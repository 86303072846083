import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {
  async getFiles () {
    const response = await Axios.get(url('/api/v1/files'), options())

    return response.data
  },

  async getTechnicalDocuments (type = null) {
    const response = await Axios.get(url(`/api/v1/files/documents/${type}`), options())

    return response.data
  },

  async getFileDownloads () {
    const response = await Axios.get(url('/api/v1/files/downloads'), options())

    return response.data
  },

  async getFileRevisions (fileId) {
    const response = await Axios.get(url(`/api/v1/files/revisions/${fileId}`), options())

    return response.data
  },

  async getFileById (id) {
    const response = await Axios.get(url(`/api/v1/files/${id}`), options())

    return response.data
  },

  async updateFile (id, file) {
    const response = await Axios.put(url(`/api/v1/files/${id}`), file, options())

    return response.data
  },

  async updateFileRevision (id, fileRevision) {
    const response = await Axios.put(url(`/api/v1/files/revision/${id}`), fileRevision, options())

    return response.data
  },

  async createFile (file) {
    if (file.id === 'new') {
      file.id = null
    }
    const response = await Axios.post(url('/api/v1/files'), file, options())

    return response.data
  },

  async createFileRevision (fileRevision) {
    const response = await Axios.post(url('/api/v1/files/revision'), fileRevision, options())

    return response.data
  },

  async resetFileRevision (params) {
    const response = await Axios.post(url('/api/v1/files/revision/reset'), params, options())

    return response.data
  },

  async createFileDownload (fileDownload) {
    const response = await Axios.post(url('/api/v1/files/download'), fileDownload, options())

    return response.data
  },

  async getFileRevisionDownload (fileRevisionId, userId) {
    const response = await Axios.get(url(`/api/v1/files/download/${fileRevisionId}/${userId}`), options())

    return response.data
  },

  async deleteFile (id) {
    const response = await Axios.delete(url(`/api/v1/files/${id}`), options())

    return response.data
  },

  async deleteFileRevision (id) {
    const response = await Axios.delete(url(`/api/v1/files/revision/${id}`), options())

    return response.data
  },

  async getFileCategories () {
    const response = await Axios.get(url('/api/v1/files/categories'), options())

    return response.data
  }
}
