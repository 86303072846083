<template>
  <div id="projects_layout">
    <section>
      <div id="projects_table">
        <div id="projects_tabletop" class="row wide">
          <div class="padding-xy-4 padding-x-0-desktop padding-y-5-tablet">
            <div class="columns is-multiline is-mobile is-vcentered">
              <div class="column">
                <b-field>
                  <b-taginput
                    v-model="searchTerms"
                    placeholder="Search..."
                    icon="search"
                    type="is-dark"
                    class="is-capitalized"
                    rounded
                    @input="loadGauges"
                  />
                </b-field>
              </div>
              <div class="column is-narrow">
                <b-button slot="trigger" class="button is-primary" @click="goToGauge()" icon-right="plus-circle">
                  Gauge
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="project_group">
            <div class="columns is-mobile is-multiline is-vcentered">
              <div class="column is-narrow">
                <b-field expanded>
                  <b-select v-model="status" @input="loadGauges" placeholder="All Statuses">
                    <option v-for="(label, value) in statusOptions" :key="value" :value="value">
                      {{ label }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <b-table
          class="scrollable clickable-row"
          :data="gaugesData"
          :mobile-cards="false"
          :hoverable="true"
          :sort-icon="'arrow-up'"
          :sort-icon-size="'is-small'"
          paginated
          :current-page.sync="page"
          :total="gaugesTotal"
          backend-pagination
          :per-page="limitPerPage"
          :loading="loadingGauges"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
          @click="goToGauge"
          @page-change="onPageChange">
          <b-table-column v-slot="props" field="action" label="Action">
            {{ props.row.action }}
          </b-table-column>
          <b-table-column v-slot="props" field="status" label="Status">
            {{ formattedStatus(props.row.status) }}
          </b-table-column>
          <b-table-column v-slot="props" :visible="(profile.type == 'jfe') && (profile.hasJfeAdminPermission) ? true : false" field="duration" label="Duration">
            {{ props.row.duration }}
          </b-table-column>
          <b-table-column v-slot="props" field="location" label="Location">
            {{ props.row.location }}
          </b-table-column>
          <b-table-column v-slot="props" field="gauge" label="Gauge/Kit">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column v-slot="props" field="connection" label="Connection">
            {{ props.row.connection }}
          </b-table-column>
          <b-table-column v-slot="props" field="application" label="Application">
            {{ props.row.application }}
          </b-table-column>
          <b-table-column :visible="(profile.type == 'jfe') && (profile.hasJfeAdminPermission) ? true : false" field="office" label="Owner">
            <template v-slot:header="{ column }">
              <span style="margin-right: 3px;">{{ column.label }}</span>
              <div>
                <b-dropdown :mobile-modal="false" class="has-text-left">
                  <b-button slot="trigger" icon-right="filter" size="is-small" :class="selectedOffices.length > 0 ? 'is-info padding-xy-1' : ' is-transparent is-dark padding-xy-1'" />
                  <b-dropdown-item custom>
                    <div class="padding-y-1">
                      <div class="media">
                        <div class="media-content">
                          <b-button @click.native="allOffices()">
                            All Offices
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item custom>
                    <div v-for="office in offices" :key="office.id" class="padding-y-1">
                      <div class="media">
                        <div class="media-content">
                          <b-checkbox
                            v-model="selectedOffices"
                            :native-value="office.id"
                            @input="loadGauges"
                          >
                            {{ office.name }}
                          </b-checkbox>
                        </div>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item class="has-text-right">
                    <small><a>close</a></small>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template v-slot="props">
              {{ props.row.owner }}
            </template>
          </b-table-column>
          <b-table-column v-slot="props" field="type" label="Type">
            {{ props.row.type }}
          </b-table-column>
          <b-table-column v-slot="props" field="value" label="Value">
            {{ props.row.value }}
          </b-table-column>
          <b-table-column v-slot="props" field="purchaseDate" label="Purchase Date">
            {{ props.row.purchaseDate }}
          </b-table-column>
          <b-table-column v-slot="props" field="manufacturer" label="Manufacturer">
            {{ props.row.manufacturer }}
          </b-table-column>
          <template slot="footer">
          </template>
          <template slot="empty">
            <div class="padding-xy-6 has-text-centered">
              No gauges
            </div>
          </template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>

import Vue2Filters from 'vue2-filters'
import Moment from 'moment'

export default {
  name: 'Gauges',
  components: {},
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      gaugesData: [],
      gaugesTotal: 0,
      selectedOffices: [],
      selectedProjects: null,
      selectedPeriod: null,
      selectedConnectionId: null,
      selectedRangeDate: null,
      selectedSizeId: null,
      selectedGradeId: null,
      showGradeFilter: false,
      searchTerms: [],
      exportMode: false,
      loaded: false,
      gradeSearchTerm: '',
      loadingGauges: true,
      checkedRows: [],
      invoice: null,
      radio: 'all',
      numberOfDummies: 5000,
      showDummyButton: true,
      countDummiesProjects: 1,
      userLicenseeConnections: [],
      selectedLicenseeName: null,
      selectedLicensee: null,
      licenseeConnections: [],
      page: 1,
      limitPerPage: 50,
      defaultSortOrder: 'DESC',
      sortField: 'id',
      sortOrder: 'DESC',
      status: 'all',
      statusOptions: {
        all: 'All Statuses',
        available: 'Available',
        checked: 'Checked',
        unavailable: 'Unavailable'
      }
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    connections () {
      return this.$store.state.connectionsLight
    },
    offices () {
      return this.$store.state.offices
    },
    now () {
      return Moment()
    }
  },

  async mounted () {
    await this.$store.commit('setTitle', 'Gauge Tracker')
    this.initializeScreen()

    // resize table
    const nonTableElementsHeight = document.getElementById('navbar').clientHeight + document.getElementById('projects_tabletop').clientHeight + document.getElementById('projects_table').getElementsByClassName('level')[0].clientHeight
    const projectsTable = document.getElementById('projects_table').getElementsByClassName('table-wrapper')[0]
    projectsTable.style.minHeight = (window.innerHeight - nonTableElementsHeight) + 'px'
    projectsTable.style.paddingBottom = (document.getElementById('projects_table').getElementsByClassName('level')[0].clientHeight) + 'px'
  },
  methods: {
    formattedStatus (status) {
      return status
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase())
    },
    async initializeScreen () {
      this.$store.commit('setApp', 'gauge')
      this.$store.dispatch('refreshOffices')
      await this.loadGauges()

      this.loadingGauges = false
    },
    async loadGauges () {
      try {
        this.loadingGauges = true
        const params = {
          filter: {
            searchTerms: this.searchTerms,
            offices: this.selectedOffices,
            limit: this.limitPerPage,
            page: this.page,
            orderFields: [this.sortField, this.sortOrder],
            status: this.status
          }
        }
        await this.$store.dispatch('refreshGauges', params)
        const { data, totalRecords } = this.$store.state.gauges
        this.gaugesData = data
        this.gaugesTotal = totalRecords
        this.loadingGauges = false
      } catch (e) {
        console.log(e)
      }
    },
    allOffices () {
      this.selectedOffices = []
      this.loadGauges()
    },
    async onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      await this.loadGauges()
    },
    async onPageChange (page) {
      this.page = page
      await this.loadGauges()
      this.$scrollTo(page, 500)
    },
    goToGauge (gauge) {
      if (gauge) {
        this.$router.push({
          name: 'gauge_detail',
          params: { id: gauge.id }
        })
      } else {
        this.$router.push({
          name: 'gauge_admin',
          params: { id: 'new' }
        })
      }
    }
  }
}
</script>
