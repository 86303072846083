<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        Class Selection
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="padding-xy-3 padding-xy-4-tablet">
        <b-table
          ref="sizesTable"
          class="nested_rows no-header"
          :class="openedSizeRow.length ? 'dim' : ''"
          :mobile-cards="false"
          :data="sizesFiltered"
          detailed
          custom-detail-row
          :opened-detailed="openedSizeRow"
          detail-key="size"
          :row-class="(row, index) => (row.size == openedSizeRow ? 'is-open' : '')"
          @click="(row, index) => closeOtherRows(row, index)"
          @details-open="(row, index) => closeOtherRows(row, index)">
          <b-table-column key="size" v-slot="props" field="size" label="Size" cell-class="pl-0">
            <div class="align-center">
            {{ props.row.size }}"
            <b-tag v-if="selectedWeights(props.row)" class="ml-2 is-dark">{{selectedWeights(props.row)}}</b-tag>
            </div>
          </b-table-column>
          <b-table-column custom-key="spacer" />
          <b-table-column key="actions" v-slot="props" cell-class="has-text-right" field="actions" label="Actions">
            <b-button
              v-if="props.row.size == openedSizeRow"
              class="is-small is-borderless is-primary is-light"
              @click="selectAllWeights(props.row)">
              Select All Weights
            </b-button>
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <tr
              v-for="weight in props.row.weights"
              :key="weight.sizeId + weight.weight"
              class="child_row"
              >
              <td colspan="3" class="pl-6 no-padding">
                <b-field>
                    <b-checkbox
                      @input="toggleWeightSelected(props.row, weight)"
                      :value="weightActive(props.row, weight)"
                      size="is-medium">
                      {{weight.weight}}
                    </b-checkbox>
                </b-field>
              </td>
            </tr>
          </template>
        </b-table>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="close">
            Cancel
          </button>
          <button class="button is-primary" @click="save">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import AdminApi from '@/apis/AdminApi'

export default {
  props: {
    gauge: Object
  },
  data () {
    return {
      loaded: false,
      openedSizeRow: [],
      classes: JSON.parse(JSON.stringify(this.gauge.classes))
    }
  },
  computed: {
    sizesFiltered () {
      return this.connection.sizes.filter(s => !s.deleted)
    }
  },
  async mounted () {
    await AdminApi.getConnectionById(this.gauge.connectionId).then(async connection => {
      this.connection = connection
    })
    this.loaded = true
  },
  methods: {
    async save () {
      this.$emit('save', this.classes)
    },
    deleteParameter () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this component?',
        confirmText: 'Delete Component',
        type: 'is-danger',
        onConfirm: () => {
          // size.deleted = true
          this.$emit('delete', this.parameter)
          this.$parent.close()
        }
      })
    },
    close () {
      this.$parent.close()
    },
    toggleSizesRow (row) {
      this.$refs.sizesTable.toggleDetails(row)
    },
    closeOtherRows (row, index) {
      this.openedSizeRow = [row.size]
    },

    weightActive (size, weight) {
      const thisSize = this.classes.find(sf => sf.sizeId === size.id)
      if (thisSize) {
        const thisWeight = thisSize.weights.indexOf(weight.id)
        if (thisWeight !== -1) {
          return true
        }
      }
      return false
    },
    selectedWeights (size) {
      const thisSize = this.classes.find(sf => sf.sizeId === size.id)
      if (thisSize) {
        return thisSize.weights.length
      }
      return 0
    },
    selectAllWeights (size) {
      const thisSize = this.classes.find(sf => sf.sizeId === size.id)
      if (thisSize) {
        this.classes.splice(this.classes.indexOf(thisSize), 1)
      }
      this.classes.push({ sizeId: size.id, weights: size.weights.map(w => w.id) })
    },
    toggleWeightSelected (size, weight) {
      const thisSize = this.classes.find(sf => sf.sizeId === size.id)
      if (thisSize) {
        const thisWeight = thisSize.weights.indexOf(weight.id)
        if (thisWeight !== -1) {
          // remove weight
          this.classes.find(c => c.sizeId === size.id).weights.splice(thisWeight, 1)
        } else {
          // add weight
          this.classes.find(c => c.sizeId === size.id).weights.push(weight.id)
        }
      } else {
        this.classes.push({ sizeId: size.id, weights: [weight.id] })
      }
      // clean up sizes with no weights
      if (thisSize && thisSize.weights && thisSize.weights.length === 0) {
        this.classes.splice(this.classes.indexOf(thisSize), 1)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.checkbox {
  padding: 20px;
  width: 100%;
}

.no-padding {
  padding: 0!important;
}

</style>
