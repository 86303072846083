<template>
  <div id="manage_buttons">
    <div class="row padding-xy-3 padding-x-5-tablet">
      <div class="columns is-vcentered is-mobile ">
        <div class="column">
          <b-button size="is-medium" class="is-transparent is-dark" @click="$emit('cancel')">
            Cancel
          </b-button>
        </div>
        <div class="column is-narrow">
          <b-button :disabled="disabledSave" type="is-success" size="is-medium" @click="$emit('save')">
            Save
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    disabledSave: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // xxx: xxxx
    }
  }
}
</script>

<style lang="scss" scoped>
#manage_buttons {
z-index: 1;
position: fixed;
.button.is-transparent {
  background: rgba(255,255,255,0.25);
  &:hover {
    background: rgba(255,255,255,0.5);
    color: #fff;
  }
}
}
</style>
