<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        {{ tempSize.size }}"
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="padding-xy-3 padding-xy-4-tablet">
        <b-field label="Size" style="margin-bottom: 5px;" />
        <b-field class="required" :type="{'is-danger': errors.has('size')}" :message="errors.first('size')">
          <b-input v-model="tempSize.size" v-validate="'required|validateSize:tempSize.size'" name="size" data-vv-as="size" />
          <p class="control">
            <span class="button is-static">
              #
            </span>
          </p>
        </b-field>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left">
          <b-dropdown :mobile-modal="false" position="is-top-right">
            <b-button
              slot="trigger"
              rounded
              type="is-dark"
              icon-right="ellipsis-h"
              class="icon-only" />
            <b-dropdown-item @click="deleteSize()">
              Delete Size
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="close">
            Cancel
          </button>
          <button class="button is-success" :disabled="!tempSize.size" @click="save">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  props: {
    size: {
      required: true,
      type: Object
    },
    sizes: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      validateOkay: false,
      loaded: false,
      tempSize: {}
    }
  },
  computed: {
  },
  async mounted () {
    // Convert site to tempSize
    this.tempSize = JSON.parse(JSON.stringify(this.size))
    this.$validator.extend('validateSize', {
      getMessage: field => 'This size already exists or cannot be empty',
      validate: (value) => {
        try {
          const newSize = Number(String(value))
          const oldSize = Number(this.size.size)
          // Empty or zeros field
          if ((newSize === parseInt(String(0))) || (isNaN(newSize))) {
            return false
          }

          if (newSize !== oldSize) {
            var sizeIndex = this.sizes.find(s => Number(s.size) === newSize)
            if (!sizeIndex) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } catch (err) {
          return true
        }
      }
    })

    this.loaded = true
  },
  methods: {
    async save () {
      if (await this.$validator.validateAll()) {
        this.$emit('change', this.tempSize)
        this.$parent.close()
      }
    },
    deleteSize (size) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this size? All associated weights will be deleted as well',
        confirmText: 'Delete Size',
        type: 'is-danger',
        onConfirm: () => {
          // size.deleted = true
          this.$emit('delete', this.size)
          this.$parent.close()
        }
      })
    },
    close () {
      this.$parent.close()
    }
  }
}

</script>
