<template>
  <div v-if="loaded">
    <div class="columns is-vcentered is-mobile mb-0">
      <div class="column is-size-5">Activity</div>
      <div class="column is-narrow">
        <div class="menu-buttons">
          <b-button
            v-if="profile.type === 'licensee' && localGauge.status === 'available'"
            type="is-primary"
            class="mr-2"
            @click="openRequestModal('initiateRequest')"
          >
            Checkout
          </b-button>
          <b-button
            v-if="profile.type === 'jfe' && localGauge.status === 'available'"
            type="is-primary"
            class="mr-2"
            @click="openRequestModal('initiateRequest', {}, 'Calibration')"
          >
            Recalibrate
          </b-button>
          <b-button
            v-if="profile.type === 'jfe' && localGauge.status === 'available'"
            type="is-primary"
            @click="openRequestModal('initiateRequest', {}, 'Allocation')"
          >
            Reallocate
          </b-button>
        </div>
      </div>
    </div>
    <span v-if="activityItemsSorted.length === 0"><i>No activity yet</i></span>
    <template v-if="activityItemsSorted.length > 0">
      <b-collapse
        class="card"
        animation="slide"
        v-for="(activity, index) in activityItemsSorted"
        :key="index"
        :class="{ active: openedActivity == index }"
        :open="openedActivity == index"
        @open="openActivity(index)"
        @close="closeActivity(index)"
      >
        <template #trigger="props">
          <div
            class="align-center padding-xy-3"
            role="button"
            :aria-expanded="props.open"
          >
            <a class="mr-1">
              <b-icon :icon="props.open ? 'chevron-down' : 'chevron-right'" />
            </a>
            <p class="card-header-title is-paddingless">
              {{ activity.type }}
              <template v-if="activity.licensee">: {{ activity.licensee }}</template>
            </p>
            <template v-if="!activity.closeDate">
              <b-button
                v-if="handleProfileButton(activity)"
                @click.stop="handleNextAction(activity)"
                type="is-primary mr-2 is-medium"
              >
                {{ setNextButtonActivityLabel(activity) }}
              </b-button>
              <b-button
                v-if="handleProfileButton(activity, 'Reject') && getActivityEvents(activity.events)[0] && getActivityEvents(activity.events)[0].event === 'Shipped'"
                @click.stop="handleNextAction(activity, 'Reject')"
                type="is-primary mr-2 is-medium"
                label="Reject"
              />
              <b-button
                :icon-left="commentToggled === index ? 'times' : 'comment'"
                class="is-primary is-medium"
                @click.stop="toggleCommentInput(index)"
              />
            </template>
            <div v-else>Close Date: {{ activity.closeDate }}</div>
          </div>
        </template>

        <div class="card-content">
          <div v-if="commentToggled === index" class="mb-4">
            <b-input ref="comment_input" type="textarea" rows="4" />
            <div class="has-text-right mt-3">
              <b-button @click="toggleCommentInput()" class="is-light mr-2" label="Cancel" style="font-size: 16px" />
              <b-button @click="saveNote(activity)" class="is-success is-light" label="Save Comment" style="font-size: 16px" />
            </div>
          </div>
          <div
            v-for="event in activity.events"
            :key="event.event + '_' +event.id"
            class="columns event_item"
            :class="[
              event.message ? 'user_message' : 'event',
              { active: event === getActivityEvents(activity.events)[0] }
            ]"
          >
            <div class="column is-narrow align-center">
            <span class="dot" v-if="event.event" />
              <div v-if="event.event">
                {{ event.event }}
                <p v-if="event.event === 'Shipped' && event.shippingFileUrl && event.shippingFileUrl.length > 0" class="download-link">
                  <a href="#" @click.prevent="handlePdfClick(event.shippingFileUrl)">
                    Shipping file
                  </a>
                </p>
                <p v-if="event.event === 'Rejected' && event.rejectFileUrl && event.rejectFileUrl.length > 0" class="download-link">
                  <a href="#" @click.prevent="handlePdfClick(event.rejectFileUrl)" >
                    Rejection file
                  </a>
                </p>
                <p v-if="event.event === 'Returned' && event.returnFileUrl && event.returnFileUrl.length > 0" class="download-link">
                  <a href="#" @click.prevent="handlePdfClick(event.returnFileUrl)"  >
                    Returning file
                  </a>
                </p>
                <p v-if="event.event === 'Finalized' && event.certificateFileUrl && event.certificateFileUrl.length > 0" class="download-link">
                  <a href="#" @click.prevent="handlePdfClick(event.certificateFileUrl)">
                    Certification file
                  </a>
                </p>
              </div>
            </div>
            <div class="column">
              <div v-if="event.message" :class="{ bubble: event.message }">
                  {{ event.message }}
              </div>
              <div class="date-col" :class="{'normal' : !event.message}">
                <div>{{ event.user }}</div>
                <span>&bull;</span>
                <div>{{ event.date }}</div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import GaugeRequestModal from '@/components/modals/GaugeRequestModal'
import GaugeCalibrationModal from '@/components/modals/GaugeCalibrationModal.vue'
import GaugeAllocationModal from '@/components/modals/GaugeAllocationModal.vue'
import GaugesApi from '@/apis/GaugesApi'

export default {
  props: {
    gauge: Object
  },
  data () {
    return {
      loaded: false,
      openedActivity: 0,
      commentToggled: null,
      localGauge: { ...this.gauge },
      gaugeActivities: []
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    activityItemsSorted () {
      return this.gaugeActivities
    }
  },
  async mounted () {
    this.loaded = true
    await this.loadGaugeActivities()
  },
  methods: {
    getActivityEvents (events) {
      return events.filter((ev) => !(ev.type && ev.type === 'message'))
    },
    async loadGaugeActivities () {
      if (!this.gauge.id) {
        return false
      }
      try {
        this.gaugeActivities = await GaugesApi.getGaugeActivityHistory(this.gauge.id)
        // this.gaugeActivities = [...this.gaugeActivities, ...activityItems]
        // this.$buefy.toast.open('Gauge data reloaded')
      } catch (error) {
        this.$buefy.toast.open('Failed to reload gauge data')
        console.error(error)
      }
    },
    openRequestModal (step = 'initiateRequest', request = {}, type = 'Checkout') {
      const component = type === 'Checkout' ? GaugeRequestModal : type === 'Calibration' ? GaugeCalibrationModal : GaugeAllocationModal
      const modal = this.$buefy.modal.open({
        parent: this,
        component,
        customClass: 'mini',
        props: {
          gauge: this.localGauge,
          requestStep: step,
          requestItem: request,
          requestType: type
        },
        events: {
          saved: () => {
            modal.close()
            this.$emit('refresh-gauge')
          }
        }
      })
    },
    openActivity (index) {
      this.commentToggled = null
      this.openedActivity = index
    },
    closeActivity () {
      this.openedActivity = null
      this.commentToggled = null
    },
    toggleCommentInput (index) {
      if (index != null) {
        this.openedActivity = index
        if (this.commentToggled === index) {
          this.commentToggled = null
        } else {
          this.commentToggled = index
          this.$nextTick(() => {
            this.$refs.comment_input[0].focus()
          })
        }
      } else {
        this.commentToggled = null
      }
    },
    setNextButtonActivityLabel (activity) {
      const events = this.getActivityEvents(activity.events)
      if (!events || !events[0] || !events[0].event) {
        return
      }
      switch (activity.events[0].event) {
        case 'Requested':
          return 'Review'
        case 'Shipped':
          return 'Accept'
        case 'Approved':
          return 'Ship'
        case 'Rejected':
          return 'Receive'
        case 'Received':
          return 'Return'
        case 'Returned':
          return 'Receive'
        default:
          return 'Next Action'
      }
    },
    handleProfileButton (activity, rejected = null) {
      const events = this.getActivityEvents(activity.events)
      if (!events || !events[0] || !events[0].event) {
        return
      }
      let lastEvent = events[0].event
      if (rejected) {
        lastEvent = rejected
      }
      switch (lastEvent) {
        case 'Requested':
          return this.profile.type === 'jfe'
        case 'Approved':
          return this.profile.type === 'jfe'
        case 'Shipped':
          return this.profile.type === 'licensee'
        case 'Reject':
          return this.profile.type === 'licensee'
        case 'Rejected':
          return this.profile.type === 'jfe'
        case 'Received':
          return this.profile.type === 'licensee'
        case 'Returned':
          return this.profile.type === 'jfe'
        default:
          return false
      }
    },
    async handleNextAction (activity, rejected = null) {
      let nextStep = ''
      const events = this.getActivityEvents(activity.events)
      if (!events || !events[0] || !events[0].event) {
        return
      }
      let lastEvent = events[0].event
      if (rejected) {
        lastEvent = rejected
      }
      switch (lastEvent) {
        case 'Requested':
          nextStep = activity.type === 'Calibration' ? 'finalize' : 'reviewRequest'
          break
        case 'Denied':
          nextStep = 'initiateRequest'
          break
        case 'Approved':
          nextStep = 'shipGauge'
          break
        case 'Shipped':
          nextStep = 'receiveGaugeLicensee'
          break
        case 'Reject':
          nextStep = 'returnGauge'
          break
        case 'Rejected':
          nextStep = 'receiveGaugeJfe'
          break
        case 'Received':
          nextStep = 'finishGauge'
          break
        case 'Returned':
          nextStep = 'receiveGaugeJfe'
          break
      }
      if (nextStep !== '') {
        const request = activity.type === 'Calibration' ? activity : activity.events[0]
        this.openRequestModal(nextStep, request, activity.type)
      }
    },
    async saveNote (activity) {
      try {
        const events = this.getActivityEvents(activity.events)
        if (!events || !events[0] || !events[0].requestId) {
          return
        }

        const requestId = events[0].requestId

        const data = {
          requestId,
          comment: this.$refs.comment_input[0].newValue,
          userId: this.$store.state.profile.id
        }

        await GaugesApi.addGaugeActivityComment(requestId, data)
        this.$buefy.toast.open('Comment saved successfully')
        this.toggleCommentInput()
        this.$emit('refresh-gauge')
      } catch (error) {
        console.error('Error saving comment:', error)
        this.$buefy.toast.open('Failed to save comment')
      }
    },
    async handlePdfClick (url) {
      const response = await GaugesApi.getPdfUrl(url)
      window.open(response, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 1rem;

  &:not(.active) {
    background: #e7e7e7;
  }
}

.card-header-title {
  font-size: 17px;
}

.card-content {
  flex-grow: 1;
  padding: 1.15rem;
  padding-top: 0;
}

.download-link {
  font-size: 12px;
}

.event_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  .column.is-narrow {
    @media (min-width: '768px') {
      width: 130px;
      font-size: 18px;
    }
  }

  .date-col {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    &:not(.normal) {
    font-size: 13px;
    opacity: 0.7;
    }

    span {
      margin: 0 5px;
    }
  }

  .bubble {
    font-size: 15px;
    background: #f0f0f0;
    padding: 12px;
    border-radius: 8px;
    position: relative;
    margin-bottom: 7px;

    &:after {
      content: "";
      position: absolute;
      left: 15px;
      top: 100%;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 7px 7px 0 7px;
      border-color: #f0f0f0 transparent transparent transparent ;
      transform: rotate(0deg);
    }
  }

  .dot {
    display: block;
    height: 8px;
    width: 8px;
    min-height: 8px;
    min-width: 8px;
    margin-right: 10px;
    border-radius: 100px;
    background: #ddd;
  }

  &.active {
    .dot {
      background: #317ac4;
    }
  }

  .menu-buttons {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 1rem;
  }
}
</style>
