import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {

  async sendEmail (notificationEmail) {
    const response = await Axios.post(url('/api/v1/emails/actions/sendEmail'), notificationEmail, options())

    return response.data
  }
}
