<template>
  <div v-if="loaded">
    <div v-if="invoice.deleted" class="padding-xy-6">
      <b-message type="is-danger" has-icon>
        This invoice is no longer available.
      </b-message>
    </div>
    <div v-else>
      <loading-general ref="loader" v-bind="loader" />
      <div class="padding-y-5 hide-for-print" style="max-width: 1000px; margin: 0 auto">
        <div class="columns is-mobile is-vcentered">
          <div class="column">
            <div class="b-tabs block">
              <nav class="tabs is-toggle-rounded is-toggle">
                <ul style="">
                  <li :class="{'is-active' : layout === 'invoice'}">
                    <a @click="layout = 'invoice'">Invoice</a>
                  </li>
                  <li v-if="invoice.taxWithholdingPercent != 0" :class="{'is-active' : layout === 'tax'}">
                    <a @click="layout = 'tax'">Tax Certificate</a>
                  </li>
                  <li :class="{'is-active' : layout === 'projects'}">
                    <a @click="layout = 'projects'">Projects</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div v-if="profile.type === 'jfe'" class="column is-narrow">
            <small v-if="invoiceSentStatus === 'sent'" class="is-inline-flex padding-xy-2">
              Invoice sent {{ invoice.sentDate | moment("MMM D, YYYY") }}
            </small>
            <b-tooltip
              v-if="!invoice.dueDate || !usersLicensee.length>0"
              :label="((!invoice.dueDate) ? 'Choose a due date' : (!usersLicensee.length>0) ? 'There are no invoice recipients for ' +licensee.name : '')"
              :multilined="!usersLicensee.length>0"
              position="is-bottom"
              type="is-dark">
              <b-button disabled type="is-success" rounded>
                {{ buttonSendText }}
              </b-button>
            </b-tooltip>
            <b-button v-else type="is-success" rounded @click="openSendInvoiceModal">
              {{ buttonSendText }}
            </b-button>
            <b-button v-if="invoiceReviewed" type="is-success" icon-left="check" rounded style="margin-left: 10px;" @click="showReviewUsers()">
              Reviewed
            </b-button>
            <b-button v-if="!invoiceReviewed && usersLicensee.length>0 && invoice.dueDate" type="is-success" rounded style="margin-left: 10px;" @click="showReviewUsers()">
              Review
            </b-button>
            <b-dropdown :mobile-modal="false" position="is-bottom-left">
              <b-button
                slot="trigger"
                rounded
                type="has-background-grey-lighter"
                icon-right="ellipsis-h"
                class="icon-only"
                style="margin-left: 10px" />
              <b-dropdown-item @click="confirmDeleteInvoice()">
                Delete Invoice
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div id="invoice">
        <div class="padding-xy-4 print-paddingless">
          <div class="columns" style="margin-bottom: 0">
            <div class="column">
              <div v-if="layout == 'invoice'">
                <div class="is-size-4 padding-y-2" style="padding-top: 0">
                  <b>Invoice:</b>
                  <span class="has-text-primary">
                    {{ invoiceCodeNumber }}
                  <!-- {{invoiceDate | moment("MMM D, YYYY")}} -->
                  </span>
                </div>
                <p>
                  <strong>Invoice Date:</strong>
                  {{ invoiceDate | moment("MMM D, YYYY") }}
                </p>
                <p class="padding-y-1">
                  <strong>Due Date:</strong>
                  {{ dueDate }}

                  <b-dropdown ref="invoiceDueDateDropdown" class="is-paddingless" :mobile-modal="false">
                    <b-button v-if="profile.type === 'jfe'" slot="trigger" class="hide-for-print is-transparent has-text-primary" style="height: auto; padding: 0 7px" icon-left="calendar">
                      <span v-if="!invoice.dueDate">SET DUE DATE</span>
                    </b-button>

                    <b-dropdown-item class="is-paddingless" custom>
                      <b-datepicker v-model="invoiceDueDate" inline icon="calendar-alt" :mobile-modal="false" name="invoiceDueDate" placeholder="Due Date">
                        <div class="level is-mobile">
                          <button class="button has-text-grey is-transparent" @click="invoiceDueDate = null">
                            <b-icon icon="times" />
                            <span>Reset</span>
                          </button>
                          <button class="button is-success" style="opacity: 1" @click="saveDueDate">
                            <span>Save</span>
                          </button>
                        </div>
                      </b-datepicker>
                    </b-dropdown-item>
                  </b-dropdown>
                </p>
                <b-message v-if="daysOverdue > 30" type="is-danger" aria-close-label="Close message">
                  This invoice is {{ daysOverdue }} days overdue
                </b-message>
              </div>
              <div v-if="layout == 'tax'">
                <div class="is-size-4 padding-y-2" style="padding-top: 0">
                  <b>Tax Receipt:</b>
                  <span class="has-text-primary">
                    {{ invoiceCodeNumber }}
                  </span>
                </div>
                <p>
                  <strong>Date:</strong>
                  {{ invoiceDate | moment("MMM D, YYYY") }}
                </p>
              </div>
              <div v-if="layout === 'projects'">
                <div class="is-size-4 padding-y-2" style="padding-top: 0">
                  <b>Projects</b>
                </div>
                <p>
                  <strong>Invoice:</strong>
                  {{ invoiceCodeNumber }}
                </p>
              </div>
              <div style="height: 20px" />
              <h2 class="is-size-4 has-text-weight-bold">
                {{ licensee.name }}
              </h2>
              <!-- <span v-if="licensee.address" style="white-space: pre-line">{{ licensee.address }}</span> -->
              <span v-if="licenseeAdress" style="white-space: pre-line">{{ licenseeAdress }}</span>
            </div>
            <div class="column is-narrow has-text-right" style="font-size: 13px">
              JFE Steel Corporation
              <br>Hibiya Kokusai Bldg., 2-3,
              <br>Uchisaiwai-cho 2-chome,
              <br>Chiyoda-ku, Tokyo 100-0011
              <br>Japan
              <div style="padding-top: 15px">
                T: 81-33597-4176
                <br>F: 81-33597-4710
              </div>
            </div>
            <div class="column is-narrow">
              <img style="width: 105px" src="@/assets/images/JFE-logo-stacked.png">
            </div>
          </div>
        </div>
        <div class="invoice-table skinny_padding">
          <!-- Block for projects with divisions       -->
          <div v-if="(layout == 'invoice' || layout == 'tax') && licensee.divisions.length > 0" style="font-size: 15px; line-height: 1.75">
            <div class="table-header">
              <div class="columns is-mobile is-marginless">
                <div class="column is-3">
                  Connection
                </div>
                <div class="column">
                  <div class="columns is-mobile is-marginless is-gapless">
                    <div class="column is-5">
                      Division
                    </div>
                    <div class="column has-text-right" :style="(layout == 'invoice' ? 'order: 4' : 'order: 3')">
                      Royalty
                    </div>
                    <div v-if="invoice.taxWithholdingPercent != 0 && layout == 'tax'" class="column has-text-right is-3" :style="(layout == 'tax' ? 'order: 4' : 'order: 3')">
                      Tax ({{ invoice.taxWithholdingPercent }}%)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(connection, indexConnection) in connectionsFiltered" :key="indexConnection" class="columns is-mobile is-marginless">
              <div class="column">
                <div class="columns is-mobile">
                  <div class="column is-3">
                    {{ connection.name }}
                  </div>
                  <div class="column">
                    <div v-for="(division, indexDivision) in licensee.divisions" :key="indexDivision" class="columns is-mobile is-marginless is-gapless">
                      <div class="column">
                        <div v-if="royaltySubtotalDivisionRow(connection, division) > 0" class="columns is-mobile">
                          <div v-if="royaltySubtotalDivisionRow(connection, division) > 0" class="column is-5">
                            {{ division.name }}
                          </div>
                          <div class="column has-text-right" :style="(layout == 'invoice' ? 'order: 4' : 'order: 3')">
                            {{ royaltySubtotalDivisionRow(connection, division) | currency }}
                          </div>
                          <div v-if="invoice.taxWithholdingPercent != 0 && layout == 'tax'" class="column has-text-right is-3" :style="(layout == 'tax' ? 'order: 4' : 'order: 3')">
                            {{ taxSubtotalDivisionRow(connection, division) | currency }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile" style="background-color: #eee;">
                  <div class="column is-3">
                    <div>
                      Subtotal
                    </div>
                  </div>
                  <div class="column">
                    <div class="columns is-mobile">
                      <div class="column has-text-right">
                        <strong class="has-text-primary">
                          {{ royaltySubtotalRow(connection) | currency }}
                        </strong>
                      </div>
                      <div v-if="layout == 'tax'" class="column is-3">
                        <div class="has-text-right ">
                          <div>
                            <strong class="has-text-primary">
                              {{ taxSubtotalRow(connection) | currency }}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End block with divisions -->

          <!-- Block for projects without divisions -->
          <div v-if="(layout == 'invoice' || layout == 'tax') && licensee.divisions.length === 0" style="font-size: 15px; line-height: 1.75">
            <div class="table-header">
              <div class="columns is-mobile is-marginless">
                <div class="column">
                  Connection
                </div>
                <div class="column has-text-right is-3" :style="(layout == 'invoice' ? 'order: 4' : 'order: 3')">
                  Royalty
                </div>
                <div v-if="invoice.taxWithholdingPercent != 0 && layout == 'tax'" class="column has-text-right is-3" :style="(layout == 'tax' ? 'order: 4' : 'order: 3')">
                  Tax ({{ invoice.taxWithholdingPercent }}%)
                </div>
              </div>
            </div>
            <div v-for="(connection, indexConnection) in connectionsFiltered" :key="indexConnection" class="columns is-mobile is-marginless is-gapless">
              <div class="column">
                <div v-if="royaltySubtotalRow(connection) > 0" class="columns is-mobile is-marginless">
                  <div class="column">
                    {{ connection.name }}
                  </div>
                  <div class="column has-text-right is-3" :style="(layout == 'invoice' ? 'order: 4' : 'order: 3')">
                    {{ royaltySubtotalRow(connection) | currency }}
                  </div>
                  <div v-if="invoice.taxWithholdingPercent != 0 && layout == 'tax'" class="column has-text-right  is-3" :style="(layout == 'tax' ? 'order: 4' : 'order: 3')">
                    {{ taxSubtotalRow(connection) | currency }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End block without divisions -->

          <!-- Block for projects list  -->
          <div v-if="layout === 'projects'" class="b-table" style="font-size: 15px; line-height: 1.75">
            <div class="table-wrapper">
              <table class="b-table table is-fullwidth">
                <thead>
                  <th width="10" />
                  <th>
                    Order
                  </th>
                  <th>
                    Project Name
                  </th>
                  <th>
                    Connection
                  </th>
                  <th>
                    Size
                  </th>
                  <th>
                    Weight
                  </th>
                  <th>
                    Grade
                  </th>
                  <th class="has-text-right">
                    Units
                  </th>
                  <th class="has-text-right">
                    Tax ({{ invoice.taxWithholdingPercent }}%)
                  </th>
                  <th class="has-text-right">
                    Royalty
                  </th>
                </thead>
                <tbody>
                  <tr v-for="(project, indexProject) in projectsFiltered" :key="indexProject">
                    <td>
                      <span class="tag is-rounded project-type" :class="[project.type]">{{ project.type.substring(0, 1).toUpperCase() }}</span>
                    </td>
                    <td>
                      {{ project.orderNumber }}
                    </td>
                    <td>
                      {{ project.name }}
                    </td>
                    <td>
                      {{ connectionsById[project.connectionId].name }}
                    </td>
                    <td v-if="getSizeTest(project)">{{ getSizeTest(project).size }}</td>
                    <td v-else class="is-danger" style="color:white">
                      <b-tooltip type="is-dark" multilined size="is-large" position="is-top">
                        <span>Not set</span>
                        <template v-slot:content>
                          <span v-html="'Please review this project and make sure it is consistent before sending this invoice'" />
                        </template>
                      </b-tooltip>
                    </td>
                    <td>
                      {{ project.connectionWeight }}#
                    </td>
                    <td>
                      <span v-if="project.materialType===1">{{ (gradeById[project.connectionGradeId]) ? gradeById[project.connectionGradeId].name : 'Grade #' + project.connectionGradeId }}</span>
                      <span v-if="project.materialType===2">{{ project.craDescription }}</span>
                      <span v-if="project.materialType===3">Other Material</span>
                    </td>
                    <td class="has-text-right">
                      {{ units(project.id) }}
                    </td>
                    <td class="has-text-right">
                      {{ (project.royalty * invoice.taxWithholdingPercent / 100) | currency }}
                    </td>
                    <td class="has-text-right">
                      {{ project.royalty | currency }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="padding-y-3" />
          </div>
        <!-- End block project list-->
        </div>

        <!-- Total Amount for both -->
        <div class="columns" style="margin-bottom: 0">
          <div class="column is-full">
            <div class="has-text-right padding-xy-3">
              <div v-if="layout == 'invoice' || layout === 'projects'">
                Total Royalties: <strong class="has-text-primary">
                  {{ royaltyTotal | currency }}
                </strong>
              </div>
              <div :class="(layout == 'tax' ? 'is-size-4 has-text-weight-bold' : '')">
                Total Tax: <strong class="has-text-primary">
                  {{ taxTotal | currency }}
                </strong>
              </div>
              <div v-if="layout == 'invoice' ">
                <div class="is-size-4">
                  <strong>Amount Due:</strong> <strong class="has-text-primary">
                    {{ royaltyTotal - taxTotal | currency }}
                  </strong>
                </div>
                <div class="padding-y-2">
                  <span v-if="invoice.paidDate" class="padding-x-2">
                    <strong>Paid:</strong>
                    {{ paidDate }}
                  </span>
                  <b-dropdown ref="invoicePaidDateDropdown" class="is-paddingless" :mobile-modal="false" position="is-bottom-left">
                    <b-button v-if="profile.type === 'jfe'" slot="trigger" class="hide-for-print is-transparent has-text-primary hide-for-print" style="height: auto; padding: 0 " icon-right="calendar">
                      <span v-if="!invoice.paidDate">Mark as Paid</span>
                    </b-button>

                    <b-dropdown-item class="is-paddingless" custom>
                      <b-datepicker v-model="invoicePaidDate" inline icon="calendar-alt" :mobile-modal="false" name="invoicePaidDate" placeholder="Paid Date">
                        <div class="level is-mobile">
                          <button class="button has-text-grey is-transparent" @click="invoicePaidDate = null">
                            <b-icon icon="times" />
                            <span>Reset</span>
                          </button>
                          <button class="button is-success" style="opacity: 1" @click="savePaidDate">
                            <span>Save</span>
                          </button>
                        </div>
                      </b-datepicker>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="layout !== 'projects'" class="padding-xy-4 print-paddingless" style="font-size: 14px; padding-top: 0; margin-top: auto;">
          <div class="columns skinny_padding">
            <div class="column">
              <div v-if="office.address">
                <div class="has-text-weight-bold" style="margin-bottom: 10px">
                  Report to:
                </div>
                <span style="white-space: pre-line;">{{ office.address }}</span>
              </div>
            </div>
            <div class="column is-narrow">
              <div class="box padding-xy-3">
                <div
                  class="is-size-5 has-text-weight-bold"
                  style="margin-bottom: 10px">
                  Wire Instructions
                </div>

                <div class="columns">
                  <div class="column is-narrow">
                    Mizuho Bank, Ltd.
                    <br>Head Office
                    <br>1-5-5, Otemachi,
                    <br>Chiyoda-ku, Tokyo 100-8176
                    <br>Japan
                  </div>
                  <div class="column is-narrow">
                    Account #: 1445936
                    <br>Swift #: MHCBJPJT
                    <br>Licensee Code #: {{ licensee.code }}
                    <br>Company Registration #: T1010001008668
                  </div>
                </div>
                <div class="has-text-centered has-text-primary">
                  <em>** Licensee is responsible for Bank/Wire transfer fees **</em>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import ProjectsApi from '@/apis/ProjectsApi'
import InvoicesApi from '@/apis/InvoicesApi'
import UsersApi from '@/apis/UsersApi'
import LoadingGeneral from '@/components/loading/LoadingGeneral'
import SendInvoiceModal from '@/components/modals/SendInvoice'
import InvoiceReviewListModal from '@/components/modals/InvoiceReviewList'

export default {
  name: 'Invoice',
  components: {
    LoadingGeneral: LoadingGeneral
  },
  data () {
    return {
      projects: [],
      usersLicensee: [],
      invoiceSentStatus: 'unsent',
      loaded: false,
      visible: false,
      layout: 'invoice',
      loader: {
        isLoading: false,
        typeLoader: 'dots',
        canCancel: false,
        fullPage: true
      },
      invoice: null,
      invoiceDueDate: null,
      invoicePaidDate: null,
      invoiceReviewList: [],
      invoiceReviewed: false
    }
  },
  computed: {
    // layout () {
    //   return this.$route.meta.title
    // },
    licensees () {
      return this.$store.state.licensees
    },
    licensee () {
      return this.licensees.find(
        l => String(l.id) === this.$route.params.licenseeId
      )
    },
    licenseeAdress () {
      const licenseeAddress = this.licensee.divisions.find(d => d.billingDivision)
      if (licenseeAddress) {
        // return licenseeAddress.address ? licenseeAddress.address : 'No address was defined for the billing division ' + licenseeAddress.name + '.'
        return licenseeAddress.address ? licenseeAddress.address : null
      }
      // return 'No billing division has been defined.'
      return null
    },
    // licenseeDivision () {
    //   if (this.licensee) {
    //     return this.licensee.divisions.find(
    //       d => String(d.id) === this.$route.params.licenseeDivisionId
    //     )
    //   } else {
    //     return null
    //   }
    // },
    profile () {
      return this.$store.state.profile
    },
    offices () {
      return this.$store.state.offices
    },
    office () {
      return this.offices.find(
        o => String(o.id) === String(this.licensee.officeId)
      )
    },
    connections () {
      return this.$store.state.connectionsLight
    },
    grades () {
      return this.$store.state.connectionGrades
    },
    connectionsById () {
      const mapped = {}

      this.connections.forEach(connection => {
        mapped[connection.id] = connection
      })

      return mapped
    },
    gradeById () {
      const mapped = {}

      this.grades.forEach(grade => {
        mapped[grade.id] = grade
      })

      return mapped
    },
    connectionsFiltered () {
      return this.connections.filter(c => this.projectsFiltered.find(p => p.connectionId === c.id))
    },
    // periodStart () {
    //   return Moment(this.$route.params.periodStartDate)
    // },
    daysOverdue () {
      if (!this.invoice.paid) {
        if (this.invoice.dueDate) {
          var d = new Date()
          var dueDateSplited = this.invoice.dueDate.substring(0, 10).split('-') // Convert the crap date from Mysql
          var dueDate = new Date(dueDateSplited[0], dueDateSplited[1] - 1, dueDateSplited[2])

          var diffInTime = d.getTime() - dueDate.getTime()
          var diffInDays = diffInTime / (1000 * 3600 * 24)
          return Math.floor(diffInDays)
        }
      }
      return 0
    },
    invoiceId () {
      return this.$route.params.invoiceId
    },
    invoiceCodeNumber () {
      if (this.invoice.codeNumber) {
        // return this.licensee.code + '-' + String(this.invoice.number).padStart(4, '0')
        return this.invoice.codeNumber
      } else {
        return this.licensee.code + '-' + String(0).padStart(4, '0')
      }
    },
    // periodEnd () {
    //   if (this.licensee.billingCycle === 'Semiannually') {
    //     return this.periodStart.clone().add(6, 'month')
    //   } else if (this.licensee.billingCycle === 'Quarterly') {
    //     return this.periodStart.clone().add(3, 'month')
    //   } else {
    //     return this.periodStart.clone().add(1, 'month')
    //   }
    // },
    invoiceDate () {
      // return this.periodEnd.clone().subtract(1, 'day')
      return this.invoice.createDate
    },
    dueDate () {
      if (this.invoice.dueDate) {
        return Moment(this.invoice.dueDate).format('MMM D, YYYY')
      } else {
        return null
      }
    },
    paidDate () {
      if (this.invoice.paidDate) {
        return Moment(this.invoice.paidDate).format('MMM D, YYYY')
      } else {
        return null
      }
    },
    buttonSendText () {
      return (this.invoiceSentStatus === 'sent') ? 'Resend' : 'Send Invoice'
    },
    projectsFiltered () {
      return this.projects
        .filter(project => {
          return project.licenseeId === this.licensee.id && project.deleted === 0
        })
        // .filter(project => {
        //   return (
        //     !this.licenseeDivision ||
        //     project.licenseeDivisionId === this.licenseeDivision.id
        //   )
        // })
        // .filter(project => {
        //   return Moment(project.endDate).isBetween(
        //     this.periodStart,
        //     this.periodEnd
        //   )
        // })
    },
    royaltyTotal () {
      // projectsFiltered.find(p => p.licenseeDivisionId === division.id && p.connectionId === connection.id)
      return this.projectsFiltered
        .map(p => p.royalty)
        .reduce((a, b) => Number(a) + Number(b), 0)
    },
    taxTotal () {
      return this.projectsFiltered
        .map(p => p.royalty)
        .reduce((a, b) => Number(a) + Number(b) * this.invoice.taxWithholdingPercent / 100, 0)
    },
    showInvoiceTaxColumn () {
      return !!((this.layout === 'invoice' && this.invoice.taxWithholdingPercent !== 0))
    },
    showTaxCertColumn () {
      return !!((this.layout === 'tax' && this.invoice.taxWithholdingPercent !== 0))
    }
  },
  watch: {
    'invoice.sent' (sent) {
      if (sent) {
        this.invoiceSentStatus = 'sent'
      } else {
        this.invoiceSentStatus = 'unsent'
      }
    }
  },
  beforeCreate: function () {
    document.body.id = 'invoice_page'
  },
  async mounted () {
    await this.$store.dispatch('refreshOffices')
    await this.$store.dispatch('refreshLicensees')
    await this.$store.dispatch('refreshConnectionsLight', { hasImages: false, hasSizes: true, hasWeights: false })
    await this.$store.dispatch('refreshConnectionGradesWithDeleted')

    this.projects = await ProjectsApi.getProjectsByInvoiceId(this.invoiceId)
    this.invoice = await InvoicesApi.getInvoiceById(this.invoiceId)
    this.usersLicensee = await UsersApi.getUsersByLicenseeId(this.licensee.id)
    this.invoiceReviewList = await InvoicesApi.getInvoicesReview(this.invoiceId)

    if (this.invoice.dueDate) {
      this.invoiceDueDate = Moment(this.invoice.dueDate).toDate()
    }
    if (this.invoice.paidDate) {
      this.invoicePaidDate = Moment(this.invoice.paidDate).toDate()
    }

    if (this.invoiceReviewList.find(user => user.userId === this.profile.id)) {
      this.invoiceReviewed = true
    }

    this.loaded = true

    this.$store.commit('setTitle', 'Invoice ' + (this.invoiceCodeNumber).trim())
  },
  methods: {
    royaltySubtotalDivisionRow (connection, division) {
      return this.projectsFiltered.filter(p => p.connectionId === connection.id && p.licenseeDivisionId === division.id)
        .reduce((subTotal, project) => (Number(subTotal) + Number(project.royalty)), 0)
    },
    taxSubtotalDivisionRow (connection, division) {
      return this.projectsFiltered.filter(p => p.connectionId === connection.id && p.licenseeDivisionId === division.id)
        .reduce((subTotal, project) => (Number(subTotal) + (Number(project.royalty) * (this.invoice.taxWithholdingPercent / 100))), 0)
    },
    royaltySubtotalRow (connection) {
      return this.projectsFiltered.filter(p => p.connectionId === connection.id)
        .reduce((subTotal, project) => (Number(subTotal) + Number(project.royalty)), 0)
    },
    taxSubtotalRow (connection) {
      return this.projectsFiltered.filter(p => p.connectionId === connection.id)
        .reduce((subTotal, project) => (Number(subTotal) + (Number(project.royalty) * (this.invoice.taxWithholdingPercent / 100))), 0)
    },
    units (projectId) {
      const project = this.projectsFiltered.find(
        p => p.id === projectId
      )

      if (project) {
        if (project.type === 'manufacturing') {
          return (project.joints || 0) + (project.couplings || 0)
        } else {
          return (project.pins || 0) + (project.boxes || 0)
        }
      } else {
        return null
      }
    },
    openSendInvoiceModal () {
      var invoiceRecipients = this.usersLicensee.filter(u => u.receiveInvoiceNotification && u.enabled && !u.deleted)
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: SendInvoiceModal,
        props: {
          invoice: this.invoice,
          licensee: this.licensee,
          invoiceRecipients: invoiceRecipients
        },
        events: {
          sent: async (response) => {
            if ((response.sent) && (response.invoice != null)) {
              this.invoice.sent = response.invoice.sent
              this.invoice.sentDate = response.invoice.sentDate
            }
          },
          close: async (response) => {
          }
        }
      })
    },
    confirmDeleteInvoice () {
      this.$buefy.dialog.confirm({
        message:
          'Are you sure you want to delete this invoice?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          const loadingComponent = this.$buefy.loading.open({ container: null })
          try {
            this.loaded = false
            this.invoice.deleted = 1
            await InvoicesApi.updateInvoice(this.invoice.id, this.invoice).then(() => {
              this.deleteInvoiceByProjectsGo().then(() => {
                this.$buefy.toast.open('Invoice deleted')
                this.$router.push({ name: 'royalty_tracker_projects' })
              })
            })
          } catch (e) {
            console.log(e)
          } finally {
            loadingComponent.close()
          }
        }
      })
    },
    showReviewUsers () {
      this.$buefy.modal.open({
        parent: this,
        canCancel: true,
        component: InvoiceReviewListModal,
        props: {
          invoice: this.invoice,
          invoiceReviewList: this.invoiceReviewList
        },
        events: {
          close: async (response) => {
          },
          review: async (response) => {
            this.invoiceReviewList = await InvoicesApi.getInvoicesReview(this.invoiceId)
          }
        }
      })
    },
    async deleteInvoiceByProjectsGo () {
      try {
        await ProjectsApi.deleteInvoiceFromProject(this.invoice.id)
      } catch (e) {
        console.log(e)
      }
      // for (var project of this.projects) {
      //   project.invoiceId = null
      //   await ProjectsApi.updateProject(project.id, project)
      // }
    },
    async saveDueDate () {
      if (this.invoiceDueDate) {
        this.invoice.dueDate = Moment(this.invoiceDueDate).format('YYYY-MM-DD hh:mm:ss')
      } else {
        this.invoice.dueDate = null
      }
      this.$refs.invoiceDueDateDropdown.toggle()
      this.invoice = await InvoicesApi.updateInvoice(this.invoice.id, this.invoice)
    },
    async savePaidDate () {
      if (this.invoicePaidDate) {
        this.invoice.paidDate = Moment(this.invoicePaidDate).format('YYYY-MM-DD hh:mm:ss')
        this.invoice.paid = true
      } else {
        this.invoice.paidDate = null
        this.invoice.paid = false
      }
      this.$refs.invoicePaidDateDropdown.toggle()
      this.invoice = await InvoicesApi.updateInvoice(this.invoice.id, this.invoice)
    },
    getSizeTest (project) {
      return this.connectionsById[project.connectionId].sizes.find(size => size.id === project.connectionSizeId && size.connectionId === project.connectionId)
    }
  }
}
</script>
