<template>
  <div class="wrapper">
    <span v-if="loaded">
      <div class="card content padding-xy-5">
        <h1 style="margin-bottom: 50px">
          Data Export
        </h1>
        <form @submit="exportReport">
          <div class="columns is-vcentered">
            <div class="column">
              <h3 class="is-marginless">Connection</h3>
            </div>
            <div class="column is-narrow">
              <b-select id="connections" v-model="connectionId" class="dropdown" name="connection">
                <option v-for="con in connections" :key="con.id" :value="con.id">
                  {{ con.name }}
                </option>
              </b-select>
            </div>
          </div>
          <div class="columns is-vcentered">
            <div class="column">
              <h3 class="is-marginless">Report</h3>
            </div>
            <div class="column is-narrow">
              <b-select id="reports" v-model="report" class="dropdown" name="report">
                <option value="pipeSpecs">
                  Pipe Specs
                </option>
                <option value="torqueValues">
                  Torque Values
                </option>
                <option value="couplingValues">
                  Coupling Values
                </option>
                <option value="inspectionSheets">
                  Inspection Sheets
                </option>
                <option value="blankingDimensions">
                  Blanking Dimensions
                </option>
                <option value="highCollapse">
                  High Collapse Values
                </option>
                <option value="gaugeTrackers">
                  Gauge Trackers
                </option>
              </b-select>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <input :disabled="!!!connection" class="submit button is-primary is-medium is-pulled-right" type="submit" value="Export">
            </div>
          </div>
        </form>
        <section>
          <b-field>
            <b-upload
              v-if="!dropFile"
              v-model="dropFile"
              :disabled="!!!connection"
              accept=".csv"
              drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large" />
                  </p>
                  <p>Drop your CSV here or click to upload</p>
                </div>
              </section>
            </b-upload>
          </b-field>

          <div v-if="dropFile" class="tags">
            <!-- <span v-for="(file, index) in dropFile"
                    :key="index"
                    class="tag is-primary" >
                    {{file.name}}
                    <button class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)">
                    </button>
                </span> -->
            <span class="tag is-primary">
              {{ dropFile.name }}
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile()" />
            </span>
          </div>
          <div>
            <b-button v-if="dropFile" :disabled="wrongFile" type="is-dark is-small has-text-uppercase" @click="openImportModal()">
              Import CSV
            </b-button>
          </div>
        </section>
      </div>
      <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        full-screen
        :can-cancel="false">
        <import-csv-report v-bind="formProps" @imported="importFinished" />
      </b-modal>
      <div class="content">
        <footer v-if="hasErrors.length > 0 || resultImportedData.length > 0" class="modal-card-foot">
          <div class="container level is-mobile">
            <div v-if="hasErrors.length > 0">
              <b-field>Errors:</b-field>
              <span v-for="(error, indexError) in hasErrors" :key="indexError">
                {{ error }}
              </span>
            </div>
            <div v-if="resultImportedData.length > 0">
              <!-- <b-table :data="resultImportedData" :columns="resultImportedHeader"></b-table> -->
              <!-- Header -->
              <div class="columns is-mobile is-centered">
                <div v-for="(header , indexHeader) in resultImportedHeader" :key="indexHeader" class="column">
                  {{ header }}
                </div>
              </div>
              <div v-for="(row, indexRow) in resultImportedData" :key="indexRow" class="columns is-mobile is-centered">
                <div v-for="(column, indexColumn) in row" :key="indexColumn" class="column">
                  <p :class="{'has-background-primary' : column.diff}">{{ column.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </span>
  </div>
</template>

<script>
import AdminApi from '@/apis/AdminApi'
import PapaParse from 'papaparse'
import ImportCsvReport from '@/components/modals/ImportCsvReport'

export default {
  name: 'Reports',
  components: {
    ImportCsvReport
  },
  data () {
    return {
      connectionId: null,
      report: 'pipeSpecs',
      loaded: false,
      dropFile: null,
      wrongFile: false,
      hasErrors: [],
      resultImportedData: [],
      resultImportedHeader: [],
      isComponentModalActive: false,
      formProps: {}
    }
  },
  computed: {
    connections () {
      return this.$store.state.connections
    },
    connection () {
      return this.connections.find(c => c.id === this.connectionId)
    }
  },
  async created () {
    this.$store.commit('setApp', 'manage')
    await this.$store.commit('setTitle', 'Reports')
    const loadingComponent = this.$buefy.loading.open({ container: null })
    await this.$store.dispatch('refreshConnections')
    loadingComponent.close()
    this.loaded = true
  },
  methods: {
    async exportReport (e) {
      e.preventDefault()
      // response is a string of csv text data, in csv format
      const loadingComponent = this.$buefy.loading.open({ container: null })
      const res = await AdminApi.exportReport(this.connection.name, this.report)
      const blobData = new Blob([res], { type: 'text/plain' })
      const fileUrl = window.URL.createObjectURL(blobData)

      // create timestamp measured in seconds
      const timeStamp = Math.floor(Date.now() / 1000)

      // initiate download with simulated download attribute
      const link = document.createElement('a')
      link.setAttribute('download', `${this.connection.name}_${this.report}_${timeStamp}.csv`)
      link.href = fileUrl
      document.body.appendChild(link)

      // wait for the link to be added to the document and simulate click
      window.requestAnimationFrame(() => {
        const event = new MouseEvent('click')
        link.dispatchEvent(event)
        document.body.removeChild(link)
      })

      loadingComponent.close()
    },
    deleteDropFile () {
      // this.dropFile.splice(index, 1)
      this.dropFile = null
      this.hasErrors = []
      this.wrongFile = false
    },
    openImportModal () {
      try {
        const reader = new FileReader()
        const file = this.dropFile

        reader.onload = async () => {
          // let typeSplited = file.type.split('/')

          // if (typeSplited[1].toLowerCase() === 'csv') {
          // Parse local CSV
          PapaParse.parse(file, {
            complete: async (results) => {
              try {
                if (results.data.length === 0) {
                  this.hasErrors.push('The uploaded CSV is empty.')
                  this.wrongFile = true
                } else {
                  this.isComponentModalActive = true
                  this.formProps = {
                    connection: this.connection,
                    reportType: this.report,
                    contentToImport: results.data,
                    file: file
                  }
                }
              } catch (error) {
                console.log('error=' + JSON.stringify(error))
                console.log('error.status=' + error.status)
                console.log('error.message=' + error.message)
              }
            }
          })
          // } else {
          //   this.hasErrors.push('Please, select a CSV.')
          //   this.wrongFile = true
          // }
        }

        reader.readAsText(file)
        // reader.readAsArrayBuffer(file)
      } catch (e) {
        this.hasErrors.items.push('fileRevision')
        console.error(e)
        // Snackbar.open(e)
        // await this.$validator.validateAll()
      }
    },
    importFinished () {
      this.deleteDropFile()
    },
    goImportCsvFile () {
      try {
        const reader = new FileReader()
        const file = this.dropFile

        reader.onload = async () => {
          // let typeSplited = file.type.split('/')

          // if (typeSplited[1] === 'csv') {
          // Parse local CSV
          PapaParse.parse(file, {
            complete: async (results) => {
              try {
                const importResponse = await AdminApi.importReport(this.connection.id, this.report, results.data)
                this.hasErrors = importResponse.messages

                if (importResponse.returnedCode === 0) {
                  this.wrongFile = false
                  this.resultImportedHeader = importResponse.data[0]
                  this.resultImportedData = importResponse.data
                  this.resultImportedData.shift()
                } else {
                  this.wrongFile = true
                }
              } catch (error) {
                console.log('error=' + JSON.stringify(error))
                console.log('error.status=' + error.status)
                console.log('error.message=' + error.message)
              }
            }
          })
          // } else {
          //   this.wrongFile = true
          // }
        }

        reader.readAsText(file)
        // reader.readAsArrayBuffer(file)
      } catch (e) {
        this.hasErrors.items.push('fileRevision')
        console.error(e)
        // Snackbar.open(e)
        // await this.$validator.validateAll()
      }
    }
  }
}

window.setImmediate = window.setTimeout
</script>

<style scoped>
  .wrapper {
    margin: 0 auto;
    width: 600px;
    margin-top: 100px;
  }

  .dropdown {
    font-size: 16px;
    padding: 5px;
  }

</style>
