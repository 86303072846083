import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {
  async getProjects (params) {
    options().params = params
    const response = await Axios.get(url('/api/v1/projects'), {
      params,
      auth: options().auth
    })

    return response.data
  },

  async getLastProjectID () {
    const response = await Axios.get(url('/api/v1/projects/lastId'), options())

    return response.data
  },

  async getProjectsByInvoiceId (invoiceId) {
    const response = await Axios.get(url(`/api/v1/projects/invoice/${invoiceId}`), options())

    return response.data
  },

  async getProjectsByOfficeUser (profile) {
    const response = await Axios.get(url(`/api/v1/projects/office/${profile.id}`), options())

    return response.data
  },

  async getProjectsByLicenseeId (profile) {
    const response = await Axios.get(url(`/api/v1/projects/licensee/${profile.licenseeId}`), options())

    return response.data
  },

  async getProjectsByLicenseeDivisions (profile) {
    const response = await Axios.get(url(`/api/v1/projects/licensee/${profile.licenseeId}/${profile.id}`), options())

    return response.data
  },

  async getProjectById (id) {
    const response = await Axios.get(url(`/api/v1/projects/${id}`), options())

    return response.data
  },

  async updateProject (id, project) {
    const response = await Axios.put(url(`/api/v1/projects/${id}`), project, options())

    return response.data
  },

  async createProject (project) {
    const response = await Axios.post(url('/api/v1/projects'), project, options())

    return response.data
  },

  async deleteProject (id) {
    const response = await Axios.delete(url(`/api/v1/projects/${id}`), options())

    return response.data
  },

  async deleteProjectByLicenseeId (id) {
    await Axios.delete(url(`/api/v1/projects/licensee/delete/${id}`), options())
    // return response.data ??
  },

  async deleteInvoiceFromProject (invoiceId) {
    const response = await Axios.delete(url(`/api/v1/projects/invoice/${invoiceId}`), options())

    return response.data
  },

  async cancelProject (id) {
    const response = await Axios.delete(url(`/api/v1/projects/cancel/${id}`), options())

    return response.data
  }

}
