<template>
  <div class="autocomplete-wrapper">
    <b-autocomplete
      v-model="text"
      data-vv-value-path="val"
      :placeholder="placeholder"
      :icon="icon"
      :disabled="disabled"
      :field="textField"
      :data="itemsFiltered"
      :open-on-focus="true"
      @select="selected"
      @input="changed"
      autocomplete="new-password">
      <template slot-scope="props">
        <strong>{{ props.option[textField || 'name'] }}</strong>
      </template>
      <span>&times;</span>
    </b-autocomplete>
    <div class="autocomplete-clear" v-if="val && !disabled" @click="clear">&times;</div>
  </div>
</template>

<script>
export default {
  name: 'autocomplete-single',
  props: ['name', 'placeholder', 'icon', 'value', 'items', 'textField', 'valueField', 'disabled'],
  watch: {
    value (v) {
      this.set(v)
    },
    items () {
      this.set(this.value)
    }
  },
  computed: {
    itemsFiltered () {
      return this.items.filter(
        i => {
          return i[this.textField]
            .toString()
            .toLowerCase()
            .indexOf(this.itemsFilter.toString().toLowerCase()) >= 0
        })
    }
  },
  methods: {
    set (v) {
      let selectedItem = this.items.find(i => i[this.valueField] === v)

      this.text = selectedItem ? selectedItem[this.textField] : null
      this.val = selectedItem ? selectedItem[this.valueField] : null
    },
    changed (o) {
      this.itemsFilter = o || ''

      let match = this.items.find(i => i[this.textField] === o)

      if (match) {
        this.text = match[this.textField]
        this.val = match[this.valueField]

        this.$emit('input', this.val)
      } else {
        this.val = null

        this.$emit('input', null)
      }
    },
    clear () {
      this.val = null
      this.text = null
    },
    selected (o) {
      if (o) {
        this.text = o[this.textField]
        this.val = o[this.valueField]

        this.$emit('input', this.val)
        this.$emit('after-changed', this.val)
      } else {
        this.val = null

        this.$emit('input', null)
        this.$emit('after-changed')
      }
    },
    validate () {
      setTimeout(() => {
        if (!this.val) {
          this.text = null
          this.val = null
        }
      }, 100)
    }
  },
  created () {
    this.set(this.value)
  },
  data () {
    return {
      text: null,
      val: null,
      itemsFilter: ''
    }
  }
}
</script>
