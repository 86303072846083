<template>
  <div style="max-width: 600px; margin: 0 auto">
    <div class="is-size-4 mb-4">Gauge Parameters</div>
    <hr />
    <b-collapse
      class="card"
      animation="slide"
      v-for="(categoryKey, index) in Object.keys(gaugeParametersGrouped)"
      :key="index"
      :open="isOpen == index"
      @open="isOpen = index">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          :aria-expanded="props.open">
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'chevron-down' : 'chevron-right'" />
          </a>
          <p class="card-header-title is-capitalized">
            {{ categoryKey }}s
          </p>
          <b-button
            @click.stop="openItem({category: categoryKey, action: 'create'})"
            type="is-primary"
            icon-left="plus"
            label="New"
          />
        </div>
      </template>
      <div class="card-content">
        <div class="card" v-for="item in gaugeParametersGrouped[categoryKey]" :key="item.id">
          <div class="level">
            {{ item.name }}
            <b-button @click.stop="openItem(item)" type="is-primary is-light" icon-left="pencil" />
          </div>
        </div>
      </div>
    </b-collapse>
    <div class="padding-y-6" />
  </div>
</template>

<script>
import GaugeParameterModal from '@/components/modals/GaugeParameter'

export default {
  data () {
    return {
      isOpen: 0
    }
  },
  async mounted () {
    this.$store.commit('setTitle', 'Gauge Parameters')
    this.loadParameters()
  },
  computed: {
    gaugeParametersGrouped () {
      const gaugeParameters = this.$store.state.gaugeParameters
      return {
        manufacturer: gaugeParameters.filter((gp) => gp && gp.category === 'manufacturer'),
        type: gaugeParameters.filter((gp) => gp && gp.category === 'type'),
        calibrator: gaugeParameters.filter((gp) => gp && gp.category === 'calibrator')
      }
    }
  },
  methods: {
    async loadParameters () {
      try {
        await this.$store.dispatch('refreshGaugeParameters')
      } catch (e) {
        console.log(e)
      }
    },
    openItem (item) {
      this.$buefy.modal.open({
        parent: this,
        component: GaugeParameterModal,
        customClass: 'skinny',
        props: {
          item
        },
        events: {
          'reload-parameters': this.loadParameters
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse {
  margin-bottom: 15px;
  .card-header {
    padding: 1rem 1.5rem;
    font-size: 18px;
    display: flex;
    align-items: center;
    .card-header-icon {
      padding: 0;
    }
  }
  .card-content {
    flex: 1;
    .card {
      margin-bottom: 5px;
      background: #f3f3f3;
      padding: 10px 15px;
    }
  }
}
</style>
