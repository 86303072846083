<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        Preview import values for {{ connection.name }} - {{ reportType }} - {{ file.name }}
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false">
      <div style="flex-direction: column; align-self: flex-start; text-align: center; z-index: 998; padding-top: 100px;">
        <div class="loading-icon" />
        <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
          Loading data to import...
        </div>
      </div>
    </b-loading>
    <section class="modal-card-body is-paddingless">
      <div class="container">
        <div v-if="hasErrors.length > 0">
          <b-field>Errors:</b-field>
          <span v-for="(error, indexError) in hasErrors" :key="indexError" class="error">
            <br>{{ error }}
          </span>
        </div>
        <div v-if="resultImportedData.length > 0">
          <table width="100%" border="1">
            <thead class="table-header">
              <tr>
                <th v-for="(header , index) in resultImportedHeader" :key="index">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tr v-for="(row, indexRow) in resultImportedData" :key="indexRow" :class="row[0].new ? 'new-row' : row[0].error ? 'error' : ''">
              <td v-for="(column, indexColumn) in row" :key="indexColumn" :class="column.error ? 'error' : column.diff ? 'cell-changed' : ''">
                <span>{{ column.value }}</span>
                <b-tooltip v-if="column.error && column.message" type="is-dark" multilined size="is-large" position="is-left">
                  <b-icon icon="info-circle" size="is-small" />
                  <template v-slot:content>
                    <span v-html="column.message" />
                  </template>
                </b-tooltip>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="close">
            Cancel
          </button>
          <button :disabled="!goodForImport" class="button is-success" @click="goImportContent">
            Import
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import AdminApi from '@/apis/AdminApi'

export default {
  props: {
    connection: {
      required: true,
      type: Object
    },
    reportType: {
      required: true,
      type: String
    },
    contentToImport: {
      required: true,
      type: Array
    },
    file: {
      required: true,
      type: File
    }
  },
  data () {
    return {
      goodForImport: false,
      hasErrors: [],
      resultImportedData: [],
      resultImportedHeader: [],
      loading: true
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    }
  },

  created () {
    this.loadImportContent()
  },
  methods: {
    async loadImportContent (goImport = false) {
      // eslint-disable-next-line
      return new Promise(async resolve => {
        const importResponse = await AdminApi.importReport(this.connection.id, this.reportType, { data: this.contentToImport, import: goImport })

        this.hasErrors = importResponse.messages
        this.goodForImport = importResponse.goodForImport
        this.resultImportedHeader = importResponse.data[0]
        this.resultImportedData = importResponse.data
        this.resultImportedData.shift()
        this.loading = false

        resolve(importResponse)
      })
    },
    async goImportContent () {
      try {
        this.$buefy.dialog.confirm({
          message: 'Are you sure you want to import this data? This process can\'t be undo.',
          confirmText: 'Go Import!',
          type: 'is-danger',
          onConfirm: async () => {
            this.loading = true
            const importResponse = await this.loadImportContent(true)
            this.loading = false

            if (importResponse.returnedCode === 0) {
              this.$emit('imported')
              this.$buefy.toast.open('CSV Imported.')
              this.$parent.close()
            }
          }
        })
      } catch (error) {
        console.log('error=' + JSON.stringify(error))
        console.log('error.status=' + error.status)
        console.log('error.message=' + error.message)
      }
    },
    close () {
      this.$parent.close()
    }
  }
}

</script>
<style lang="scss">
.error {
  background-color:#ff0000;
  color:white;
}
td {
    white-space: nowrap;
}
.table-header {
  background-color:#669999; color:white;
}
.cell-changed {
  background-color:#2591f5; color:white;
}
.new-row {
  background-color:green; color:white;
}
</style>
