<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        {{ size.size }}" — {{ sizeData[0].value }}#
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="padding-xy-3 padding-xy-4-tablet">
        <b-field label="Weight" style="margin-bottom: 5px;" />
        <b-field class="required" :type="{'is-danger': errors.has('weight')}" :message="errors.first('weight')">
          <b-input v-model="sizeData[0].value" v-validate="'required|validateWeight:sizeData[0].value'" name="weight" data-vv-as="weight" />
          <p class="control">
            <span class="button is-static">
              #
            </span>
          </p>
        </b-field>
      </div>
      <hr class="margin-y-3 margin-y-0-tablet">
      <div class="">
        <div class="is-size-4 has-text-weight-bold has-text-black is-clickable" @click="toggleSpecsFunc">
          <a class="padding-xy-3 padding-xy-4-tablet align-center">
            <div v-if="!toggleSpecs" class="is-inline-flex"><b-icon icon="caret-right" /></div>
            <b-icon v-else icon="caret-down" />
            <span class="margin-x-2">Pipe Specs</span>
          </a>
        </div>
        <div v-if="toggleSpecs" class="spec_columns padding-x-4 padding-x-5-tablet" style="margin-bottom: 30px;">
          <div v-for="spec in sizeDataWeightless" :key="spec.label">
            <b-field :label="spec.label" style="margin-bottom: 5px;" />
            <b-field v-if="spec.field !== 'weight'">
              <b-input v-model="spec.value" style="width: 100px" :value="spec.value" />
              <p v-show="spec.metric" class="control">
                <span class="button is-static">
                  {{ spec.metric }}
                </span>
              </p>
              <small v-show="spec.value && spec.metric === '°/100ft'" class="padding-y-2 padding-x-3" />
              <small v-show="spec.value && spec.metric === 'in'" class="padding-y-2 padding-x-3">{{ inchmm(spec.value) }} mm</small>
            </b-field>
          </div>
        </div>
      </div>
      <hr class="margin-y-3 margin-y-0-tablet">
      <div class="">
        <div class="is-size-4 has-text-weight-bold has-text-black is-clickable" @click="toggleTorqueFunc">
          <a class="align-center padding-xy-3 padding-xy-4-tablet">
            <div v-if="!toggleTorque" class="is-inline-flex"><b-icon icon="caret-right" /></div>
            <b-icon v-else icon="caret-down" />
            <span class="margin-x-2">Torque Values</span>
          </a>
        </div>
        <b-tabs v-if="toggleTorque" v-model="torqueAlloyTab" type="is-toggle" size="is-medium" expanded class="padding-x-4 padding-x-5-tablet" style="margin-bottom: 30px;">
          <b-tab-item label="Carbon">
            <div class="padding-y-3">
              <b-tabs v-model="torqueCarbonTab" type="is-toggle" expanded>
                <b-tab-item v-for="torque in torqueDataCarbon" :key="torque.size" :label="torque.size">
                  <div class="padding-y-3">
                    <div class="is-size-5 has-text-grey-dark has-text-weight-bold" style="margin-bottom: 15px;">
                      API Modified (Carbon)
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">Final Torque</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Min
                              </span>
                            </p>
                            <b-input v-model="torque.ftMinApi" expanded />
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Opt
                              </span>
                            </p>
                            <b-input v-model="torque.ftOptApi" expanded />
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Max
                              </span>
                            </p>
                            <b-input v-model="torque.ftMaxApi" expanded />
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">Shoulder Torque</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Min
                              </span>
                            </p>
                            <b-input v-model="torque.stMinApi" expanded />
                          </b-field>
                        </div>
                        <div class="column is-hidden-mobile" />
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Max
                              </span>
                            </p>
                            <b-input v-model="torque.stMaxApi" expanded />
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">MTS</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-input v-model="torque.mtsApi" expanded />
                          </b-field>
                        </div>
                        <div class="column is-hidden-mobile" />
                        <div class="column is-hidden-mobile" />
                      </div>
                    </div>
                  </div>
                  <hr class="margin-y-2">
                  <div class="padding-y-3">
                    <div class="is-size-5 has-text-grey-dark has-text-weight-bold" style="margin-bottom: 15px;">
                      Clear-Run (Carbon)
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">Final Torque</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Min
                              </span>
                            </p>
                            <b-input v-model="torque.ftMinClr" expanded />
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Opt
                              </span>
                            </p>
                            <b-input v-model="torque.ftOptClr" expanded />
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Max
                              </span>
                            </p>
                            <b-input v-model="torque.ftMaxClr" expanded />
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">Shoulder Torque</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Min
                              </span>
                            </p>
                            <b-input v-model="torque.stMinClr" expanded />
                          </b-field>
                        </div>
                        <div class="column is-hidden-mobile" />
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Max
                              </span>
                            </p>
                            <b-input v-model="torque.stMaxClr" expanded />
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">MTS</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-input v-model="torque.mtsClr" expanded />
                          </b-field>
                        </div>
                        <div class="column is-hidden-mobile" />
                        <div class="column is-hidden-mobile" />
                      </div>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </b-tab-item>
          <b-tab-item label="High Chrome">
            <div class="padding-y-3">
              <b-tabs v-model="torqueChromeTab" type="is-toggle" expanded>
                <b-tab-item v-for="torqueChrome in torqueDataChrome" :key="torqueChrome.size" :label="torqueChrome.size">
                  <div class="padding-y-3">
                    <div class="is-size-5 has-text-grey-dark has-text-weight-bold" style="margin-bottom: 15px;">
                      API Modified (High-Chrome)
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">Final Torque</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Min
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.ftMinApiChrome" expanded />
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Opt
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.ftOptApiChrome" expanded />
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Max
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.ftMaxApiChrome" expanded />
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">Shoulder Torque</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Min
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.stMinApiChrome" expanded />
                          </b-field>
                        </div>
                        <div class="column is-hidden-mobile" />
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Max
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.stMaxApiChrome" expanded />
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">MTS</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-input v-model="torqueChrome.mtsApiChrome" expanded />
                          </b-field>
                        </div>
                        <div class="column is-hidden-mobile" />
                        <div class="column is-hidden-mobile" />
                      </div>
                    </div>
                  </div>
                  <hr class="margin-y-2">
                  <div class="padding-y-3">
                    <div class="is-size-5 has-text-grey-dark has-text-weight-bold" style="margin-bottom: 15px;">
                      Clear-Run (High-Chrome)
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">Final Torque</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Min
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.ftMinClrChrome" expanded />
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Opt
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.ftOptClrChrome" expanded />
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Max
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.ftMaxClrChrome" expanded />
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">Shoulder Torque</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Min
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.stMinClrChrome" expanded />
                          </b-field>
                        </div>
                        <div class="column is-hidden-mobile" />
                        <div class="column">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                Max
                              </span>
                            </p>
                            <b-input v-model="torqueChrome.stMaxClrChrome" expanded />
                          </b-field>
                        </div>
                      </div>
                    </div>
                    <div class="padding-y-2">
                      <div class="columns is-vcentered is-variable is-1">
                        <div class="column">
                          <label class="label">MTS</label>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-input v-model="torqueChrome.mtsClrChrome" expanded />
                          </b-field>
                        </div>
                        <div class="column is-hidden-mobile" />
                        <div class="column is-hidden-mobile" />
                      </div>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
      <hr class="margin-y-3 margin-y-0-tablet">
      <div class="">
        <div class="is-size-4 has-text-weight-bold has-text-black is-clickable" @click="toggleCouplingFunc">
          <a class="align-center padding-xy-3 padding-xy-4-tablet">
            <div v-if="!toggleCoupling" class="is-inline-flex"><b-icon icon="caret-right" /></div>
            <b-icon v-else icon="caret-down" />
            <span class="margin-x-2">Coupling Values</span>
            <b-button
              v-if="toggleCoupling"
              style="margin-left: auto"
              icon-left="plus"
              type="is-primary"
              rounded
              @click.stop="addCoupling()">
              Add Coupling
            </b-button>
          </a>
        </div>
        <div v-if="toggleCoupling">
          <b-tabs v-if="couplingData.length > 0 && couplingTab !== null" v-model="couplingTab" type="is-toggle" expanded class="padding-x-4 padding-x-5-tablet mb-5" @input="changeTab">
            <b-tab-item v-for="(coupling, index) in couplingData.filter(c => !c.delete)" :key="index" :label="coupling.name">
              <div class="pt-4">
                <div class="columns">
                  <div class="column">
                    <b-field label="Coupling Name" class="required" :type="{'is-danger': errors.has('couplingName')}" :message="errors.first('couplingName')">
                      <b-input v-model="coupling.name" v-validate="'required|validateDuplicatedCoupling:coupling.name'" name="couplingName" data-vv-as="couplingName" />
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <div class="field">
                      <label class="label">&nbsp;</label>
                      <b-button
                        type="is-danger"
                        icon-left="trash-alt"
                        @click="deleteCoupling(coupling)" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="padding-y-3" style="padding-bottom: 0">
                <div class="padding-y-2">
                  <div class="columns" style="margin-bottom: 0">
                    <div class="column">
                      <b-field class="is-inline-block is-marginless" style="padding-right: 15px">
                        <small>Display on:</small>
                      </b-field>
                      <b-field class="is-inline-block is-marginless" style="padding-right: 15px; vertical-align: middle;">
                        <b-switch v-model="coupling.datasheetsEnabled" true-value="1" false-value="0" type="is-success">
                          Data Sheets
                        </b-switch>
                      </b-field>
                      <b-field class="is-inline-block is-marginless" style="padding-right: 15px; vertical-align: middle;">
                        <b-switch v-model="coupling.inspectionSheetsEnabled" true-value="1" false-value="0" type="is-success">
                          Inspection Sheets
                        </b-switch>
                      </b-field>
                      <b-field class="is-inline-block is-marginless" style="padding-right: 15px; vertical-align: middle;">
                        <b-switch v-model="coupling.blankingDimensionsEnabled" true-value="1" false-value="0" type="is-success">
                          Blanking Dimensions
                        </b-switch>
                      </b-field>
                    </div>
                  </div>
                  <hr style="margin-top: 10px">
                  <div class="columns is-vcentered is-variable">
                    <div class="column is-size-5 has-text-weight-bold">
                      Data Sheet Values
                    </div>
                  </div>

                  <div class="padding-y-2">
                    <div class="columns">
                      <div class="column is-half">
                        <label class="label">Coupling OD/Turning Diameter</label>
                        <b-field>
                          <b-input v-model="coupling.cplgOd" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <label class="label">Upper Tol</label>
                        <b-field>
                          <b-input v-model="coupling.upperTolMax" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <label class="label">Lower Tol</label>
                        <b-field>
                          <b-input v-model="coupling.lowerTolMin" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in
                            </span>
                          </p>
                        </b-field>
                      </div>
                    </div>
                  </div>

                  <div class="padding-y-2">
                    <div class="columns">
                      <div class="column">
                        <label class="label">TE</label>
                        <b-field>
                          <b-input v-model="coupling.te" expanded />
                          <p class="control">
                            <span class="button is-static">
                              %
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <label class="label">JTE</label>
                        <b-field>
                          <b-input v-model="coupling.jte" expanded />
                          <p class="control">
                            <span class="button is-static">
                              %
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <label class="label">MBFW - 45&deg;</label>
                        <b-field>
                          <b-input v-model="coupling.mbFw45" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <label class="label">MBFW - 20&deg;</label>
                        <b-field>
                          <b-input v-model="coupling.mbFw20" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in
                            </span>
                          </p>
                        </b-field>
                      </div>
                    </div>
                  </div>

                  <div class="padding-y-2">
                    <div class="columns">
                      <div class="column">
                        <label class="label">BF OD - 45&deg;</label>
                        <b-field>
                          <b-input v-model="coupling.bfOd45" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <label class="label">BF OD - 20&deg;</label>
                        <b-field>
                          <b-input v-model="coupling.bfOd20" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <label class="label">BF Area - 45&deg;</label>
                        <b-field>
                          <b-input v-model="coupling.bfAr45" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in<sup>2</sup>
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <div class="column">
                        <label class="label">BF Area - 20&deg;</label>
                        <b-field>
                          <b-input v-model="coupling.bfAr20" expanded />
                          <p class="control">
                            <span class="button is-static">
                              in<sup>2</sup>
                            </span>
                          </p>
                        </b-field>
                      </div>
                    </div>
                  </div>
                  <div class="pt-4">
                    <div class="columns">
                      <div class="column">
                        <b-field class="is-inline-block is-marginless" style="padding-right: 15px; vertical-align: middle;">
                          <b-switch v-model="coupling.specialClearance" true-value="1" false-value="0" type="is-success">
                            Special Clearance
                          </b-switch>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>

        <!-- End new stuff for Coupling -->
        <!-- <b-tabs v-if="toggleCoupling" v-model="couplingTab" type="is-toggle" expanded class="padding-x-4 padding-x-5-tablet" style="margin-bottom: 30px;">
          <b-tab-item v-for="coupling in couplingData" :key="coupling.size" :label="coupling.size">
            <div class="padding-y-3" style="padding-bottom: 0">
              <div class="padding-y-2">
                <div class="columns" style="margin-bottom: 0">
                  <div class="column">
                    <b-field class="is-inline-block is-marginless" style="padding-right: 15px">
                      <small>Display on:</small>
                    </b-field>
                    <b-field class="is-inline-block is-marginless" style="padding-right: 15px; vertical-align: middle;">
                      <b-switch v-model="coupling.cplgEnabled" true-value="1" false-value="0" type="is-success">
                        Data Sheets
                      </b-switch>
                    </b-field>
                    <b-field class="is-inline-block is-marginless" style="padding-right: 15px; vertical-align: middle;">
                      <b-switch v-model="coupling.cplgInsEnabled" true-value="1" false-value="0" type="is-success">
                        Inspection Sheets
                      </b-switch>
                    </b-field>
                    <b-field class="is-inline-block is-marginless" style="padding-right: 15px; vertical-align: middle;">
                      <b-switch v-model="coupling.cplgBlnkEnabled" true-value="1" false-value="0" type="is-success">
                        Blanking Dimensions
                      </b-switch>
                    </b-field>
                  </div>
                </div>
                <hr style="margin-top: 10px">
                <div class="columns is-vcentered is-variable">
                  <div class="column is-size-5 has-text-weight-bold">
                    Data Sheet Values
                  </div>
                </div>
                <div class="padding-y-2">
                  <div class="columns">
                    <div class="column is-half">
                      <label class="label">Coupling OD/Turning Diameter</label>
                      <b-field>
                        <b-input v-model="coupling.cplgOd" expanded />
                        <p class="control">
                          <span class="button is-static">
                            in
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <label class="label">Upper Tol</label>
                      <b-field>
                        <b-input v-model="getBlankDimensionsDataCouplingField('dimTurnDiameterBox' + coupling.size + 'Max' ).value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getBlankDimensionsDataCouplingField('dimTurnDiameterBox' + coupling.size + 'Max' ).metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <label class="label">Lower Tol</label>
                      <b-field>
                        <b-input v-model="getBlankDimensionsDataCouplingField('dimTurnDiameterBox' + coupling.size + 'Min' ).value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getBlankDimensionsDataCouplingField('dimTurnDiameterBox' + coupling.size + 'Min' ).metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="padding-y-2">
                  <div class="columns">
                    <div class="column is-one-quarter">
                      <label class="label">TE</label>
                      <b-field>
                        <b-input v-model="coupling.cplgTe" expanded />
                        <p class="control">
                          <span class="button is-static">
                            %
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column is-one-quarter">
                      <label class="label">JTE</label>
                      <b-field>
                        <b-input v-model="coupling.cplgJte" expanded />
                        <p class="control">
                          <span class="button is-static">
                            %
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="padding-y-2">
                  <div class="columns">
                    <div class="column">
                      <label class="label">BF OD - 45&deg;</label>
                      <b-field>
                        <b-input v-model="coupling.cplgBfOd45" expanded />
                        <p class="control">
                          <span class="button is-static">
                            in
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <label class="label">BF OD - 20&deg;</label>
                      <b-field>
                        <b-input v-model="coupling.cplgBfOd20" expanded />
                        <p class="control">
                          <span class="button is-static">
                            in
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <label class="label">BF Area - 45&deg;</label>
                      <b-field>
                        <b-input v-model="coupling.cplgBfAr45" expanded />
                        <p class="control">
                          <span class="button is-static">
                            in<sup>2</sup>
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <label class="label">BF Area - 20&deg;</label>
                      <b-field>
                        <b-input v-model="coupling.cplgBfAr20" expanded />
                        <p class="control">
                          <span class="button is-static">
                            in<sup>2</sup>
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs> -->
      </div>

      <hr class="margin-y-3 margin-y-0-tablet">
      <div class="">
        <div class="is-size-4 has-text-weight-bold has-text-black is-clickable" @click="toggleInspectionFunc">
          <a class="align-center padding-xy-3 padding-xy-4-tablet">
            <div v-if="!toggleInspection" class="is-inline-flex"><b-icon icon="caret-right" /></div>
            <b-icon v-else icon="caret-down" />
            <span class="margin-x-2">Inspection Sheets</span>
          </a>
        </div>
        <div v-if="toggleInspection" type="is-toggle" expanded class="padding-x-4 padding-x-5-tablet" style="margin-bottom: 30px;">
          <div class="padding-y-2">
            <b-tabs v-model="inspectionTabs" type="is-toggle" size="is-medium" expanded>
              <b-tab-item label="Pin">
                <div class="padding-y-3">
                  <div class="columns">
                    <div class="column is-8">
                      <b-field :label="getInspectionFields('inspPinDwgName').label">
                        <b-input v-model="getInspectionFields('inspPinDwgName').value" expanded />
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Revision">
                        <div class="body">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspPinDwgRev').metric }}
                              </span>
                            </p>
                            <b-input v-model="getInspectionFields('inspPinDwgRev').value" expanded />
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-variable is-1" style="margin-bottom: 1.25em;">
                    <div class="column">
                      <b-field :label="getInspectionFields('inspPinMinLfc').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspPinMinLfc').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspPinMinLfc').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :label="getInspectionFields('inspPinCplt').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspPinCplt').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspPinCplt').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :label="getInspectionFields('inspPinTrpd').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspPinTrpd').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspPinTrpd').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field :label="getInspectionFields('inspPinTpi').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspPinTpi').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspPinTpi').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>

                    <div class="column">
                      <b-field :label="getInspectionFields('inspPinThreadDiaOvality').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspPinThreadDiaOvality').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspPinThreadDiaOvality').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :label="getInspectionFields('inspPinSealDiaOvality').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspPinSealDiaOvality').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspPinSealDiaOvality').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </div>

                  <hr>

                  <div class="columns is-variable is-1 has-text-centered is-hidden-mobile">
                    <div class="column has-text-left" style="margin-bottom: 2px" />
                    <div class="column">
                      <b-field label="Upper Tol" />
                    </div>
                    <div class="column">
                      <b-field label="Nominal" />
                    </div>
                    <div class="column">
                      <b-field label="Lower Tol" />
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinThreadDiaNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinThreadDiaUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinThreadDiaUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinThreadDiaNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinThreadDiaNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinThreadDiaLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinThreadDiaLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinSealDiaNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinSealDiaUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinSealDiaUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinSealDiaNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinSealDiaNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinSealDiaLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinSealDiaLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinLeadNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinLeadUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinLeadUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinLeadNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinLeadNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinLeadLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinLeadLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinCumLeadNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinCumLeadUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinCumLeadUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinCumLeadNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinCumLeadNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinCumLeadLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinCumLeadLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinTaperPNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinTaperPUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinTaperPUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinTaperPNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinTaperPNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinTaperPLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinTaperPLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinTaperINom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinTaperIUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinTaperIUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinTaperINom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinTaperINom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinTaperILt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinTaperILt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinThreadHtNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinThreadHtUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinThreadHtUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinThreadHtNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinThreadHtNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinThreadHtLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinThreadHtLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinNoseOdNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinNoseOdUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinNoseOdUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinNoseOdNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinNoseOdNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinNoseOdLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinNoseOdLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinNoseLenNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinNoseLenUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinNoseLenUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinNoseLenNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinNoseLenNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinNoseLenLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinNoseLenLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinBoreLenNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinBoreLenUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinBoreLenUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinBoreLenNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinBoreLenNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinBoreLenLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinBoreLenLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinBoreIdNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinBoreIdUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinBoreIdUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinBoreIdNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinBoreIdNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinBoreIdLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinBoreIdLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspPinRuninRunoutNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinRuninRunoutUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinRuninRunoutUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinRuninRunoutNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinRuninRunoutNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspPinRuninRunoutLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspPinRuninRunoutLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <hr>
                  <b-field :label="getInspectionFields('inspPinFooter1').label + ' ' + getInspectionFields('inspPinFooter1').metric">
                    <b-input v-model="getInspectionFields('inspPinFooter1').value" type="textarea" expanded rows="2" />
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item label="Box">
                <div class="padding-y-3">
                  <div class="columns">
                    <div class="column is-8">
                      <b-field :label="getInspectionFields('inspBoxDwgName').label">
                        <b-input v-model="getInspectionFields('inspBoxDwgName').value" expanded />
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="Revision">
                        <div class="body">
                          <b-field>
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspBoxDwgRev').metric }}
                              </span>
                            </p>
                            <b-input v-model="getInspectionFields('inspBoxDwgRev').value" expanded />
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns" style="margin-bottom: 1.25em;">
                    <div class="column">
                      <b-field :label="getInspectionFields('inspMaxTro').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspMaxTro').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspMaxTro').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>

                    <div class="column">
                      <b-field :label="getInspectionFields('inspMinPtl').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspMinPtl').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspMinPtl').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>

                    <div class="column">
                      <b-field :label="getInspectionFields('inspBoxTrpd').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspBoxTrpd').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspBoxTrpd').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field :label="getInspectionFields('inspBoxShoesLength').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspBoxShoesLength').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspBoxShoesLength').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>

                    <div class="column">
                      <b-field :label="getInspectionFields('inspBoxThreadDiaOvality').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspBoxThreadDiaOvality').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspBoxThreadDiaOvality').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :label="getInspectionFields('inspBoxSealDiaOvality').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspBoxSealDiaOvality').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspBoxSealDiaOvality').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field :label="getInspectionFields('inspBoxCpSealDia').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspBoxCpSealDia').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspBoxCpSealDia').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field :label="getInspectionFields('inspBoxCpLeadTaper').label">
                        <div class="body">
                          <b-field>
                            <b-input v-model="getInspectionFields('inspBoxCpLeadTaper').value" expanded />
                            <p class="control">
                              <span class="button is-static">
                                {{ getInspectionFields('inspBoxCpLeadTaper').metric }}
                              </span>
                            </p>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </div>

                  <hr>

                  <div class="columns is-variable is-1 has-text-centered is-hidden-mobile">
                    <div class="column has-text-left" style="margin-bottom: 2px" />
                    <div class="column">
                      <b-field label="Upper Tol" />
                    </div>
                    <div class="column">
                      <b-field label="Nominal" />
                    </div>
                    <div class="column">
                      <b-field label="Lower Tol" />
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxThreadDiaNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxThreadDiaUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxThreadDiaUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxThreadDiaNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxThreadDiaNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxThreadDiaLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxThreadDiaLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxSealDiaNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxSealDiaUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxSealDiaUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxSealDiaNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxSealDiaNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxSealDiaLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxSealDiaLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxLeadNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxLeadUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxLeadUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxLeadNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxLeadNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxLeadLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxLeadLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxCumLeadNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxCumLeadUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxCumLeadUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxCumLeadNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxCumLeadNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxCumLeadLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxCumLeadLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxTaperPNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxTaperPUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxTaperPUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxTaperPNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxTaperPNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxTaperPLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxTaperPLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxTaperINom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxTaperIUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxTaperIUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxTaperINom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxTaperINom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxTaperILt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxTaperILt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxThreadHtNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxThreadHtUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxThreadHtUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxThreadHtNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxThreadHtNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxThreadHtLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxThreadHtLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxNoseIdNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxNoseIdUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxNoseIdUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxNoseIdNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxNoseIdNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxNoseIdLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxNoseIdLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxCoupLenNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxCoupLenUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxCoupLenUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxCoupLenNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxCoupLenNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxCoupLenLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxCoupLenLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">Coupling OD</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspCoupOdUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspCoupOdUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field />
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspCoupOdLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspCoupOdLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspShoulDepNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspShoulDepUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspShoulDepUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspShoulDepNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspShoulDepNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspShoulDepLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspShoulDepLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns is-vcentered is-variable is-1">
                    <div class="column">
                      <label class="label has-text-left">{{ getInspectionFields('inspBoxBoreIdNom').label }}</label>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxBoreIdUt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxBoreIdUt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxBoreIdNom').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxBoreIdNom').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-input v-model="getInspectionFields('inspBoxBoreIdLt').value" expanded />
                        <p class="control">
                          <span class="button is-static">
                            {{ getInspectionFields('inspBoxBoreIdLt').metric }}
                          </span>
                        </p>
                      </b-field>
                    </div>
                  </div>

                  <hr>
                  <b-field :label="getInspectionFields('inspBoxFooter1').label + ' ' + getInspectionFields('inspBoxFooter1').metric">
                    <b-input v-model="getInspectionFields('inspBoxFooter1').value" type="textarea" expanded rows="2" />
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>

      <hr class="margin-y-3 margin-y-0-tablet">
      <div class="">
        <div class="is-size-4 has-text-weight-bold has-text-black is-clickable" @click="toggleBlankingFunc">
          <a class="align-center padding-xy-3 padding-xy-4-tablet">
            <div v-if="!toggleBlanking" class="is-inline-flex"><b-icon icon="caret-right" /></div>
            <b-icon v-else icon="caret-down" />
            <span class="margin-x-2">Blanking Dimensions</span>
          </a>
        </div>
        <div v-if="toggleBlanking" type="is-toggle" expanded class="padding-x-4 padding-x-5-tablet" style="margin-bottom: 30px;">
          <div class="columns">
            <div class="column">
              <b-field :label="getBlankDimensionsDataField('threadRecutLen').label">
                <div class="body">
                  <b-field>
                    <b-input v-model="getBlankDimensionsDataField('threadRecutLen').value" expanded />
                    <p class="control">
                      <span class="button is-static">
                        {{ getBlankDimensionsDataField('threadRecutLen').metric }}
                      </span>
                    </p>
                  </b-field>
                </div>
              </b-field>
            </div>
            <div class="column">
              <b-field :label="getBlankDimensionsDataField('threadRecutLenExtraPin').label">
                <div class="body">
                  <b-field>
                    <b-input v-model="getBlankDimensionsDataField('threadRecutLenExtraPin').value" expanded />
                    <p class="control">
                      <span class="button is-static">
                        {{ getBlankDimensionsDataField('threadRecutLenExtraPin').metric }}
                      </span>
                    </p>
                  </b-field>
                </div>
              </b-field>
            </div>
            <div class="column">
              <b-field :label="getBlankDimensionsDataField('threadRecutLenExtraBox').label">
                <div class="body">
                  <b-field>
                    <b-input v-model="getBlankDimensionsDataField('threadRecutLenExtraBox').value" expanded />
                    <p class="control">
                      <span class="button is-static">
                        {{ getBlankDimensionsDataField('threadRecutLenExtraBox').metric }}
                      </span>
                    </p>
                  </b-field>
                </div>
              </b-field>
            </div>
          </div>

          <hr class="margin-y-1">
          <div class="padding-y-3">
            <div class="columns is-vcentered is-variable is-1 has-text-centered is-hidden-mobile">
              <div class="column has-text-left is-size-5 has-text-weight-bold" style="margin-bottom: 0px">
                Pin Values
              </div>
              <div class="column">
                <b-field label="Upper Tol" />
              </div>
              <div class="column">
                <b-field label="Nominal" />
              </div>
              <div class="column">
                <b-field label="Lower Tol" />
              </div>
            </div>
            <div class="columns is-vcentered is-variable is-1">
              <div class="column">
                <label class="label has-text-left">Boring/Turning Length</label>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnLenPinMax').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnLenPinMax').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnLenPin').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnLenPin').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnLenPinMin').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnLenPinMin').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="columns is-vcentered is-variable is-1">
              <div class="column">
                <label class="label has-text-left">Boring Diameter</label>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimBorDiameterPinMax').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimBorDiameterPinMax').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimBorDiameterPin').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimBorDiameterPin').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimBorDiameterPinMin').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimBorDiameterPinMin').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="columns is-vcentered is-variable is-1">
              <div class="column">
                <label class="label has-text-left">Coupling OD/Turning Diameter</label>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnDiameterPinMax').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnDiameterPinMax').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnDiameterPin').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnDiameterPin').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnDiameterPinMin').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnDiameterPinMin').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
            </div>
          </div>

          <hr class="margin-y-1">
          <div class="padding-y-3">
            <div class="columns is-vcentered is-variable is-1 has-text-centered is-hidden-mobile">
              <div class="column has-text-left is-size-5 has-text-weight-bold" style="margin-bottom: 0px">
                Box Values
              </div>
              <div class="column">
                <b-field label="Upper Tol" />
              </div>
              <div class="column">
                <b-field label="Nominal" />
              </div>
              <div class="column">
                <b-field label="Lower Tol" />
              </div>
            </div>
            <div class="columns is-vcentered is-variable is-1">
              <div class="column">
                <label class="label has-text-left">Boring/Turning Length</label>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnLenBoxMax').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnLenBoxMax').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnLenBox').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnLenBox').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimTurnLenBoxMin').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimTurnLenBoxMin').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="columns is-vcentered is-variable is-1">
              <div class="column">
                <label class="label has-text-left">Boring Diameter</label>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimBorDiameterBoxMax').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimBorDiameterBoxMax').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimBorDiameterBox').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimBorDiameterBox').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
              <div class="column">
                <b-field>
                  <b-input v-model="getBlankDimensionsDataField('dimBorDiameterBoxMin').value" expanded />
                  <p class="control">
                    <span class="button is-static">
                      {{ getBlankDimensionsDataField('dimBorDiameterBoxMin').metric }}
                    </span>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="columns is-vcentered is-variable is-1" style="margin-top: 15px">
              <div class="column">
                <label class="label has-text-left">Coupling OD/Turning Diameter</label>
              </div>
              <div class="column has-text-left is-9">
                Manage in the Coupling Values tab above
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="margin-y-3 margin-y-0-tablet">
      <div class="">
        <div class="is-size-4 has-text-weight-bold has-text-black is-clickable" @click="toggleGradeVisibilityFunc">
          <a class="padding-xy-3 padding-xy-4-tablet align-center">
            <div v-if="!toggleGradeVisibility" class="is-inline-flex"><b-icon icon="caret-right" /></div>
            <b-icon v-else icon="caret-down" />
            <span class="margin-x-2">Grade Visibility</span>
          </a>
        </div>
        <div v-if="toggleGradeVisibility" id="#grades-container" class="padding-x-4 padding-x-5-tablet" style="margin-bottom: 30px;">
          <h1 style="font-size: 18px; margin-bottom: 30px; font-weight: bold">
            All Grades are visible for the selected Connection/Size/Weight unless otherwise indicated:
          </h1>
          <div class="columns">
            <div v-for="(column, index) in iconGradesObject" :key="index" class="column">
              <div v-for="g in column" :key="g.id" style="border-bottom: solid 1px #eee; padding: 10px 0;">
                <div class="columns is-marginless">
                  <a v-if="g.visible" @click="changeGradeVisibleValue(g.id)">
                    <b-icon :ref="g.id + 'eye'" icon="eye" size="is-small" />
                  </a>
                  <a v-else @click="changeGradeVisibleValue(g.id)">
                    <span>
                      <b-icon :ref="g.id + 'eye-slash'" icon="eye-slash" size="is-small" />
                    </span>
                  </a>
                  <span style="margin-left: 10px">
                    {{ g.name }}
                    <div v-if="g.visible">
                      <b-checkbox size="is-small" :value="hasSpDrift(g.id)" @input="toggleSpDrift(g.id)">
                        Special Drift
                      </b-checkbox>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left">
          <b-dropdown :mobile-modal="false" position="is-top-right">
            <b-button
              slot="trigger"
              rounded
              type="is-dark"
              icon-right="ellipsis-h"
              class="icon-only" />
            <b-dropdown-item @click="deleteWeight()">
              Delete Weight
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="close">
            Cancel
          </button>
          <button class="button is-success" :disabled="!canSaveWeight" @click="save">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import AdminApi from '@/apis/AdminApi'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  props: {
    size: {
      required: true,
      type: Object
    },
    weight: {
      required: true,
      type: Object
    },
    hiddenGrades: {
      required: true,
      type: Array
    },
    showSpDrifts: {
      required: true,
      type: Array
    }
  },
  data () {
    const sizeData = [
      { field: 'weight', label: 'Weight', value: this.weight.weight, metric: 'lb' },
      { field: 'pipeBodyWall', label: 'Pipe Body Wall', value: this.weight.pipeBodyWall, metric: 'in' },
      { field: 'couplingId', label: 'Coupling ID', value: this.weight.couplingId, metric: 'in' },
      { field: 'couplingLength', label: 'Coupling Length', value: this.weight.couplingLength, metric: 'in' },
      { field: 'makeupLoss', label: 'Make-up Loss', value: this.weight.makeupLoss, metric: 'in' },
      { field: 'spDriftDiameter', label: 'Sp. Drift Diameter', value: this.weight.spDriftDiameter, metric: 'in' },
      { field: 'couplingEntryId', label: 'Coupling Entry ID', value: this.weight.couplingEntryId, metric: null },
      { field: 'criticalDiameter', label: 'Critical Diameter', value: this.weight.criticalDiameter, metric: null },
      { field: 'taper', label: 'Taper', value: this.weight.taper, metric: null },
      { field: 'sd', label: 'SD', value: this.weight.sd, metric: null },
      { field: 'trueTe', label: 'True TE', value: this.weight.trueTe, metric: null },
      { field: 'compressionRating', label: 'Compression Rating', value: this.weight.compressionRating, metric: '%' },
      { field: 'bendingModulus', label: 'Bending w/ Sealability', value: this.weight.bendingModulus, metric: '°' }
    ]
    const torqueDataCarbon = [
      { size: '55', ftMinApi: this.weight.apiCarbonKsi55FtMin, ftMaxApi: this.weight.apiCarbonKsi55FtMax, ftOptApi: this.weight.apiCarbonKsi55FtOpt, stMinApi: this.weight.apiCarbonKsi55StMin, stMaxApi: this.weight.apiCarbonKsi55StMax, mtsApi: this.weight.apiCarbonKsi55Mts, ftMinClr: this.weight.clrCarbonKsi55FtMin, ftMaxClr: this.weight.clrCarbonKsi55FtMax, ftOptClr: this.weight.clrCarbonKsi55FtOpt, stMinClr: this.weight.clrCarbonKsi55StMin, stMaxClr: this.weight.clrCarbonKsi55StMax, mtsClr: this.weight.clrCarbonKsi55Mts },
      { size: '75', ftMinApi: this.weight.apiCarbonKsi75FtMin, ftMaxApi: this.weight.apiCarbonKsi75FtMax, ftOptApi: this.weight.apiCarbonKsi75FtOpt, stMinApi: this.weight.apiCarbonKsi75StMin, stMaxApi: this.weight.apiCarbonKsi75StMax, mtsApi: this.weight.apiCarbonKsi75Mts, ftMinClr: this.weight.clrCarbonKsi75FtMin, ftMaxClr: this.weight.clrCarbonKsi75FtMax, ftOptClr: this.weight.clrCarbonKsi75FtOpt, stMinClr: this.weight.clrCarbonKsi75StMin, stMaxClr: this.weight.clrCarbonKsi75StMax, mtsClr: this.weight.clrCarbonKsi75Mts },
      { size: '80', ftMinApi: this.weight.apiCarbonKsi80FtMin, ftMaxApi: this.weight.apiCarbonKsi80FtMax, ftOptApi: this.weight.apiCarbonKsi80FtOpt, stMinApi: this.weight.apiCarbonKsi80StMin, stMaxApi: this.weight.apiCarbonKsi80StMax, mtsApi: this.weight.apiCarbonKsi80Mts, ftMinClr: this.weight.clrCarbonKsi80FtMin, ftMaxClr: this.weight.clrCarbonKsi80FtMax, ftOptClr: this.weight.clrCarbonKsi80FtOpt, stMinClr: this.weight.clrCarbonKsi80StMin, stMaxClr: this.weight.clrCarbonKsi80StMax, mtsClr: this.weight.clrCarbonKsi80Mts },
      { size: '85', ftMinApi: this.weight.apiCarbonKsi85FtMin, ftMaxApi: this.weight.apiCarbonKsi85FtMax, ftOptApi: this.weight.apiCarbonKsi85FtOpt, stMinApi: this.weight.apiCarbonKsi85StMin, stMaxApi: this.weight.apiCarbonKsi85StMax, mtsApi: this.weight.apiCarbonKsi85Mts, ftMinClr: this.weight.clrCarbonKsi85FtMin, ftMaxClr: this.weight.clrCarbonKsi85FtMax, ftOptClr: this.weight.clrCarbonKsi85FtOpt, stMinClr: this.weight.clrCarbonKsi85StMin, stMaxClr: this.weight.clrCarbonKsi85StMax, mtsClr: this.weight.clrCarbonKsi85Mts },
      { size: '90', ftMinApi: this.weight.apiCarbonKsi90FtMin, ftMaxApi: this.weight.apiCarbonKsi90FtMax, ftOptApi: this.weight.apiCarbonKsi90FtOpt, stMinApi: this.weight.apiCarbonKsi90StMin, stMaxApi: this.weight.apiCarbonKsi90StMax, mtsApi: this.weight.apiCarbonKsi90Mts, ftMinClr: this.weight.clrCarbonKsi90FtMin, ftMaxClr: this.weight.clrCarbonKsi90FtMax, ftOptClr: this.weight.clrCarbonKsi90FtOpt, stMinClr: this.weight.clrCarbonKsi90StMin, stMaxClr: this.weight.clrCarbonKsi90StMax, mtsClr: this.weight.clrCarbonKsi90Mts },
      { size: '95', ftMinApi: this.weight.apiCarbonKsi95FtMin, ftMaxApi: this.weight.apiCarbonKsi95FtMax, ftOptApi: this.weight.apiCarbonKsi95FtOpt, stMinApi: this.weight.apiCarbonKsi95StMin, stMaxApi: this.weight.apiCarbonKsi95StMax, mtsApi: this.weight.apiCarbonKsi95Mts, ftMinClr: this.weight.clrCarbonKsi95FtMin, ftMaxClr: this.weight.clrCarbonKsi95FtMax, ftOptClr: this.weight.clrCarbonKsi95FtOpt, stMinClr: this.weight.clrCarbonKsi95StMin, stMaxClr: this.weight.clrCarbonKsi95StMax, mtsClr: this.weight.clrCarbonKsi95Mts },
      { size: '100', ftMinApi: this.weight.apiCarbonKsi100FtMin, ftMaxApi: this.weight.apiCarbonKsi100FtMax, ftOptApi: this.weight.apiCarbonKsi100FtOpt, stMinApi: this.weight.apiCarbonKsi100StMin, stMaxApi: this.weight.apiCarbonKsi100StMax, mtsApi: this.weight.apiCarbonKsi100Mts, ftMinClr: this.weight.clrCarbonKsi100FtMin, ftMaxClr: this.weight.clrCarbonKsi100FtMax, ftOptClr: this.weight.clrCarbonKsi100FtOpt, stMinClr: this.weight.clrCarbonKsi100StMin, stMaxClr: this.weight.clrCarbonKsi100StMax, mtsClr: this.weight.clrCarbonKsi100Mts },
      { size: '105', ftMinApi: this.weight.apiCarbonKsi105FtMin, ftMaxApi: this.weight.apiCarbonKsi105FtMax, ftOptApi: this.weight.apiCarbonKsi105FtOpt, stMinApi: this.weight.apiCarbonKsi105StMin, stMaxApi: this.weight.apiCarbonKsi105StMax, mtsApi: this.weight.apiCarbonKsi105Mts, ftMinClr: this.weight.clrCarbonKsi105FtMin, ftMaxClr: this.weight.clrCarbonKsi105FtMax, ftOptClr: this.weight.clrCarbonKsi105FtOpt, stMinClr: this.weight.clrCarbonKsi105StMin, stMaxClr: this.weight.clrCarbonKsi105StMax, mtsClr: this.weight.clrCarbonKsi105Mts },
      { size: '110', ftMinApi: this.weight.apiCarbonKsi110FtMin, ftMaxApi: this.weight.apiCarbonKsi110FtMax, ftOptApi: this.weight.apiCarbonKsi110FtOpt, stMinApi: this.weight.apiCarbonKsi110StMin, stMaxApi: this.weight.apiCarbonKsi110StMax, mtsApi: this.weight.apiCarbonKsi110Mts, ftMinClr: this.weight.clrCarbonKsi110FtMin, ftMaxClr: this.weight.clrCarbonKsi110FtMax, ftOptClr: this.weight.clrCarbonKsi110FtOpt, stMinClr: this.weight.clrCarbonKsi110StMin, stMaxClr: this.weight.clrCarbonKsi110StMax, mtsClr: this.weight.clrCarbonKsi110Mts },
      { size: '125', ftMinApi: this.weight.apiCarbonKsi125FtMin, ftMaxApi: this.weight.apiCarbonKsi125FtMax, ftOptApi: this.weight.apiCarbonKsi125FtOpt, stMinApi: this.weight.apiCarbonKsi125StMin, stMaxApi: this.weight.apiCarbonKsi125StMax, mtsApi: this.weight.apiCarbonKsi125Mts, ftMinClr: this.weight.clrCarbonKsi125FtMin, ftMaxClr: this.weight.clrCarbonKsi125FtMax, ftOptClr: this.weight.clrCarbonKsi125FtOpt, stMinClr: this.weight.clrCarbonKsi125StMin, stMaxClr: this.weight.clrCarbonKsi125StMax, mtsClr: this.weight.clrCarbonKsi125Mts },
      { size: '140', ftMinApi: this.weight.apiCarbonKsi140FtMin, ftMaxApi: this.weight.apiCarbonKsi140FtMax, ftOptApi: this.weight.apiCarbonKsi140FtOpt, stMinApi: this.weight.apiCarbonKsi140StMin, stMaxApi: this.weight.apiCarbonKsi140StMax, mtsApi: this.weight.apiCarbonKsi140Mts, ftMinClr: this.weight.clrCarbonKsi140FtMin, ftMaxClr: this.weight.clrCarbonKsi140FtMax, ftOptClr: this.weight.clrCarbonKsi140FtOpt, stMinClr: this.weight.clrCarbonKsi140StMin, stMaxClr: this.weight.clrCarbonKsi140StMax, mtsClr: this.weight.clrCarbonKsi140Mts }
    ]

    const torqueDataChrome = [
      { size: '75', ftMinApiChrome: this.weight.apiChromeKsi75FtMin, ftMaxApiChrome: this.weight.apiChromeKsi75FtMax, ftOptApiChrome: this.weight.apiChromeKsi75FtOpt, stMinApiChrome: this.weight.apiChromeKsi75StMin, stMaxApiChrome: this.weight.apiChromeKsi75StMax, mtsApiChrome: this.weight.apiChromeKsi75Mts, ftMinClrChrome: this.weight.clrChromeKsi75FtMin, ftMaxClrChrome: this.weight.clrChromeKsi75FtMax, ftOptClrChrome: this.weight.clrChromeKsi75FtOpt, stMinClrChrome: this.weight.clrChromeKsi75StMin, stMaxClrChrome: this.weight.clrChromeKsi75StMax, mtsClrChrome: this.weight.clrChromeKsi75Mts },
      { size: '80', ftMinApiChrome: this.weight.apiChromeKsi80FtMin, ftMaxApiChrome: this.weight.apiChromeKsi80FtMax, ftOptApiChrome: this.weight.apiChromeKsi80FtOpt, stMinApiChrome: this.weight.apiChromeKsi80StMin, stMaxApiChrome: this.weight.apiChromeKsi80StMax, mtsApiChrome: this.weight.apiChromeKsi80Mts, ftMinClrChrome: this.weight.clrChromeKsi80FtMin, ftMaxClrChrome: this.weight.clrChromeKsi80FtMax, ftOptClrChrome: this.weight.clrChromeKsi80FtOpt, stMinClrChrome: this.weight.clrChromeKsi80StMin, stMaxClrChrome: this.weight.clrChromeKsi80StMax, mtsClrChrome: this.weight.clrChromeKsi80Mts },
      { size: '85', ftMinApiChrome: this.weight.apiChromeKsi85FtMin, ftMaxApiChrome: this.weight.apiChromeKsi85FtMax, ftOptApiChrome: this.weight.apiChromeKsi85FtOpt, stMinApiChrome: this.weight.apiChromeKsi85StMin, stMaxApiChrome: this.weight.apiChromeKsi85StMax, mtsApiChrome: this.weight.apiChromeKsi85Mts, ftMinClrChrome: this.weight.clrChromeKsi85FtMin, ftMaxClrChrome: this.weight.clrChromeKsi85FtMax, ftOptClrChrome: this.weight.clrChromeKsi85FtOpt, stMinClrChrome: this.weight.clrChromeKsi85StMin, stMaxClrChrome: this.weight.clrChromeKsi85StMax, mtsClrChrome: this.weight.clrChromeKsi85Mts },
      { size: '90', ftMinApiChrome: this.weight.apiChromeKsi90FtMin, ftMaxApiChrome: this.weight.apiChromeKsi90FtMax, ftOptApiChrome: this.weight.apiChromeKsi90FtOpt, stMinApiChrome: this.weight.apiChromeKsi90StMin, stMaxApiChrome: this.weight.apiChromeKsi90StMax, mtsApiChrome: this.weight.apiChromeKsi90Mts, ftMinClrChrome: this.weight.clrChromeKsi90FtMin, ftMaxClrChrome: this.weight.clrChromeKsi90FtMax, ftOptClrChrome: this.weight.clrChromeKsi90FtOpt, stMinClrChrome: this.weight.clrChromeKsi90StMin, stMaxClrChrome: this.weight.clrChromeKsi90StMax, mtsClrChrome: this.weight.clrChromeKsi90Mts },
      { size: '95', ftMinApiChrome: this.weight.apiChromeKsi95FtMin, ftMaxApiChrome: this.weight.apiChromeKsi95FtMax, ftOptApiChrome: this.weight.apiChromeKsi95FtOpt, stMinApiChrome: this.weight.apiChromeKsi95StMin, stMaxApiChrome: this.weight.apiChromeKsi95StMax, mtsApiChrome: this.weight.apiChromeKsi95Mts, ftMinClrChrome: this.weight.clrChromeKsi95FtMin, ftMaxClrChrome: this.weight.clrChromeKsi95FtMax, ftOptClrChrome: this.weight.clrChromeKsi95FtOpt, stMinClrChrome: this.weight.clrChromeKsi95StMin, stMaxClrChrome: this.weight.clrChromeKsi95StMax, mtsClrChrome: this.weight.clrChromeKsi95Mts },
      { size: '100', ftMinApiChrome: this.weight.apiChromeKsi100FtMin, ftMaxApiChrome: this.weight.apiChromeKsi100FtMax, ftOptApiChrome: this.weight.apiChromeKsi100FtOpt, stMinApiChrome: this.weight.apiChromeKsi100StMin, stMaxApiChrome: this.weight.apiChromeKsi100StMax, mtsApiChrome: this.weight.apiChromeKsi100Mts, ftMinClrChrome: this.weight.clrChromeKsi100FtMin, ftMaxClrChrome: this.weight.clrChromeKsi100FtMax, ftOptClrChrome: this.weight.clrChromeKsi100FtOpt, stMinClrChrome: this.weight.clrChromeKsi100StMin, stMaxClrChrome: this.weight.clrChromeKsi100StMax, mtsClrChrome: this.weight.clrChromeKsi100Mts },
      { size: '105', ftMinApiChrome: this.weight.apiChromeKsi105FtMin, ftMaxApiChrome: this.weight.apiChromeKsi105FtMax, ftOptApiChrome: this.weight.apiChromeKsi105FtOpt, stMinApiChrome: this.weight.apiChromeKsi105StMin, stMaxApiChrome: this.weight.apiChromeKsi105StMax, mtsApiChrome: this.weight.apiChromeKsi105Mts, ftMinClrChrome: this.weight.clrChromeKsi105FtMin, ftMaxClrChrome: this.weight.clrChromeKsi105FtMax, ftOptClrChrome: this.weight.clrChromeKsi105FtOpt, stMinClrChrome: this.weight.clrChromeKsi105StMin, stMaxClrChrome: this.weight.clrChromeKsi105StMax, mtsClrChrome: this.weight.clrChromeKsi105Mts },
      { size: '110', ftMinApiChrome: this.weight.apiChromeKsi110FtMin, ftMaxApiChrome: this.weight.apiChromeKsi110FtMax, ftOptApiChrome: this.weight.apiChromeKsi110FtOpt, stMinApiChrome: this.weight.apiChromeKsi110StMin, stMaxApiChrome: this.weight.apiChromeKsi110StMax, mtsApiChrome: this.weight.apiChromeKsi110Mts, ftMinClrChrome: this.weight.clrChromeKsi110FtMin, ftMaxClrChrome: this.weight.clrChromeKsi110FtMax, ftOptClrChrome: this.weight.clrChromeKsi110FtOpt, stMinClrChrome: this.weight.clrChromeKsi110StMin, stMaxClrChrome: this.weight.clrChromeKsi110StMax, mtsClrChrome: this.weight.clrChromeKsi110Mts },
      { size: '125', ftMinApiChrome: this.weight.apiChromeKsi125FtMin, ftMaxApiChrome: this.weight.apiChromeKsi125FtMax, ftOptApiChrome: this.weight.apiChromeKsi125FtOpt, stMinApiChrome: this.weight.apiChromeKsi125StMin, stMaxApiChrome: this.weight.apiChromeKsi125StMax, mtsApiChrome: this.weight.apiChromeKsi125Mts, ftMinClrChrome: this.weight.clrChromeKsi125FtMin, ftMaxClrChrome: this.weight.clrChromeKsi125FtMax, ftOptClrChrome: this.weight.clrChromeKsi125FtOpt, stMinClrChrome: this.weight.clrChromeKsi125StMin, stMaxClrChrome: this.weight.clrChromeKsi125StMax, mtsClrChrome: this.weight.clrChromeKsi125Mts },
      { size: '140', ftMinApiChrome: this.weight.apiChromeKsi140FtMin, ftMaxApiChrome: this.weight.apiChromeKsi140FtMax, ftOptApiChrome: this.weight.apiChromeKsi140FtOpt, stMinApiChrome: this.weight.apiChromeKsi140StMin, stMaxApiChrome: this.weight.apiChromeKsi140StMax, mtsApiChrome: this.weight.apiChromeKsi140Mts, ftMinClrChrome: this.weight.clrChromeKsi140FtMin, ftMaxClrChrome: this.weight.clrChromeKsi140FtMax, ftOptClrChrome: this.weight.clrChromeKsi140FtOpt, stMinClrChrome: this.weight.clrChromeKsi140StMin, stMaxClrChrome: this.weight.clrChromeKsi140StMax, mtsClrChrome: this.weight.clrChromeKsi140Mts }
    ]

    // const couplingData = [
    //   { 'size': 'Std', 'cplgEnabled': this.weight.cplgstdcEnabled, 'cplgInsEnabled': this.weight.cplgstdcInsEnabled, 'cplgBlnkEnabled': this.weight.cplgstdcBlnkEnabled, 'cplgOd': this.weight.dimTurnDiameterBoxStdCplgstdcOd, 'cplgTe': this.weight.cplgstdcTe, 'cplgJte': this.weight.cplgstdcJte, 'cplgBfOd45': this.weight.cplgstdcBfOd45, 'cplgBfOd20': this.weight.cplgstdcBfOd20, 'cplgBfAr45': this.weight.cplgstdcBfAr45, 'cplgBfAr20': this.weight.cplgstdcBfAr20 },
    //   { 'size': '101', 'cplgEnabled': this.weight.cplg101cEnabled, 'cplgInsEnabled': this.weight.cplg101cInsEnabled, 'cplgBlnkEnabled': this.weight.cplg101cBlnkEnabled, 'cplgOd': this.weight.dimTurnDiameterBox101Cplg101cOd, 'cplgTe': this.weight.cplg101cTe, 'cplgJte': this.weight.cplg101cJte, 'cplgBfOd45': this.weight.cplg101cBfOd45, 'cplgBfOd20': this.weight.cplg101cBfOd20, 'cplgBfAr45': this.weight.cplg101cBfAr45, 'cplgBfAr20': this.weight.cplg101cBfAr20 },
    //   { 'size': 'Sc90', 'cplgEnabled': this.weight.cplgsc90cEnabled, 'cplgInsEnabled': this.weight.cplgsc90cInsEnabled, 'cplgBlnkEnabled': this.weight.cplgsc90cBlnkEnabled, 'cplgOd': this.weight.dimTurnDiameterBoxSc90Cplgsc90cOd, 'cplgTe': this.weight.cplgsc90cTe, 'cplgJte': this.weight.cplgsc90cJte, 'cplgBfOd45': this.weight.cplgsc90cBfOd45, 'cplgBfOd20': this.weight.cplgsc90cBfOd20, 'cplgBfAr45': this.weight.cplgsc90cBfAr45, 'cplgBfAr20': this.weight.cplgsc90cBfAr20 },
    //   { 'size': 'Sc80', 'cplgEnabled': this.weight.cplgsc80cEnabled, 'cplgInsEnabled': this.weight.cplgsc80cInsEnabled, 'cplgBlnkEnabled': this.weight.cplgsc80cBlnkEnabled, 'cplgOd': this.weight.dimTurnDiameterBoxSc80Cplgsc80cOd, 'cplgTe': this.weight.cplgsc80cTe, 'cplgJte': this.weight.cplgsc80cJte, 'cplgBfOd45': this.weight.cplgsc80cBfOd45, 'cplgBfOd20': this.weight.cplgsc80cBfOd20, 'cplgBfAr45': this.weight.cplgsc80cBfAr45, 'cplgBfAr20': this.weight.cplgsc80cBfAr20 },
    //   { 'size': 'Sc70', 'cplgEnabled': this.weight.cplgsc70cEnabled, 'cplgInsEnabled': this.weight.cplgsc70cInsEnabled, 'cplgBlnkEnabled': this.weight.cplgsc70cBlnkEnabled, 'cplgOd': this.weight.dimTurnDiameterBoxSc70Cplgsc70cOd, 'cplgTe': this.weight.cplgsc70cTe, 'cplgJte': this.weight.cplgsc70cJte, 'cplgBfOd45': this.weight.cplgsc70cBfOd45, 'cplgBfOd20': this.weight.cplgsc70cBfOd20, 'cplgBfAr45': this.weight.cplgsc70cBfAr45, 'cplgBfAr20': this.weight.cplgsc70cBfAr20 }
    // ]

    const inspectionFields = [
      { field: 'inspMaxTro', label: 'Max TRO', value: this.weight.inspMaxTro, metric: 'in' },
      { field: 'inspMinPtl', label: 'Min PTL', value: this.weight.inspMinPtl, metric: 'in' },
      { field: 'inspCoupOdLt', label: null, value: this.weight.inspCoupOdLt, metric: 'in' },
      { field: 'inspCoupOdUt', label: null, value: this.weight.inspCoupOdUt, metric: 'in' },
      { field: 'inspShoulDepUt', label: 'Shoulder Depth UT', value: this.weight.inspShoulDepUt, metric: 'in' },
      { field: 'inspShoulDepNom', label: 'Shoulder Depth', value: this.weight.inspShoulDepNom, metric: 'in' },
      { field: 'inspShoulDepLt', label: 'Shoulder Depth LT', value: this.weight.inspShoulDepLt, metric: 'in' },
      { field: 'inspBoxBoreIdUt', label: 'Box Bore ID UT', value: this.weight.inspBoxBoreIdUt, metric: 'in' },
      { field: 'inspBoxBoreIdNom', label: 'Box Bore ID', value: this.weight.inspBoxBoreIdNom, metric: 'in' },
      { field: 'inspBoxBoreIdLt', label: 'Box Bore ID LT', value: this.weight.inspBoxBoreIdLt, metric: 'in' },
      { field: 'inspPinBoreIdUt', label: 'Pin Bore ID UT', value: this.weight.inspPinBoreIdUt, metric: 'in' },
      { field: 'inspPinBoreIdNom', label: 'Pin Bore ID', value: this.weight.inspPinBoreIdNom, metric: 'in' },
      { field: 'inspPinBoreIdLt', label: 'Pin Bore ID LT', value: this.weight.inspPinBoreIdLt, metric: 'in' },
      { field: 'inspPinRuninRunoutUt', label: 'Run-in/Run-out UT', value: this.weight.inspPinRuninRunoutUt, metric: 'in' },
      { field: 'inspPinRuninRunoutNom', label: 'Run-in/Run-out', value: this.weight.inspPinRuninRunoutNom, metric: 'in' },
      { field: 'inspPinRuninRunoutLt', label: 'Run-in/Run-out LT', value: this.weight.inspPinRuninRunoutLt, metric: 'in' },

      { field: 'inspPinMinLfc', label: 'Min LFC', value: this.weight.inspPinMinLfc, metric: 'in' },
      { field: 'inspPinThreadDiaNom', label: 'Thread Diameter', value: this.weight.inspPinThreadDiaNom, metric: 'in' },
      { field: 'inspPinThreadDiaLt', label: null, value: this.weight.inspPinThreadDiaLt, metric: 'in' },
      { field: 'inspPinThreadDiaUt', label: null, value: this.weight.inspPinThreadDiaUt, metric: 'in' },
      { field: 'inspPinSealDiaNom', label: 'Seal Diameter', value: this.weight.inspPinSealDiaNom, metric: 'in' },
      { field: 'inspPinSealDiaLt', label: null, value: this.weight.inspPinSealDiaLt, metric: 'in' },
      { field: 'inspPinSealDiaUt', label: null, value: this.weight.inspPinSealDiaUt, metric: 'in' },
      { field: 'inspPinThreadHtNom', label: 'Thread Height', value: this.weight.inspPinThreadHtNom, metric: 'in' },
      { field: 'inspPinThreadHtLt', label: null, value: this.weight.inspPinThreadHtLt, metric: 'in' },
      { field: 'inspPinThreadHtUt', label: null, value: this.weight.inspPinThreadHtUt, metric: 'in' },
      { field: 'inspPinNoseOdNom', label: 'Nose OD', value: this.weight.inspPinNoseOdNom, metric: 'in' },
      { field: 'inspPinNoseOdLt', label: null, value: this.weight.inspPinNoseOdLt, metric: 'in' },
      { field: 'inspPinNoseOdUt', label: null, value: this.weight.inspPinNoseOdUt, metric: 'in' },
      { field: 'inspPinNoseLenNom', label: 'Nose Length', value: this.weight.inspPinNoseLenNom, metric: 'in' },
      { field: 'inspPinNoseLenLt', label: null, value: this.weight.inspPinNoseLenLt, metric: 'in' },
      { field: 'inspPinNoseLenUt', label: null, value: this.weight.inspPinNoseLenUt, metric: 'in' },
      { field: 'inspPinBoreLenNom', label: 'Bore Length', value: this.weight.inspPinBoreLenNom, metric: 'in' },
      { field: 'inspPinBoreLenLt', label: null, value: this.weight.inspPinBoreLenLt, metric: 'in' },
      { field: 'inspPinBoreLenUt', label: null, value: this.weight.inspPinBoreLenUt, metric: 'in' },
      { field: 'inspPinCplt', label: 'CPLT', value: this.weight.inspPinCplt, metric: 'in' },
      { field: 'inspPinTrpd', label: 'TRPD', value: this.weight.inspPinTrpd, metric: 'in' },
      { field: 'inspPinTpi', label: 'TPI', value: this.weight.inspPinTpi, metric: 'in' },
      { field: 'inspPinThreadDiaOvality', label: 'Thread Dia Ovality', value: this.weight.inspPinThreadDiaOvality, metric: 'in' },
      { field: 'inspPinSealDiaOvality', label: 'Seal Dia Ovality', value: this.weight.inspPinSealDiaOvality, metric: 'in' },
      { field: 'inspPinLeadNom', label: 'Lead', value: this.weight.inspPinLeadNom, metric: 'in' },
      { field: 'inspPinLeadUt', label: null, value: this.weight.inspPinLeadUt, metric: 'in' },
      { field: 'inspPinLeadLt', label: null, value: this.weight.inspPinLeadLt, metric: 'in' },
      { field: 'inspPinCumLeadNom', label: 'Cumulative Lead', value: this.weight.inspPinCumLeadNom, metric: 'in' },
      { field: 'inspPinCumLeadUt', label: null, value: this.weight.inspPinCumLeadUt, metric: 'in' },
      { field: 'inspPinCumLeadLt', label: null, value: this.weight.inspPinCumLeadLt, metric: 'in' },
      { field: 'inspPinTaperPNom', label: 'Perfect Taper', value: this.weight.inspPinTaperPNom, metric: 'in' },
      { field: 'inspPinTaperPUt', label: null, value: this.weight.inspPinTaperPUt, metric: 'in' },
      { field: 'inspPinTaperPLt', label: null, value: this.weight.inspPinTaperPLt, metric: 'in' },
      { field: 'inspPinTaperINom', label: 'Imperfect Taper', value: this.weight.inspPinTaperINom, metric: 'in' },
      { field: 'inspPinTaperIUt', label: null, value: this.weight.inspPinTaperIUt, metric: 'in' },
      { field: 'inspPinTaperILt', label: null, value: this.weight.inspPinTaperILt, metric: 'in' },
      { field: 'inspPinDwgName', label: 'Drawing Name', value: this.weight.inspPinDwgName, metric: null },
      { field: 'inspPinDwgRev', label: 'Revision', value: this.weight.inspPinDwgRev, metric: 'rev' },
      { field: 'inspPinFooter1', label: 'Footer Pin', value: this.weight.inspPinFooter1, metric: 'HTML' },

      { field: 'inspBoxMinPtl', label: 'inspBoxMinPtl', value: this.weight.inspBoxMinPtl, metric: 'in' },
      { field: 'inspBoxShoulDepNom', label: 'inspBoxShoulDepNom', value: this.weight.inspBoxShoulDepNom, metric: 'in' },
      { field: 'inspBoxThreadDiaNom', label: 'Thread Diameter', value: this.weight.inspBoxThreadDiaNom, metric: 'in' },
      { field: 'inspBoxThreadDiaLt', label: 'inspBoxThreadDiaLt', value: this.weight.inspBoxThreadDiaLt, metric: 'in' },
      { field: 'inspBoxThreadDiaUt', label: 'inspBoxThreadDiaUt', value: this.weight.inspBoxThreadDiaUt, metric: 'in' },
      { field: 'inspBoxSealDiaNom', label: 'Seal Diameter', value: this.weight.inspBoxSealDiaNom, metric: 'in' },
      { field: 'inspBoxSealDiaLt', label: 'inspBoxSealDiaLt', value: this.weight.inspBoxSealDiaLt, metric: 'in' },
      { field: 'inspBoxSealDiaUt', label: 'inspBoxSealDiaUt', value: this.weight.inspBoxSealDiaUt, metric: 'in' },
      { field: 'inspBoxThreadHtNom', label: 'Thread Height', value: this.weight.inspBoxThreadHtNom, metric: 'in' },
      { field: 'inspBoxThreadHtLt', label: null, value: this.weight.inspBoxThreadHtLt, metric: 'in' },
      { field: 'inspBoxThreadHtUt', label: null, value: this.weight.inspBoxThreadHtUt, metric: 'in' },
      { field: 'inspBoxNoseIdNom', label: 'Nose I.D.', value: this.weight.inspBoxNoseIdNom, metric: 'in' },
      { field: 'inspBoxNoseIdLt', label: 'inspBoxNoseIdLt', value: this.weight.inspBoxNoseIdLt, metric: 'in' },
      { field: 'inspBoxNoseIdUt', label: 'inspBoxNoseIdUt', value: this.weight.inspBoxNoseIdUt, metric: 'in' },
      { field: 'inspBoxCoupLenNom', label: 'Coup. Length', value: this.weight.inspBoxCoupLenNom, metric: 'in' },
      { field: 'inspBoxCoupLenLt', label: 'inspBoxCoupLenLt', value: this.weight.inspBoxCoupLenLt, metric: 'in' },
      { field: 'inspBoxCoupLenUt', label: 'inspBoxCoupLenUt', value: this.weight.inspBoxCoupLenUt, metric: 'in' },
      { field: 'inspBoxCpSealDia', label: 'Seal Contact Points', value: this.weight.inspBoxCpSealDia, metric: 'in' },
      { field: 'inspBoxCpLeadTaper', label: 'Lead Contact Points', value: this.weight.inspBoxCpLeadTaper, metric: 'in' },
      { field: 'inspBoxShoulDepCp', label: 'inspBoxShoulDepCp', value: this.weight.inspBoxShoulDepCp, metric: 'in' },
      { field: 'inspBoxTrpd', label: 'TRPD', value: this.weight.inspBoxTrpd, metric: 'in' },
      { field: 'inspBoxThreadDiaOvality', label: 'Thread Dia Ovality', value: this.weight.inspBoxThreadDiaOvality, metric: 'in' },
      { field: 'inspBoxSealDiaOvality', label: 'Seal Dia Ovality', value: this.weight.inspBoxSealDiaOvality, metric: 'in' },
      { field: 'inspBoxShoesLength', label: 'Shoes Length', value: this.weight.inspBoxShoesLength, metric: 'in' },
      { field: 'inspBoxLeadNom', label: 'Lead', value: this.weight.inspBoxLeadNom, metric: 'in' },
      { field: 'inspBoxLeadUt', label: null, value: this.weight.inspBoxLeadUt, metric: 'in' },
      { field: 'inspBoxLeadLt', label: null, value: this.weight.inspBoxLeadLt, metric: 'in' },
      { field: 'inspBoxCumLeadNom', label: 'Cumulative Lead', value: this.weight.inspBoxCumLeadNom, metric: 'in' },
      { field: 'inspBoxCumLeadUt', label: null, value: this.weight.inspBoxCumLeadUt, metric: 'in' },
      { field: 'inspBoxCumLeadLt', label: null, value: this.weight.inspBoxCumLeadLt, metric: 'in' },
      { field: 'inspBoxTaperPNom', label: 'Perfect Taper', value: this.weight.inspBoxTaperPNom, metric: 'in' },
      { field: 'inspBoxTaperPUt', label: null, value: this.weight.inspBoxTaperPUt, metric: 'in' },
      { field: 'inspBoxTaperPLt', label: null, value: this.weight.inspBoxTaperPLt, metric: 'in' },
      { field: 'inspBoxTaperINom', label: 'Imperfect Taper', value: this.weight.inspBoxTaperINom, metric: 'in' },
      { field: 'inspBoxTaperIUt', label: null, value: this.weight.inspBoxTaperIUt, metric: 'in' },
      { field: 'inspBoxTaperILt', label: null, value: this.weight.inspBoxTaperILt, metric: 'in' },
      { field: 'inspBoxDwgName', label: 'Drawing Name', value: this.weight.inspBoxDwgName, metric: null },
      { field: 'inspBoxDwgRev', label: 'Revision', value: this.weight.inspBoxDwgRev, metric: 'rev' },
      { field: 'inspBoxFooter1', label: 'Footer Box', value: this.weight.inspBoxFooter1, metric: 'HTML' }

    ]

    // const blankDimensionsDataCoupling = [
    //   { 'field': 'dimTurnDiameterBoxStdCplgstdcOd', 'label': 'Nominal', 'value': this.weight.dimTurnDiameterBoxStdCplgstdcOd, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxStdMin', 'label': 'Lower Tol', 'value': this.weight.dimTurnDiameterBoxStdMin, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxStdMax', 'label': 'Upper Tol', 'value': this.weight.dimTurnDiameterBoxStdMax, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc70Cplgsc70cOd', 'label': 'Special Clearance 70.0%', 'value': this.weight.dimTurnDiameterBoxSc70Cplgsc70cOd, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc70Min', 'label': 'Lower Tol', 'value': this.weight.dimTurnDiameterBoxSc70Min, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc70Max', 'label': 'Upper Tol', 'value': this.weight.dimTurnDiameterBoxSc70Max, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc80Cplgsc80cOd', 'label': 'Special Clearance 80.0%', 'value': this.weight.dimTurnDiameterBoxSc80Cplgsc80cOd, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc80Min', 'label': 'Lower Tol', 'value': this.weight.dimTurnDiameterBoxSc80Min, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc80Max', 'label': 'Upper Tol', 'value': this.weight.dimTurnDiameterBoxSc80Max, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc90Cplgsc90cOd', 'label': 'Special Clearance 90.0%', 'value': this.weight.dimTurnDiameterBoxSc90Cplgsc90cOd, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc90Min', 'label': 'Lower Tol', 'value': this.weight.dimTurnDiameterBoxSc90Min, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBoxSc90Max', 'label': 'Upper Tol', 'value': this.weight.dimTurnDiameterBoxSc90Max, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBox101Cplg101cOd', 'label': '101%', 'value': this.weight.dimTurnDiameterBox101Cplg101cOd, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBox101Min', 'label': 'Lower Tol', 'value': this.weight.dimTurnDiameterBox101Min, 'metric': 'in' },
    //   { 'field': 'dimTurnDiameterBox101Max', 'label': 'Upper Tol', 'value': this.weight.dimTurnDiameterBox101Max, 'metric': 'in' }
    // ]

    const blankDimensionsData = [
      { field: 'threadRecutLen', label: 'R Value', value: this.weight.threadRecutLen, metric: 'in' },
      { field: 'threadRecutLenExtraPin', label: 'Pin Re-cut length', value: this.weight.threadRecutLenExtraPin, metric: 'in' },
      { field: 'threadRecutLenExtraBox', label: 'Box Re-cut length', value: this.weight.threadRecutLenExtraBox, metric: 'in' },
      { field: 'dimTurnLenPin', label: null, value: this.weight.dimTurnLenPin, metric: 'in' },
      { field: 'dimTurnLenPinMin', label: null, value: this.weight.dimTurnLenPinMin, metric: 'in' },
      { field: 'dimTurnLenPinMax', label: null, value: this.weight.dimTurnLenPinMax, metric: 'in' },
      { field: 'dimTurnLenBox', label: null, value: this.weight.dimTurnLenBox, metric: 'in' },
      { field: 'dimTurnLenBoxMin', label: null, value: this.weight.dimTurnLenBoxMin, metric: 'in' },
      { field: 'dimTurnLenBoxMax', label: null, value: this.weight.dimTurnLenBoxMax, metric: 'in' },
      { field: 'dimTurnDiameterPin', label: null, value: this.weight.dimTurnDiameterPin, metric: 'in' },
      { field: 'dimTurnDiameterPinMin', label: null, value: this.weight.dimTurnDiameterPinMin, metric: 'in' },
      { field: 'dimTurnDiameterPinMax', label: null, value: this.weight.dimTurnDiameterPinMax, metric: 'in' },
      { field: 'dimBorDiameterPin', label: null, value: this.weight.dimBorDiameterPin, metric: 'in' },
      { field: 'dimBorDiameterPinMin', label: null, value: this.weight.dimBorDiameterPinMin, metric: 'in' },
      { field: 'dimBorDiameterPinMax', label: null, value: this.weight.dimBorDiameterPinMax, metric: 'in' },
      { field: 'dimBorDiameterBox', label: null, value: this.weight.dimBorDiameterBox, metric: 'in' },
      { field: 'dimBorDiameterBoxMin', label: null, value: this.weight.dimBorDiameterBoxMin, metric: 'in' },
      { field: 'dimBorDiameterBoxMax', label: null, value: this.weight.dimBorDiameterBoxMax, metric: 'in' }
    ]
    return {
      validateOkay: false,
      sizeData,
      torqueDataCarbon,
      torqueDataChrome,
      couplingData: [],
      inspectionFields,
      blankDimensionsData,
      // blankDimensionsDataCoupling,
      toggleSpecs: false,
      toggleTorque: false,
      toggleCoupling: false,
      toggleInspection: false,
      toggleBlanking: false,
      toggleGradeVisibility: false,
      torqueAlloyTab: 0,
      torqueCarbonTab: 0,
      torqueChromeTab: 0,
      couplingTab: this.weight.couplings.length > 0 ? 0 : null,
      loaded: false,
      inspectionTabs: 0,
      gradeVector: [],
      iconGradesObject: [],
      duplicatedCoupling: false
    }
  },
  computed: {
    // gradesDisplayInThirds () {
    //   let temp = this.iconGradesObject
    //   let result = [];
    //   for (let i = 3; i > 0; i--) {
    //     result.push(temp.splice(0, Math.ceil(this.iconGradesObject.length / i)));
    //   }
    //   return result
    // },
    profile () {
      return this.$store.state.profile
    },
    gradesFiltered () {
      if (this.profile.hasJfeAdminPermission || this.profile.hasJfeDataManagerPermission) {
        return this.gradeVector
      } else {
        return this.gradeVector.filter(grade => grade.public)
      }
    },
    sizeDataWeightless () {
      return this.sizeData.filter(function (f) {
        return f.field !== 'weight'
      })
    },
    canSaveWeight: {
      cache: false,
      get () {
        if (!this.sizeData[0].value) {
          return false
        }
        if (this.duplicatedCoupling) {
          return false
        }
        const couplingEmpty = this.couplingData.find(c => !c.name)
        if (couplingEmpty) {
          return false
        }
        return true
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('refreshConnectionGrades')
    this.loadGrades()
    this.loadCoupling()

    this.$validator.extend('validateWeight', {
      getMessage: field => 'This weight already exists or cannot be empty',
      validate: (value) => {
        try {
          if (parseInt(String(value)) === parseInt(String(0))) {
            return false
          }
          if (String(value) !== String(this.weight.weight)) {
            var weightIndex = this.size.weights.find(w => String(w.weight) === String(value))

            if (!weightIndex) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } catch (err) {
          return true
        }
      }
    })

    this.$validator.extend('validateDuplicatedCoupling', {
      getMessage: field => 'This coupling name already exists or cannot be empty',
      validate: (newName) => {
        return !this.hasDuplicatedCouplings()
      }
    })

    this.loaded = true
  },
  methods: {
    hasDuplicatedCouplings () {
      this.duplicatedCoupling = false

      for (const coupling of this.couplingData) {
        const foundCoupling = this.couplingData.filter(c => c.name === coupling.name)
        if (foundCoupling.length > 1) {
          this.duplicatedCoupling = true
        }
      }

      return this.duplicatedCoupling
    },
    async save () {
      if (await this.$validator.validateAll()) {
        const loadingComponent = this.$buefy.loading.open({ container: null })

        for (const sizeR of this.sizeData) {
          this.weight[sizeR.field] = sizeR.value
        }

        for (const blankD of this.blankDimensionsData) {
          this.weight[blankD.field] = blankD.value
        }

        for (const field of this.inspectionFields) {
          this.weight[field.field] = field.value
        }

        for (const torque of this.torqueDataCarbon) {
          const size = String(torque.size)
            .replace(',', '')
            .replace(' ', '')
          this.weight['apiCarbonKsi' + size + 'FtMin'] = torque.ftMinApi
          this.weight['apiCarbonKsi' + size + 'FtMax'] = torque.ftMaxApi
          this.weight['apiCarbonKsi' + size + 'FtOpt'] = torque.ftOptApi
          this.weight['apiCarbonKsi' + size + 'StMin'] = torque.stMinApi
          this.weight['apiCarbonKsi' + size + 'StMax'] = torque.stMaxApi
          this.weight['apiCarbonKsi' + size + 'Mts'] = torque.mtsApi
          this.weight['clrCarbonKsi' + size + 'FtMin'] = torque.ftMinClr
          this.weight['clrCarbonKsi' + size + 'FtMax'] = torque.ftMaxClr
          this.weight['clrCarbonKsi' + size + 'FtOpt'] = torque.ftOptClr
          this.weight['clrCarbonKsi' + size + 'StMin'] = torque.stMinClr
          this.weight['clrCarbonKsi' + size + 'StMax'] = torque.stMaxClr
          this.weight['clrCarbonKsi' + size + 'Mts'] = torque.mtsClr
        }

        for (const torque of this.torqueDataChrome) {
          const size = String(torque.size)
            .replace(',', '')
            .replace(' ', '')
          this.weight['apiChromeKsi' + size + 'FtMin'] = torque.ftMinApiChrome
          this.weight['apiChromeKsi' + size + 'FtMax'] = torque.ftMaxApiChrome
          this.weight['apiChromeKsi' + size + 'FtOpt'] = torque.ftOptApiChrome
          this.weight['apiChromeKsi' + size + 'StMin'] = torque.stMinApiChrome
          this.weight['apiChromeKsi' + size + 'StMax'] = torque.stMaxApiChrome
          this.weight['apiChromeKsi' + size + 'Mts'] = torque.mtsApiChrome
          this.weight['clrChromeKsi' + size + 'FtMin'] = torque.ftMinClrChrome
          this.weight['clrChromeKsi' + size + 'FtMax'] = torque.ftMaxClrChrome
          this.weight['clrChromeKsi' + size + 'FtOpt'] = torque.ftOptClrChrome
          this.weight['clrChromeKsi' + size + 'StMin'] = torque.stMinClrChrome
          this.weight['clrChromeKsi' + size + 'StMax'] = torque.stMaxClrChrome
          this.weight['clrChromeKsi' + size + 'Mts'] = torque.mtsClrChrome
        }

        this.weight.hiddenGrades = this.hiddenGrades.join(',')
        this.weight.showSpDrifts = this.showSpDrifts.join(',')
        this.weight.couplings = this.couplingData

        loadingComponent.close()

        this.$emit('change', this.sizeData, this.torqueDataCarbon, this.torqueDataChrome, this.couplingData, this.blankDimensionsData, this.inspectionFields)
        this.$parent.close()
      }
    },
    mmValue (value) {
      value = value * 25.4
      return parseFloat(value).toFixed(2) + 'mm'
    },
    toggleSpecsFunc () {
      if (this.toggleSpecs) {
        this.toggleSpecs = false
      } else {
        this.toggleSpecs = true
      }
      this.toggleTorque = false
      this.toggleCoupling = false
      this.toggleInspection = false
      this.toggleBlanking = false
      this.toggleGradeVisibility = false
    },
    toggleTorqueFunc () {
      this.toggleSpecs = false
      if (this.toggleTorque) {
        this.toggleTorque = false
      } else {
        this.toggleTorque = true
      }
      this.toggleCoupling = false
      this.toggleInspection = false
      this.toggleBlanking = false
      this.toggleGradeVisibility = false
    },
    toggleCouplingFunc () {
      this.toggleSpecs = false
      this.toggleTorque = false
      if (this.toggleCoupling) {
        this.toggleCoupling = false
      } else {
        this.toggleCoupling = true
      }
      this.toggleInspection = false
      this.toggleBlanking = false
      this.toggleGradeVisibility = false
    },
    toggleInspectionFunc () {
      this.toggleSpecs = false
      this.toggleTorque = false
      this.toggleCoupling = false
      if (this.toggleInspection) {
        this.toggleInspection = false
      } else {
        this.toggleInspection = true
      }
      this.toggleBlanking = false
      this.toggleGradeVisibility = false
    },
    toggleBlankingFunc () {
      this.toggleSpecs = false
      this.toggleTorque = false
      this.toggleCoupling = false
      this.toggleInspection = false
      if (this.toggleBlanking) {
        this.toggleBlanking = false
      } else {
        this.toggleBlanking = true
      }
      this.toggleGradeVisibility = false
    },
    toggleGradeVisibilityFunc () {
      // fill iconGradesObject data
      this.iconGradesObject = this.gradesFiltered.map(g => {
        if (this.hiddenGrades.includes(g.id)) {
          return ({
            id: g.id,
            name: g.name,
            visible: false
          })
        } else {
          return ({
            id: g.id,
            name: g.name,
            visible: true
          })
        }
      })

      // split the iconGradesObject arr into thirds for better display
      const result = []
      for (let i = 3; i > 0; i--) {
        result.push(this.iconGradesObject.splice(0, Math.ceil(this.iconGradesObject.length / i)))
      }
      this.iconGradesObject = result

      this.toggleSpecs = false
      this.toggleTorque = false
      this.toggleCoupling = false
      this.toggleInspection = false
      this.toggleBlanking = false
      if (this.toggleGradeVisibility) {
        this.toggleGradeVisibility = false
      } else {
        this.toggleGradeVisibility = true
      }
      // scroll down to it TODO
      // https://stackoverflow.com/questions/40730116/scroll-to-bottom-of-div-with-vue-js
      // const el = this.$el.querySelector('#grades-container')
      // console.log(el)
    },
    getBlankDimensionsDataField (field) {
      return this.blankDimensionsData.find(b => b.field === field)
    },
    // getBlankDimensionsDataCouplingField (field) {
    //   return this.blankDimensionsDataCoupling.find(b => b.field === field)
    // },
    getInspectionFields (field) {
      return this.inspectionFields.find(b => b.field === field)
    },
    deleteWeight (size) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this weight?',
        confirmText: 'Delete Weight',
        type: 'is-danger',
        onConfirm: () => {
          // size.deleted = true
          this.$emit('delete', this.sizeData)
          this.$parent.close()
          this.$buefy.toast.open('Weight Deleted')
        }
      })
    },
    close () {
      this.$parent.close()
    },
    async loadGrades () {
      // await this.$store.dispatch('refreshConnectionGrades')
      this.gradeVector = JSON.parse(JSON.stringify(this.$store.state.connectionGrades.filter(g => g.id !== 0 && g.public)))
    },
    changeTab () {
    },
    loadCoupling () {
      // Flag the weights with the delete var.
      for (const coupling of this.weight.couplings) {
        coupling.delete = false
        this.couplingData.push(coupling)
      }

      this.couplingData = this.orderCouplilngVetor(this.couplingData)
    },
    deleteCoupling (coupling) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this coupling?',
        confirmText: 'Delete coupling',
        type: 'is-danger',
        onConfirm: () => {
          if (coupling.id) {
            coupling.delete = true
          } else {
            var couplingIndex = this.couplingData.indexOf(coupling)
            if (couplingIndex !== -1) {
              this.couplingData.splice(couplingIndex, 1)
            }
          }
          this.hasDuplicatedCouplings()
          this.couplingTab--
        }
      })
    },
    addCoupling () {
      const foundEmptyNewCoulling = this.couplingData.find(c => c.id === null && (c.name === null || c.name === '' || c.name === 'New'))

      if (!foundEmptyNewCoulling) {
        const newCoupling = {
          id: null,
          weightId: this.weight.id,
          name: 'New',
          datasheetsEnabled: 1,
          inspectionSheetsEnabled: 1,
          blankingDimensionsEnabled: 1,
          te: null,
          jte: null,
          bfOd20: null,
          bfOd45: null,
          bfAr20: null,
          bfAr45: null,
          mbFw20: null,
          mbFw45: null,
          cplgOd: null,
          specialClearance: false
        }

        this.couplingData.splice(0, 0, newCoupling)
        this.couplingTab = 0
      } else {
        const index = this.couplingData.indexOf(foundEmptyNewCoulling)
        if (index !== -1) {
          this.couplingTab = index
        }
      }
    },
    changeGradeVisibleValue (id) {
      if (this.hiddenGrades.includes(id)) {
        // take it out of hiddenGrades
        this.hiddenGrades.splice(this.hiddenGrades.indexOf(id), 1)
        // change the iconGradesObject icon
        for (const column of this.iconGradesObject) {
          for (const [i, g] of column.entries()) {
            if (g.id === id) {
              column.splice(i, 1, {
                id: g.id,
                name: g.name,
                visible: true
              })
              break
            }
          }
        }
      } else {
        this.hiddenGrades.push(id)
        // Clear the Special Drift
        this.toggleSpDrift(id)
        this.hiddenGrades.sort((a, b) => a - b)
        // change the iconGradesObject icon
        for (const column of this.iconGradesObject) {
          for (const [i, g] of column.entries()) {
            if (g.id === id) {
              column.splice(i, 1, {
                id: g.id,
                name: g.name,
                visible: false
              })
              break
            }
          }
        }
      }
    },

    hasSpDrift (gradeId) {
      return !!(this.showSpDrifts.includes(gradeId))
    },

    toggleSpDrift (gradeId) {
      if (this.showSpDrifts.includes(gradeId)) {
        this.showSpDrifts.splice(this.showSpDrifts.indexOf(gradeId), 1)
      } else {
        this.showSpDrifts.push(gradeId)
      }
      this.showSpDrifts.sort((a, b) => a - b)
    }
  }
}

</script>
