<template>
  <div>
    <section id="login_page" class="hero is-fullheight has-text-centered">
      <div class="overlay" />
      <div style="position: relative; z-index: 5;">
        <div class="auth-wrapper">
          <div class="padding-y-6">
            <div class="logo padding-y-6">
              <img id="login_logo" style="width: 70px" src="@/assets/images/JFE-logo-stacked.png">
              <div class="has-text-grey" style="font-size: 18px;">
                T O O L S
              </div>
            </div>

            <div class="module_buttons columns is-vcentered is-multiline is-centered">
              <div
                v-if="profile && (profile.hasJfeRoyaltyTrackerPermission || profile.hasLicenseeRoyaltyTrackerPermission)"
                class="column is-narrow">
                <a class="button is-primary has-shadow-50" @click="royaltyApp">Royalty Tracker</a>
              </div>
              <div
                v-if="profile && (profile.hasJfeGaugeTrackerPermission || profile.hasLicenseeGaugeTrackerPermission)"
                class="column is-narrow">
                <a class="button is-primary has-shadow-50" @click="gaugeApp">Gauge Tracker</a>
              </div>
              <div class="column is-narrow">
                <a class="button is-primary has-shadow-50" @click="datasheetApp">Datasheet Generator</a>
              </div>
              <div class="column is-narrow">
                <a class="button is-primary has-shadow-50" @click="technicalApp">Technical Documents</a>
              </div>
              <div v-if="(profile.hasJfeInspectionSheetPermission || profile.hasLicenseeInspectionSheetPermission)" class="column is-narrow">
                <a class="button is-primary has-shadow-50" @click="inspectionSheetsApp">Inspection Sheets</a>
              </div>
              <div class="column is-narrow">
                <a class="button is-primary has-shadow-50" @click="blankingApp">Blanking Dimensions</a>
              </div>
            </div>
          </div>
          <div class="row auth-form padding-y-6">
            <div v-if="profile != 'guest'">
              <div class="align-center" style="justify-content: center">
                <div>
                  Welcome back, {{ profile.firstName }}
                </div>
                <AdminDropdown position="is-bottom-left" />
              </div>
            </div>
            <LoginComponent v-else />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoginComponent from '@/components/misc/Login'
import AdminDropdown from '@/components/navigation/AdminDropdown'

export default {
  name: 'LoginPage',
  components: {
    LoginComponent,
    AdminDropdown
  },

  data () {
    return {
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    }
  },

  mounted () {
    this.$store.commit('setApp', null)
    this.$store.commit('setTitle', 'Dashboard')
  },
  methods: {
    royaltyApp () {
      this.$store.commit('setApp', 'royalty')
      this.$router.push({ name: 'royalty_tracker_projects' })
    },
    gaugeApp () {
      this.$store.commit('setApp', 'gauge')
      this.$router.push({ name: 'gauge_tracker' })
    },
    datasheetApp () {
      this.$store.commit('setApp', 'datasheet')
      this.$router.push({ name: 'datasheet_generator' })
    },
    technicalApp () {
      this.$store.commit('setApp', 'technical')
      this.$router.push({ name: 'technical_documents' })
    },
    inspectionSheetsApp () {
      this.$store.commit('setApp', 'inspection')
      this.$router.push({ name: 'inspection_sheets' })
    },
    blankingApp () {
      this.$store.commit('setApp', 'blanking')
      this.$router.push({ name: 'blanking_dimensions' })
    }
  }
}
</script>
