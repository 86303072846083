<template>
  <div>
    <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false">
      <div style="flex-direction: column; text-align: center; z-index: 998">
        <div class="loading-icon" />
        <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
          Loading Data
        </div>
      </div>
    </b-loading>
    <div v-if="!loading && profile === 'guest'" class="padding-xy-1" style="max-width: 300px; margin: 10% auto;">
      <div class="is-size-5 has-text-centered has-text-weight-bold padding-y-4">
        Login
      </div>
      <LoginComponent />
    </div>
    <div v-else-if="!loading && (profile.hasJfeInspectionSheetPermission || profile.hasLicenseeInspectionSheetPermission)" class="is-fullwidth">
      <div class="hide-for-print">
        <div id="datasheet_builder" class="topbar">
          <div class="padding-xy-2 is-flex-tablet" style="align-items: stretch">
            <div class="field" style="display: flex; flex-direction: column; justify-content: center; flex: 0; margin-top: 15px; margin-bottom: 15px;">
              <div class="columns is-mobile is-multiline is-gapless">
                <div class="column is-narrow is-12-tablet">
                  <b-radio v-model="measureSelect" native-value="imp" style="margin-right: 10px">
                    Imperial
                  </b-radio>
                </div>
                <div class="column is-12-tablet">
                  <b-radio v-model="measureSelect" native-value="met">
                    Metric
                  </b-radio>
                </div>
              </div>
            </div>
            <div class="field">
              <label v-if="connectionSelect">Connection</label>
              <b-select v-model="connectionSelect" expanded placeholder="Connection" :disabled="loading" @change.native="changeSheet('default')">
                <option disabled="disabled" :value="null">
                  Connection
                </option>
                <option v-for="c in connections" :key="c.id" :value="c.id">
                  {{ c.name }}
                </option>
              </b-select>
            </div>
            <div class="field">
              <label v-if="applicationSelect">Application</label>
              <b-select v-model="applicationSelect" expanded placeholder="Application" :disabled="!connectionSelect" @change.native="changeApplication()">
                <option disabled="disabled" :value="null">
                  Application
                </option>
                <option v-if="hasPin" value="pin">
                  Pin
                </option>
                <option v-if="hasBox" value="box">
                  Box
                </option>
              </b-select>
            </div>
            <div class="field">
              <label v-if="sizeSelect">Size</label>
              <b-select v-if="applicationSelect" v-model="sizeSelect" expanded placeholder="Size">
                <option disabled="disabled" :value="null">
                  Size
                </option>
                <option v-for="s in sizesFiltered" :key="s.id" :value="s.id">
                  {{ s.size }}
                </option>
              </b-select>
              <b-select v-else expanded placeholder="Size" disabled>
                <option disabled="disabled" :value="null">
                  Size
                </option>
              </b-select>
            </div>
            <div class="field">
              <label v-if="weightSelect">Weight</label>
              <b-select v-if="sizeSelect" v-model="weightSelect" expanded placeholder="Weight" @change.native="changeSheet('coupling')">
                <option disabled="disabled" :value="null">
                  Weight
                </option>
                <option v-for="w in weightsFiltered" :key="w.id" :value="w.id">
                  {{ w.weight }}
                </option>
              </b-select>
              <b-select v-else expanded placeholder="Weight" disabled>
                <option disabled="disabled" :value="null">
                  Weight
                </option>
              </b-select>
            </div>
            <div class="field">
              <label v-if="couplingSelect && weightSelect">Coupling</label>
              <b-select v-if="weightSelect" v-model="couplingSelect" expanded placeholder="Coupling" :disabled="applicationSelect === 'pin'">
                <option disabled="disabled" :value="null">
                  Coupling
                </option>
                <option v-for="couple in couplingData" :key="couple.id" :value="couple.id">
                  {{ couple.name }}
                </option>
              </b-select>
              <b-select v-else expanded placeholder="Coupling" disabled>
                <option disabled="disabled" :value="null">
                  Coupling
                </option>
              </b-select>
            </div>
            <div class="field">
              <label v-if="couplingSelect && weightSelect">Bevel</label>
              <b-select v-if="couplingSelect" v-model="bevelSelect" expanded placeholder="Bevel" :disabled="applicationSelect === 'pin'">
                <option disabled="disabled" :value="null">
                  Bevel
                </option>
                <option value="20">
                  20&deg;
                </option>
                <option value="45">
                  45&deg;
                </option>
              </b-select>
              <b-select v-else expanded placeholder="Bevel" disabled>
                <option disabled="disabled" :value="null">
                  Bevel
                </option>
              </b-select>
            </div>
            <div class="field">
              <div class="columns is-mobile" style="margin: 0px">
                <div class="column has-text-centered is-6" style="padding: 0 5px 0 0;">
                  <b-button :disabled="!weightSelect" class="is-success padding-y-2 is-fullwidth" style="height: auto" @click="createPDF()">
                    <div><b-icon icon="download" /></div>
                    <div style="line-height: 1.1">
                      <b>Download</b><br> <small>Compressed</small>
                    </div>
                  </b-button>
                </div>
                <div class="column has-text-centered is-6" style="padding: 0 0 0 5px;">
                  <b-button :disabled="!weightSelect" class="is-success padding-y-2 is-fullwidth" style="height: auto" @click="printPdf()">
                    <div><b-icon icon="print" /></div>
                    <div style="line-height: 1.1">
                      <b>Print</b> <br> <span style="white-space: nowrap"><small>Full-resolution</small></span>
                    </div>
                  </b-button>
                </div>
              </div>
            </div>
            <!-- <div class="field is-hidden-mobile" style="flex: 0">
              <b-button icon-left="undo" type="is-cancel-light" style="height: 100%; padding: 0;" @click="resetSheet">
                Reset
              </b-button>
            </div> -->
          </div>
        </div>
      </div>
      <div class="is-fullwidth" style="overflow: auto;">
        <div v-show="!connectionSelect" class="padding-xy-6 has-text-centered">
          <div class="is-size-4 margin-y-3">
            <b-icon icon="arrow-up" />
          </div>
          Build your inspection sheet above
        </div>
        <div v-show="connectionSelect" id="gauge_sheet_wrapper">
          <div id="gauge_sheet_page">
            <div id="pdf_wrapper">
              <div id="gauge_sheet_header" class="table_wrap">
                <table class="is-borderless is-marginless" border="0" cellpadding="0" cellspacing="0">
                  <tr>
                    <td align="left" valign="middle" width="25%" style="text-transform: capitalize">
                      <img v-if="connectionSelect" :src="imageConnection.screenshotUrl" style="width: 100px; vertical-align: middle; margin-right: 10px" crossOrigin="anonymous"> <span class="is-size-6 has-text-weight-bold" style="vertical-align: middle">{{ applicationSelect }} Inspection Sheet</span>
                    </td>
                    <td align="center" width="50%" style="vertical-align: middle">
                      <span v-if="sizeSelect" class="is-size-6">
                        <span class="has-text-weight-light" style="margin-right: 3px">Size:</span>
                        <span class="has-text-weight-bold">{{ size.size }}"</span>
                      </span>
                      <span v-if="weightSelect" class="is-size-6" style="margin-left: 20px">
                        <span class="has-text-weight-light" style="margin-right: 3px">Weight:</span>
                        <span class="has-text-weight-bold">{{ weight.weight }} lb/ft</span>
                      </span>
                      <span v-if="weightSelect" class="is-size-6" style="margin-left: 20px">
                        <span class="has-text-weight-light" style="margin-right: 3px">WT:</span>
                        <span class="has-text-weight-bold">{{ weight.pipeBodyWall }}</span>
                      </span>
                      <span v-if="applicationSelect === 'box' && couplingSelect" class="is-size-6" style="margin-left: 20px">
                        <span class="has-text-weight-light">Coupling:</span>
                        <span class="has-text-weight-bold">
                          <span v-if="couplingSelect">
                            {{ couplingLabel }}
                          </span>
                        </span>
                      </span>
                      <span v-if="applicationSelect === 'box' && bevelSelect" class="is-size-6" style="margin-left: 20px">
                        <span class="has-text-weight-light">Bevel:</span>
                        <span class="has-text-weight-bold">
                          <span v-if="bevelSelect">
                            {{ bevelSelect }}&deg;
                          </span>
                        </span>
                      </span>
                    </td>
                    <td align="right" width="25%" style="vertical-align: middle">
                      Downloaded {{ dateHeader }} <div>by {{ profile.firstName + ' ' + profile.lastName }}</div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="table_wrap">
                <table border="0" cellpadding="0" cellspacing="0">
                  <tr>
                    <td class="nowrap" style="width: 0.1%; max-width: 100%;">
                      <b>Drawing #</b>
                      <div v-if="connectionSelect" style="margin:5px 5px 0 0;">
                        {{ connection.name }} <span v-if="weightSelect">{{ getInspectionSheetDataForWeight('DwgName') }} {{ (weightSelect) ? sdText : '' }} rev. {{ getInspectionSheetDataForWeight('DwgRev') }}</span>
                      </div>
                    </td>
                    <td align="left">
                      <b>Material</b>
                    </td>
                    <td align="left" width="15%">
                      <b>Client</b>
                    </td>
                    <td align="left">
                      <b>W.O.</b>
                    </td>
                    <td align="left">
                      <b>Operator</b>
                    </td>
                    <td align="left">
                      <b>Lathe</b>
                    </td>
                    <td align="left">
                      <b>Inspector</b>
                    </td>
                    <td align="left">
                      <b>STD. No: TSS-</b>
                    </td>
                    <td align="left">
                      <b>Date</b>
                    </td>
                  </tr>
                </table>
              </div>
              <PinTemplate v-if="applicationSelect === 'pin' && sizeSelect && weightSelect" :connection="connection" :application-select="applicationSelect" :size="size" :weight="weight" :measure="measureSelect" :gauge-sheet-text="gaugeSheetText" />
              <!-- <BoxTemplateAdmin v-if="applicationSelect === 'box' && sizeSelect && weightSelect && couplingSelect && (profile.type === 'jfe' && profile.hasJfeAdminPermission)" :connection="connection" :application-select="applicationSelect" :size="size" :weight="weight" :measure="measureSelect" :bevel="bevelSelect" :coupling="couplingById[couplingSelect] || {}" :gauge-sheet-text="gaugeSheetText" /> -->
              <BoxTemplate  v-if="applicationSelect === 'box' && sizeSelect && weightSelect && couplingSelect" :connection="connection" :application-select="applicationSelect" :size="size" :weight="weight" :measure="measureSelect" :bevel="bevelSelect" :coupling="couplingById[couplingSelect] || {}" :gauge-sheet-text="gaugeSheetText" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!loading" class="padding-xy-2" style="max-width: 320px; margin: 5% auto;">
      Your account does not have permission to use the Inspection Sheet generator. Please contact your JFE rep for access.
    </div>
  </div>
</template>

<script>
import LoginComponent from '@/components/misc/Login'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Moment from 'moment'
import PinTemplate from '@/views/inspection_sheets/pin-template.vue'
import BoxTemplate from '@/views/inspection_sheets/box-template.vue'
import Vue2Filters from 'vue2-filters'
// import BoxTemplateAdmin from '@/views/inspection_sheets/box-template-admin.vue'

export default {
  name: 'InspectionSheetGenerator',
  components: {
    LoginComponent,
    PinTemplate,
    BoxTemplate
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      loading: true,
      connectionSelect: null,
      applicationSelect: null,
      sizeSelect: null,
      measureSelect: 'imp',
      weightSelect: null,
      couplingSelect: null,
      bevelSelect: null
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    licenseeConnections () {
      return this.$store.state.licenseeConnections
    },
    hasPin () {
      if (this.profile.type !== 'jfe') {
        const licenseeConnection = this.licenseeConnections.find(l => l.licenseeId === this.profile.licenseeId && l.connectionId === this.connectionSelect)

        if (licenseeConnection) {
          if (licenseeConnection.inspSheetsPin) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
    hasBox () {
      if (this.profile.type !== 'jfe') {
        const licenseeConnection = this.licenseeConnections.find(l => l.licenseeId === this.profile.licenseeId && l.connectionId === this.connectionSelect)

        if (licenseeConnection) {
          if (licenseeConnection.inspSheetsBox) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
    connections () {
      return this.$store.state.connectionsLight.filter(c => c.inspectionAvailability)
    },
    dateHeader () {
      return Moment().format('MMM D, YYYY')
    },
    connection () {
      return this.connections.find(c => c.id === this.connectionSelect)
    },
    size () {
      return this.sizesFiltered.find(s => s.id === this.sizeSelect)
    },
    sizesFiltered () {
      this.changeSheet('size')
      return this.connections.find(c => c.id === this.connectionSelect).sizes.filter(s => !s.deleted)
    },
    weight () {
      return this.weightsFiltered.find(w => w.id === this.weightSelect)
    },
    weightsFiltered () {
      this.changeSheet('weight')
      return this.sizesFiltered.find(s => s.id === this.sizeSelect).weights.filter(w => !w.deleted)
    },
    sdText () {
      if (this.weight.sd) {
        if (this.applicationSelect === 'pin') {
          return 'SD' + this.weight.sd
        } else {
          return 'SD' + this.weight.sd
        }
      } else {
        return null
      }
    },
    imageConnection () {
      return this.connections.find(c => c.id === this.connectionSelect).images.find(i => i.name === 'Logo')
    },
    isBearBox () {
      if (this.connectionSelect && this.applicationSelect) {
        if (this.connection.name === 'JFEBEAR' && this.applicationSelect === 'box') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    nan () {
      return '<span class="has-text-danger">???</span>'
    },
    gaugeSheetText () {
      return this.$store.state.inspectionSheetFields
    },
    couplingData () {
      return this.orderCouplilngVetor(this.weight.couplings.filter(c => c.inspectionSheetsEnabled))
    },
    couplingLabel () {
      return this.couplingById[this.couplingSelect] ? this.couplingById[this.couplingSelect].name : null
    },
    couplingById () {
      const mapped = {}
      this.weight.couplings.forEach(c => {
        mapped[c.id] = c
      })

      return mapped
    }
  },
  async mounted () {
    await this.$store.commit('setTitle', 'Inspection Sheets')
    try {
      this.$store.commit('setApp', 'inspection')
      await this.$store.dispatch('refreshInspectionSheetFields')
      await this.$store.dispatch('refreshConnectionsLight', { hasImages: true, hasSizes: true, hasWeights: true })
      await this.$store.dispatch('refreshLicenseeConnections')
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    resetSheet () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to reset?',
        confirmText: 'Reset Inspection Sheet',
        type: 'is-danger',
        onConfirm: () => {
          this.connectionSelect = null
          this.changeSheet('default')
        }
      })
    },
    changeApplication () {
      this.changeSheet('size')
    },
    changeSheet (field) {
      switch (field) {
        case 'size':
          this.sizeSelect = null
          this.weightSelect = null
          this.couplingSelect = null
          break
        case 'weight':
          this.weightSelect = null
          this.couplingSelect = null
          break
        case 'coupling':
          this.couplingSelect = null
          this.bevelSelect = null
          break
        default:
          this.applicationSelect = null
          this.sizeSelect = null
          this.weightSelect = null
          this.couplingSelect = null
      }

      // if (this.applicationSelect === 'pin') {
      //   this.couplingSelect = 'std'
      // }
    },
    getInspectionSheetDataForSize (field) {
      try {
        if (this.size) {
          const composedField = 'insp' + this.applicationSelect.charAt(0).toUpperCase() + this.applicationSelect.slice(1) + field
          switch (field) {
            case 'AnyDiff':
              return 'special'
            default:
              return this.size[composedField]
          }
        } else {
          return null
        }
      } catch (e) {
        // Not foound
        return '#'
      } finally {

      }
    },
    getInspectionSheetDataForWeight (field) {
      try {
        if (this.weight) {
          const composedField = 'insp' + this.applicationSelect.charAt(0).toUpperCase() + this.applicationSelect.slice(1) + field
          switch (field) {
            case 'AnyDiff':
              return 'special'
            default:
              return this.weight[composedField]
          }
        } else {
          return null
        }
      } catch (e) {
        // Not foound
        return '#'
      } finally {

      }
    },
    printPdf () {
      this.printingPdf = true
      setTimeout(() => {
        window.print()
        this.printingPdf = false
        // window.onafterprint = function () this.printingPdf = false
      }, 500)
    },
    createPDF (quality = 2) {
      this.$scrollTo('body', 200)
      const filename = this.connection.name + '_' + this.applicationSelect + '_' + this.size.size + '_' + this.weight.weight
      setTimeout(function () {
        html2canvas(document.querySelector('#pdf_wrapper'), {
          scale: quality,
          useCORS: true
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/png')
          // eslint-disable-next-line
          const pdf = new jsPDF('landscape', 'in', 'letter')
          const imgProps = pdf.getImageProperties(imgData)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST')
          pdf.save(filename + '_inspection-sheet.pdf')
        })
      }, 300)
    }
  }
}
</script>
<style lang="scss">
  @page {
    size: landscape;
  }
</style>
