<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        Invoice review - #{{ invoice.codeNumber }}
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body content">
      <div style="margin-bottom: 30px; text-align: center">
        <template v-if="!usersLicensee.length>0 || !invoice.dueDate || invoiceReviewList.find(user => user.userId == profile.id)">
          You marked as reviewed on {{ invoiceReviewList.find(user => user.userId == profile.id).dateReview | moment("MMM D, YYYY - hh:mm") }}
        </template>
        <template v-else>
          <b-button size="is-large" type="is-success" rounded @click="reviewInvoice()">
            Mark As Reviewed
          </b-button>
        </template>
      </div>

      <b-table
        :data="invoiceReviewList">
        <b-table-column
          v-slot="props"
          field="name"
          label="Name">
          {{ props.row.firstName }} {{ props.row.lastName }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="email"
          label="Email">
          {{ props.row.username }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="date"
          label="Review Date">
          {{ props.row.dateReview | moment("MMM D, YYYY - hh:mm") }}
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left" />
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="close">
            Close
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import InvoicesApi from '@/apis/InvoicesApi'
import UsersApi from '@/apis/UsersApi'

export default {
  props: {
    invoice: {
      required: true,
      type: Object
    },
    invoiceReviewList: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      loaded: false,
      usersLicensee: []
    }
  },
  computed: {
    licensees () {
      return this.$store.state.licensees
    },
    licensee () {
      return this.licensees.find(
        l => String(l.id) === this.$route.params.licenseeId
      )
    },
    profile () {
      return this.$store.state.profile
    }
  },
  async mounted () {
    this.usersLicensee = await UsersApi.getUsersByLicenseeId(this.licensee.id)
    this.loaded = true
  },
  methods: {
    async reviewInvoice () {
      await InvoicesApi.createInvoiceReview({ invoiceId: this.invoice.id, userId: this.profile.id }).then(async () => {
        this.$buefy.toast.open('Invoice reviewed')
        this.$emit('review', null)
        this.$parent.close()
      })
    },
    close () {
      this.$emit('close', null)
      this.$parent.close()
    }
  }
}

</script>
