<template>
  <div>
    <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false">
      <div style="flex-direction: column; text-align: center; z-index: 998">
        <div class="loading-icon" />
        <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
          Loading Files
        </div>
      </div>
    </b-loading>
    <div v-if="fileId && canDownload && preparingFile" class="padding-xy-1" style="max-width: 300px; margin: 10% auto;">
      <b-button
        class="is-small"
        icon-left="download"
        @click="downloadFile(filesById[fileId])">
        Click here if your download didn't start.
      </b-button>
    </div>
    <div v-if="!loading">
      <div class="padding-y-4 padding-y-5-tablet has-text-centered">
        <h1 class="is-size-4" style="margin-bottom: 25px">
          <a v-if="activeConnection" @click="activeConnection = null">Technical Documents</a>
          <span v-else>Technical Documents</span>
        </h1>
        <div class="columns document_connections is-centered is-marginless is-mobile" :class="{'selected_connection' : activeConnection}">
          <div v-for="connection in connections" :key="connection.id" class="column is-narrow" :class="{'active' : activeConnection === connection.id}" @click="activeConnection = connection.id">
            <img v-if="connection.images.find(i => i.name === 'Logo').screenshotUrl" :src="connection.images.find(i => i.name === 'Logo').screenshotUrl || {}.screenshotUrl">
            <div v-else class="is-flex" style="height: 100%; align-items: center;">
              <label class="label is-clickable">{{ connection.name }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="padding-x-2 padding-x-5-tablet padding-y-3">
        <div class="columns is-multiline is-vcentered">
          <div class="column">
            <b-field>
              <b-taginput
                v-model="searchTerms"
                placeholder="Search..."
                icon="search"
                type="is-dark"
                rounded />
            </b-field>
          </div>
          <div v-if="profile.hasJfeAdminPermission" class="column is-narrow">
            <autocomplete-single
              v-model="selectedLicensee"
              class="field no-icon wrap-item"
              placeholder="Company"
              icon="id-badge"
              :open-on-focus="true"
              :items="licensees"
              text-field="name"
              value-field="id"
              name="licensee"
              style="width: 300px" />
          </div>
          <div v-if="profile.type !== 'jfe' && profile !== 'guest'" class="column is-narrow">
            <b-field>
              <b-checkbox v-model="hideDownloads" value-true="1" value-false="0">
                Hide downloaded files
              </b-checkbox>
            </b-field>
          </div>
          <div v-if="selectedLicensee" class="column is-12 has-text-centered">
            <div class="has-text-weight-bold">
              {{ licenseesById[selectedLicensee].name }} download progress
            </div>
            <b-icon class="is-clickable margin-x-2" icon="times-circle" size="is-small" type="is-danger" @click.native="selectedLicensee=null" />

            <div style="max-width: 280px; margin: 15px auto">
              <b-progress size="is-medium" :value="downloadedByLicenseePercentage()" type="is-success" show-value format="percent">
                <div class="has-text-black" style="margin-top: 3px">
                  {{ numberFormat(downloadedByLicenseePercentage(), 0) || 0 }}% downloaded
                </div>
              </b-progress>
            </div>
          </div>
          <div v-if="profile !== 'guest' && profile.type !== 'jfe'" class="column is-12 has-text-centered ">
            <div style="max-width: 280px; margin: 15px auto">
              <b-progress size="is-medium" :value="downloadedByUserPercentage()" type="is-success" show-value format="percent">
                <div class="has-text-black" style="margin-top: 3px">
                  {{ numberFormat(downloadedByUserPercentage(), 0) || 0 }}% downloaded
                </div>
              </b-progress>
            </div>
          </div>
        </div>
      </div>
      <!-- BULID START -->
      <div v-for="connectionTable in connections" :key="connectionTable.id">
        <div v-if="activeConnection === connectionTable.id || activeConnection == null" class="padding-x-0 padding-x-5-tablet padding-y-3">
          <b-table
            ref="docsTable"
            class="nested_rows has-shadow-30 light-header"
            :class="openedCategoryRow.length ? 'dim' : ''"
            :mobile-cards="false"
            :data="categoriesMounted(connectionTable)"
            detailed
            custom-detail-row
            :opened-detailed="openedCategoryRow"
            detail-key="detailId"
            :row-class="(row, index) => (openedCategoryRow.find(c => c === row.detailId) ? 'is-open' : '')"
            @click="(row, index) => closeOtherRows(row, index)"
            @details-open="(row, index) => closeOtherRows(row, index)">
            <b-table-column
              :custom-key="connectionTable.name">
              <template v-slot:header="{ props }">
                <span v-if="connectionTable.images.find(i => i.name === 'Logo').screenshotUrl">
                  <img style="width: 100px" :src="connectionTable.images.find(i => i.name === 'Logo').screenshotUrl">
                </span>
                <span v-else style="color: black">
                  {{ connectionTable.name }} {{ props }}
                </span>
              </template>
              <template v-slot="props">
                {{ props.row.name }} <b-tag class="margin-x-3" rounded>
                  {{ filesFilteredByCategoryAndConnection(props.row.id, connectionTable.id).length }}
                </b-tag>
              </template>
            </b-table-column>
            <b-table-column
              v-slot="props"
              custom-key="date"
              field="date"
              label="">
              <span v-if="openedCategoryRow.find(c => c === props.row.detailId)">
                Date
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              custom-key="downloaded"
              field="downloads"
              label="">
              <span v-if="openedCategoryRow.find(c => c === props.row.detailId) && profile.type !== 'jfe' && profile !== 'guest'">
                Downloaded
              </span>
            </b-table-column>
            <b-table-column
              v-if="!selectedLicensee"
              v-slot="props"
              custom-key="downloads"
              field="downloads"
              label=""
              :visible="profile.hasJfeAdminPermission ? true : false">
              <span v-if="openedCategoryRow.find(c => c === props.row.detailId)">
                Downloads
              </span>
            </b-table-column>
            <b-table-column
              v-slot="props"
              custom-key="actions"
              class="has-text-right"
              field="actions"
              label=""
              numeric>
              <b-button
                v-if="profile.hasJfeAdminPermission && openedCategoryRow.find(c => c === props.row.detailId)"
                class="is-small is-borderless"
                icon-left="plus"
                @click="fileModalDownload(connectionTable, props.row, {id: 'new', name: 'New File'})">
                Add File
              </b-button>
            </b-table-column>

            <template slot="detail" slot-scope="props">
              <tr v-if="filesFilteredByCategoryAndConnection(props.row.id, connectionTable.id).length === 0" style="opacity: 1">
                <td />
                <td colspan="5">
                  No files
                </td>
              </tr>
              <tr
                v-for="file in filesFilteredByCategoryAndConnection(props.row.id, connectionTable.id).filter(f => {
                  if(hideDownloads){
                    if(downloadedByUserId(f.id, profile.id).length > 0){
                      return false
                    }
                  }
                  return true
                })"
                :key="file.name"
                class="child_row"
                v-on="profile.hasJfeAdminPermission ? { click: () => fileModalDownload(connectionTable, props.row, file) } : { click: () => downloadFile(file)}">
                <td><b-button class="is-small is-primary" :icon-left="profile.hasJfeAdminPermission ? 'pencil' : 'download'" /></td>
                <td>
                  {{ file.name }}
                  <b-tag v-if="file.revisionId" class="margin-x-2">
                    rev. {{ file.revision }}
                  </b-tag>
                  <b-tag v-else type="is-danger" class="margin-x-2">
                    no revisions
                  </b-tag>
                </td>
                <td>
                  {{ file.revisionDateCreated | moment("MMM D, YYYY") }}
                </td>
                <td>
                  <div v-if="selectedLicensee">
                    <b-icon v-if="downloadedByUserId(file.id, selectedLicensee.id).length > 0" icon="check" size="is-small" />
                  </div>
                  <div v-if="profile.type !== 'jfe'">
                    <b-icon v-if="downloadedByUserId(file.id, profile.id).length > 0" icon="check" size="is-small" />
                  </div>
                </td>
                <td v-if="profile.hasJfeAdminPermission && !selectedLicensee" width="200">
                  <b-progress :value="downloadedByJfeUsersPercentage(file, props.row)" type="is-warning" show-value format="percent">
                    <span class="has-text-black">{{ numberFormat(downloadedByJfeUsersPercentage(file, props.row), 0) || 0 }}% Employees</span>
                  </b-progress>
                  <b-progress v-if="props.row.id !== 5" :value="downloadedByLicenseePercentage(file, props.row)" type="is-success" show-value format="percent">
                    <span class="has-text-black">{{ numberFormat(downloadedByLicenseePercentage(file, props.row), 0) || 0 }}% Lincesees</span>
                  </b-progress>
                </td>
                <td class="has-text-right">
                  <b-button
                    v-if="file.revisionEnabled"
                    class="is-small"
                    icon-left="download"
                    @click.stop="downloadFile(file)">
                    Download
                  </b-button>
                  <b-button
                    v-else
                    class="is-small is-borderless"
                    icon-left="plus"
                    @click="fileModalDownload(connectionTable, props.row, file)">
                    Add Revision
                  </b-button>
                </td>
              </tr>
            </template>
          </b-table>
        </div>
      </div>
      <div class="padding-y-6" />
      <b-loading :is-full-page="true" :active.sync="preparingFile" :can-cancel="false">
        <div style="flex-direction: column; text-align: center; z-index: 998">
          <div class="loading-icon" />
          <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
            Preparing your file
          </div>
        </div>
      </b-loading>
    <!-- BUILD FINISH -->
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import FileEditor from '@/components/modals/FileEditor'
import FilesApi from '@/apis/FilesApi'
import Moment from 'moment'
import LoginComponent from '@/components/misc/Login'

export default {
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      activeConnection: null,
      openedCategoryRow: [],
      addSizeToggle: false,
      selectedLicensee: null,
      selectedLicenseeFilter: ['licensee', 'jfe'],
      loading: true,
      fileDownload: {
        id: null,
        userId: null,
        fileId: null,
        revisionId: null,
        categoryId: null,
        connectionId: null
      },
      totalFilesShouldDownload: 0,
      preparingFile: false,
      hideDownloads: false,
      searchTerms: []
    }
  },
  computed: {
    fileId () {
      return parseInt(this.$route.params.id)
    },
    canDownload () {
      if (!this.loading) {
        const choosedFile = this.files.find(f => f.revisionId === this.fileId) || null

        if (choosedFile) {
          const hasCategory = this.categoriesMounted(this.connectionById[choosedFile.connectionId]).find(c => c.id === choosedFile.categoryId) || null

          if (hasCategory) {
            this.downloadFile(choosedFile)
            return true
          } else {
            var message = 'You don\'t have permission to download this file'

            if (this.profile === 'guest') {
              message = 'You are not logged in'
            }
            this.$buefy.toast.open({
              message: message,
              type: 'is-danger'
            })
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    profile () {
      return this.$store.state.profile
    },
    connections () {
      var connections = []
      this.$store.state.connections.filter(c => c.technicalDocsAvailability).map(c => {
        const categories = this.categoriesMounted(c)
        let totalPublicDocs = 0

        for (const category of categories) {
          const totalFilesThisCategory = this.filesFilteredByCategoryAndConnection(category.id, c.id).length
          if (category.name.toLowerCase() === 'public docs') totalPublicDocs += totalFilesThisCategory
          this.totalFilesShouldDownload += totalFilesThisCategory
        }

        if (totalPublicDocs === 0) {
          if (this.profile !== 'guest') {
            // Remove Connection
            connections.push(c)
          }
        } else {
          connections.push(c)
        }
      })
      connections.push({ id: 0, name: 'Standard Documents', images: [{ name: 'Logo', data: null, screenshotUrl: null }], sizes: [] })

      return connections
    },
    connectionById () {
      const mapped = {}

      this.connections.forEach(connection => {
        mapped[connection.id] = connection
      })

      return mapped
    },
    licensees () {
      return this.orderBy(this.$store.state.licensees, 'name')
    },
    licenseesById () {
      const mapped = {}

      this.licensees.forEach(licensee => {
        mapped[licensee.id] = licensee
      })

      return mapped
    },
    licenseeConnections () {
      return this.$store.state.licenseeConnections
    },
    files () {
      return this.$store.state.files
    },
    downloads () {
      return this.$store.state.fileDownloads
    },
    downloadsByLicensee () {
      return this.downloads.filter(d => d.licenseeId === this.selectedLicensee)
    },
    downloadsUniqueBySelectedLicensee () {
      return this.downloadsByLicensee.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.fileId).indexOf(obj.fileId) === pos
      })
    },
    categories () {
      return this.$store.state.technicalDocuments
    },
    filesById () {
      const mapped = {}

      this.files.forEach(file => {
        mapped[file.id] = file
      })

      return mapped
    },
    filesByConnectionId () {
      const mapped = {}

      this.files.forEach(file => {
        mapped[file.id] = file
      })

      return mapped
    },
    fileCategories () {
      return this.$store.state.fileCategories
    },
    users () {
      return this.$store.state.users
    },
    jfeUsers () {
      return this.users.filter(u => u.type === 'jfe' && u.enabled && !u.deleted)
    },
    userById () {
      const mapped = {}

      this.users.forEach(user => {
        mapped[user.id] = user
      })

      return mapped
    }
  },
  async mounted () {
    this.$store.commit('setApp', 'technical')
    this.$store.commit('setTitle', 'Technical Documents')
    await this.$store.dispatch('refreshConnections')
    await this.$store.dispatch('refreshFiles')
    await this.$store.dispatch('refreshTechnicalDocuments')
    await this.$store.dispatch('refreshFileCategories')
    await this.$store.dispatch('refreshFileDownloads')
    await this.$store.dispatch('refreshLicensees')
    await this.$store.dispatch('refreshLicenseeConnections')
    if (this.profile !== 'guest') {
      await this.$store.dispatch('refreshUsers')
    }

    // console.log("licensees=" + JSON.stringify(this.licensees))
    this.loading = false

    if (this.profile === 'guest' && this.fileId && !this.canDownload) {
      this.showLogin()
    }

    // Prepare the total files this user licensee should download
    this.totalFilesShouldDownload = 0
    for (const connection of this.connections) {
      const categories = this.categoriesMounted(connection)

      for (const category of categories) {
        const totalFilesThisCategory = this.filesFilteredByCategoryAndConnection(category.id, connection.id).length
        this.totalFilesShouldDownload += totalFilesThisCategory
      }
    }
  },
  methods: {
    showLogin () {
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: LoginComponent,
        props: {
          closeModal: true
        },
        events: {
          loginSuccess: async () => {
            console.log('success')
          }
        }
      })
    },
    toggleDocsRow (row) {
      this.$refs.docsTable.toggleDetails(row)
    },
    closeOtherRows (row, index) {
      this.openedCategoryRow = [row.detailId]
    },
    categoriesMounted (connection) {
      var hasBox = false
      var hasPin = false
      // var hasTechDoc = false
      var licenseeConnection = null

      if ((this.profile !== 'guest') && (connection.id !== 0)) {
        if (this.profile.type === 'jfe') {
          if (this.selectedLicensee) {
            licenseeConnection = this.licenseeConnections.find(l => l.licenseeId === this.selectedLicensee && l.connectionId === connection.id)

            if (licenseeConnection) {
              if (licenseeConnection.techDocsPin) {
                hasPin = true
              }
              if (licenseeConnection.techDocsBox) {
                hasBox = true
              }
            }

            const userLicensee = this.users.find(u => u.licenseeId === this.selectedLicensee && u.hasLicenseeTechnicalDataPermission) || null
            if (userLicensee) {
              // hasTechDoc = true
            }
          } else {
            hasBox = true
            hasPin = true
            // hasTechDoc = true
          }
        } else {
          if (this.profile.hasLicenseeTechnicalDataPermission) {
            // hasTechDoc = true
            licenseeConnection = this.licenseeConnections.find(l => l.licenseeId === this.profile.licenseeId && l.connectionId === connection.id)

            if (licenseeConnection) {
              if (licenseeConnection.techDocsPin) {
                hasPin = true
              }
              if (licenseeConnection.techDocsBox) {
                hasBox = true
              }
            }
          }
        }
      }

      var categories = []
      // Update detailId forEach category
      this.categories.map(category => {
        var newCategory = {
          id: category.id,
          name: category.name,
          sequence: category.sequence,
          files: category.files
        }
        newCategory.detailId = newCategory.name + String(connection.id)
        let showCategory = true

        if (this.filesFilteredByCategoryAndConnection(category.id, connection.id).length === 0) {
          if (this.profile.type !== 'jfe') showCategory = false
        }

        switch (newCategory.name.toLowerCase()) {
          case 'pin':
            if (hasPin && showCategory) {
              categories.push(newCategory)
            }
            break
          case 'box':
            if (hasBox && showCategory) {
              categories.push(newCategory)
            }
            break
          case 'technical package':
            if ((hasPin || hasBox) && showCategory) {
              categories.push(newCategory)
            }
            break
          case 'employee docs':
            if (this.profile && this.profile.hasJfeAdminPermission) {
              categories.push(newCategory)
            }
            break
          default:
            if (showCategory) categories.push(newCategory)
        }
      })

      return categories
    },
    createObjectURL (fileData) {
      const reader = new FileReader()
      const blob = new Blob([fileData.data], { type: fileData.type })
      reader.onload = () => {
        return URL.createObjectURL(blob)
      }

      reader.readAsDataURL(blob)
    },
    fileModalDownload (connection, category, file) {
      if (this.profile.hasJfeAdminPermission) {
        this.$buefy.modal.open({
          parent: this,
          component: FileEditor,
          props: {
            connection: connection,
            category: category,
            file: file,
            users: this.users,
            downloads: this.downloads,
            selectedLicensee: this.selectedLicensee
          },
          events: {
            save: async () => {
              await this.$store.dispatch('refreshFiles')
              await this.$store.dispatch('refreshTechnicalDocuments')
              await this.$store.dispatch('refreshFileDownloads')
            },
            delete: async () => {
              await this.$store.dispatch('refreshFiles')
              await this.$store.dispatch('refreshTechnicalDocuments')
              await this.$store.dispatch('refreshFileDownloads')
            }
          }
        })
      } else {
        alert('Download file')
      }
    },
    async downloadFile (file) {
      this.preparingFile = true
      try {
        // Create log
        var fileDownload = this.fileDownload
        fileDownload.userId = this.profile.id
        fileDownload.fileId = file.id
        fileDownload.revisionId = file.revisionId
        fileDownload.categoryId = file.categoryId
        fileDownload.connectionId = file.connectionId
        await FilesApi.createFileDownload(fileDownload)
        await this.$store.dispatch('refreshFileDownloads')
      } catch (e) {
        console.error(e)
      } finally {
        //= ==================================
        // Create the link file and send to the user
        //= ==================================
        // // https://stackoverflow.com/questions/53772331/vue-html-js-how-to-download-a-file-to-browser-using-the-download-tag
        const dateTimestamp = Moment().format('YYYYMMDDHHmmss')
        const link = document.createElement('a')
        link.href = await FilesApi.getFileRevisionDownload(file.revisionId, this.profile.id)
        link.download = (file.name + '_Rev' + file.revision + '_' + dateTimestamp + '.' + file.type.split('/')[1]).replace(/ /g, '_')
        // link.download = file.name
        link.click()
        this.preparingFile = false
      }
    },
    filesFilteredByCategoryAndConnection (categoryId = null, connectionId = null) {
      return this.orderBy(this.files.filter(f => {
        return this.searchTerms.length === 0 || this.searchTerms.some(term => {
          const haystack = [f.name].join('|').toLowerCase()
          return haystack.includes(term.toLowerCase())
        })
      }).filter(f => {
        // Set the special treatments
        if (!f.connectionId) { // If connection is null, then is a Standard Doc
          f.connectionId = 0
        }
        if ((categoryId) && (connectionId >= 0)) {
          return ((f.categoryId === categoryId && f.connectionId === connectionId && f.revisionEnabled) || (f.categoryId === categoryId && f.connectionId === connectionId && !f.revisionId && !f.revisionEnabled))
        } else {
          return true
        }
      }).filter(f => {
        return ((f.revisionEnabled) || (!f.revisionId && !f.revisionEnabled))
      }), 'name')
    },

    filesFilteredByLicenseePermission (licenseeId = null) {
      let licenseeHasPermission = null
      return this.files.filter(file => {
        switch (file.categoryId) {
          case 1:
            return true
          case 2:
            licenseeHasPermission = this.licenseeConnections.find(l => l.licenseeId === licenseeId && l.connectionId === file.connectionId)
            if (licenseeHasPermission) {
              return licenseeHasPermission.techDocsPin
            } else {
              return false
            }
          case 3:
            licenseeHasPermission = this.licenseeConnections.find(l => l.licenseeId === licenseeId && l.connectionId === file.connectionId)
            if (licenseeHasPermission) {
              return licenseeHasPermission.techDocsBox
            } else {
              return false
            }
          case 4:
            const userLicensee = this.users.find(u => u.licenseeId === licenseeId && u.hasLicenseeTechnicalDataPermission) || null
            if (userLicensee) {
              return true
            } else {
              return false
            }

          default:
            return false
        }
      })
    },

    downloadedByUserId (fileId, userId) {
      return this.downloads.filter(d => d.fileId === parseInt(fileId) && d.userId === parseInt(userId))
    },
    downloadedByUserPercentage () {
      const downloadsMade = this.downloads.filter(d => d.userId === parseInt(this.profile.id))
      const totalDownloaded = this.downloadsUniqueByField(downloadsMade, 'fileId').length
      var percentage = 0

      if (this.totalFilesShouldDownload > 0) {
        percentage = (totalDownloaded / this.totalFilesShouldDownload) * 100
      }

      return ((percentage > 100) ? 100 : percentage) || 0
    },
    downloadsUniqueByField (myArr, prop) {
      // prop = the field that should not be repeated
      // https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
      return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos && obj[prop]
      })
    },
    downloadedByLicenseePercentage (file = null, category = null) {
      if (this.selectedLicensee) {
        return ((this.downloadsUniqueBySelectedLicensee.length / this.filesFilteredByLicenseePermission(this.selectedLicensee).length) * 100) || 0
      } else {
        // Step One - What is the category?
        // Step Two - Which licensee has access for this category
        // Step Three - How many downloads were take, at least one of each licensee
        const totalLicensees = this.licenseesFilteredByCategory(category, file)
        const downloadsMade = this.downloads.filter(d => d.fileId === file.id && d.revisionId === file.revisionId)
        const totalDownloaded = this.downloadsUniqueByField(downloadsMade, 'licenseeId').length
        const percentage = (totalDownloaded / totalLicensees.length) * 100
        return ((percentage > 100) ? 100 : percentage) || 0
      }
    },
    downloadedByJfeUsersPercentage (file = null, category = null) {
      // Step One - What is the category?
      // Step Two - Which licensee has access for this category
      // Step Three - How many downloads were take, at least one of each licensee
      const totalJfeUsers = this.jfeUsers
      const downloadsMade = this.downloads.filter(d => d.fileId === file.id && d.revisionId === file.revisionId && d.categoryId === category.id).filter(d => {
        if (this.jfeUsers.find(u => u.id === d.userId)) {
          return true
        }
        return false
      })
      const totalDownloaded = this.downloadsUniqueByField(downloadsMade, 'userId').length
      const percentage = (totalDownloaded / totalJfeUsers.length) * 100

      return ((percentage > 100) ? 100 : percentage) || 0
    },
    licenseesFilteredByCategory (category, file) {
      return this.licensees.filter(l => {
        var licenseeConnection = this.licenseeConnections.find(lc => lc.licenseeId === l.id && lc.connectionId === file.connectionId)
        switch (category.id) {
          case 2: // Pin
            if (licenseeConnection) {
              return licenseeConnection.techDocsPin
            } else {
              return false
            }

          case 3: // Box
            if (licenseeConnection) {
              return licenseeConnection.techDocsBox
            } else {
              return false
            }

          case 4: // Technical Packages
          case 1: // Public Docs
            const userLicensee = this.users.find(u => u.licenseeId === l.id && u.hasLicenseeTechnicalDataPermission) || null
            if (userLicensee) {
              if (licenseeConnection) {
                if ((licenseeConnection.techDocsPin) || (licenseeConnection.techDocsBox)) {
                  return true
                } else {
                  return false
                }
              } else {
                return false
              }
            } else {
              return false
            }

          default: // Public
            return true
        }
      })
    }

  }
}
</script>
