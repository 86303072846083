<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        <div class="align-center">
          <span v-if="requestStep === 'initiateRequest'">Initiate Calibration</span>
          <span v-if="requestStep === 'finalize'">Finalize Calibration</span>
        </div>
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="padding-xy-3 padding-xy-4-tablet">
        <div class="columns is-multiline">
          <div class="column is-full" v-if="requestStep === 'initiateRequest'">
            <div class="card">
              <b-field label="Vendor">
                <b-input v-model="vendor" placeholder="Enter vendor name" />
              </b-field>
            </div>
          </div>
          <div class="column is-narrow" v-if="requestStep === 'initiateRequest'">
            <div class="card">
              <b-field label="Sent date" :type="{'is-danger': errors.has('sentDate')}" :message="errors.first('sentDate')">
                <b-datepicker v-model="sentDate" v-validate="'required'" icon="calendar-alt" :mobile-modal="false" name="sentDate" data-vv-as="date" />
              </b-field>
            </div>
          </div>
          <div class="column is-full" v-if="requestStep === 'finalize'">
            <div class="card">
              <div class="card-label">Vendor</div>
              <div class="has-text-weight-bold">{{ requestItem.vendor }}</div>
            </div>
          </div>
          <div class="column is-narrow" v-if="requestStep === 'finalize'">
            <div class="card">
              <b-field label="Expiration date" :type="{'is-danger': errors.has('expirationDate')}" :message="errors.first('expirationDate')">
                <b-datepicker v-model="expirationDate" v-validate="'required'" icon="calendar-alt" :mobile-modal="false" name="expirationDate" data-vv-as="date" />
              </b-field>
            </div>
          </div>
          <div class="column is-full" v-if="requestStep === 'finalize'">
            <div class="card">
              <b-field
                class="file"
                style="border:none; background: none"
                :type="{'is-danger': errors.has('fileUp')}"
                :message="errors.first('fileUp')">
                <b-upload
                  refs="file"
                  drag-drop
                  name="fileUp"
                  data-vv-as="file"
                  @input="uploadFile(optionalFile, $event)">
                  <div v-if="optionalFile.data" class="is-inline-flex align-center">
                    <strong style="margin-right: 5px;">File:</strong> {{ file.name }} Rev {{ optionalFile.revision }} <b-icon icon="times-circle" type="is-danger" class="is-clickable is-small margin-x-2" @click.native="optionalFileInit()" />
                  </div>
                  <a v-if="!optionalFile.data && !wrongFile" class="button is-light is-inline-flex align-center">
                    <b-icon icon="upload" class="is-clickable is-small margin-x-2" />
                    <span>Upload certificate (PDF, JPG, PNG)</span>
                  </a>
                  <div v-if="wrongFile" class="is-inline-flex align-center">
                    <strong style="margin-right: 5px;">Just PDF, JPG and PNG files are permitted</strong> <b-icon icon="times-circle" type="is-danger" class="is-clickable is-small margin-x-2" @click.native="optionalFileInit()" />
                  </div>
                </b-upload>
              </b-field>
              <object
                v-if="optionalFile.data"
                :data="originalFileURL"
                type="application/pdf"
                width="100%"
                height="500px" />
            </div>
          </div>
        </div>
        <div>
          <b-field label="Notes">
            <b-input
              type="textarea"
              v-model="notes"
              placeholder="Enter notes"
            ></b-input>
          </b-field>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div v-if="errorForm" class="container level is-mobile">
        <span class="error-message">{{ errorForm }}</span>
      </div>
      <div class="container level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="close">
            Cancel
          </button>
          <button class="button is-primary" @click="save">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import GaugesApi from '@/apis/GaugesApi'

export default {
  props: {
    gauge: Object,
    requestStep: String,
    requestItem: Object,
    requestType: String
  },
  data () {
    return {
      loaded: false,
      selectedAction: null,
      notes: '',
      vendor: '',
      sentDate: null,
      expirationDate: null,
      optionalFile: null,
      errorForm: null,
      wrongFile: false,
      originalFileURL: null,
      file: { id: 'new', name: 'New File' }
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    }
  },
  async mounted () {
    this.loaded = true
    this.selectedAction = this.requestStep
    this.optionalFileInit()
  },
  methods: {
    optionalFileInit () {
      this.wrongFile = false
      this.optionalFile = {
        fileId: this.file.id,
        type: null,
        data: null
      }
    },
    async uploadFile (optionalFile, file) {
      try {
        const reader = new FileReader()

        reader.onload = async () => {
          optionalFile.type = file.type
          const typeSplit = file.type.split('/')

          if (typeSplit[1] === 'pdf' || typeSplit[1] === 'jpg' || typeSplit[1] === 'jpeg' || typeSplit[1] === 'png') {
            optionalFile.data = reader.result
            optionalFile.size = file.size
            optionalFile.fileId = this.file.id
            optionalFile.name = file.name

            // Preview
            this.originalFileURL = URL.createObjectURL(file)
          } else {
            this.wrongFile = true
          }
        }

        reader.readAsDataURL(file)
      } catch (e) {
        this.errors.items.push('optionalFile')
        console.error(e)
      }
    },
    async save () {
      this.errorForm = null
      try {
        switch (this.requestStep) {
          case 'initiateRequest':
            await GaugesApi.createGaugeActivityRequest({
              gaugeId: this.gauge.id,
              userId: this.profile.id,
              shippingAddress: null,
              type: 'calibration',
              notes: this.notes,
              licenseeId: this.profile.licenseeId,
              vendor: this.vendor,
              sentDate: this.sentDate
            })
            this.$buefy.toast.open('Calibration started')
            break
          case 'finalize':
            const id = this.requestItem.events[0].requestId
            const data = {
              id,
              status: 'finalized',
              type: this.requestType,
              gaugeId: this.gauge.id,
              notes: this.notes,
              expirationDate: this.expirationDate,
              file: this.optionalFile
            }
            await GaugesApi.updateGaugeActivityRequest(id, data)
            this.$buefy.toast.open({
              message: 'Calibration finalized',
              type: 'is-success'
            })
            break
        }

        this.$emit('saved')
        this.close()
      } catch (error) {
        this.$buefy.toast.open('Failed to start checkout')
        console.error(error)
      }
    },
    close () {
      this.$parent.close()
    }
  }
}

</script>

<style lang="scss" scoped>
.column.is-narrow {
  max-width: 100%;
}
.card {
  background: transparent;
  border: none;
  display: block;
  font-size: 14px;
  .has-text-weight-bold {
    font-size: 15px;
  }
  .card-label {
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-size: 12px;
    color: #000;
    margin-bottom: 8px;
    border-bottom: solid 1px;
    display: inline-block;
  }
}
.error-message {
  color: #ff0000;
  font-size: 14px;
}
</style>
