<template>
  <div v-if="loaded" class="padding-x-5-tablet">
    <div class="padding-xy-4 padding-x-0-tablet padding-y-5-tablet">
      <div class="columns is-multiline is-mobile is-vcentered">
        <div class="column is-12-mobile">
          <b-field>
            <b-input
              v-model="searchText"
              placeholder="Search..."
              type="search"
              icon="search" />
          </b-field>
        </div>
        <div class="column is-narrow-tablet is-12-mobile">
          <b-field v-show="offices && offices.length > 1 && showOffice">
            <autocomplete-single
              v-model="searchOffice"
              class="field no-icon"
              placeholder="Office"
              icon="id-badge"
              :open-on-focus="true"
              :items="offices"
              text-field="name"
              value-field="id"
              name="office" />
          </b-field>
        </div>
        <div class="column is-narrow-tablet is-12-mobile">
          <b-field v-show="licenseesByOffice && licenseesByOffice.length > 1 && showCompany" :type="{'is-danger': errors.has('licensee')}" :message="errors.first('licensee')">
            <autocomplete-single
              v-model="searchCompany"
              class="field no-icon wrap-item"
              placeholder="Company"
              icon="id-badge"
              :open-on-focus="true"
              :items="licenseesByOffice"
              text-field="name"
              value-field="id"
              name="licensee" />
          </b-field>
        </div>
        <div class="column is-narrow-tablet">
          <div class="field" style="line-height: 1">
            <b-checkbox v-model="showDisabled">
              Show Inactive
            </b-checkbox>
          </div>
        </div>
      </div>
    </div>

    <b-table
      :data="users"
      class="scrollable clickable-row"
      :mobile-cards="false"
      :hoverable="true"
      default-sort="firstName"
      @click="goToUser">
      <b-table-column v-slot="props" field="active" label="" :visible="showDisabled" width="10">
        <b-icon v-if="!props.row.enabled" icon="ban" size="is-small" type="is-danger" />
      </b-table-column>
      <b-table-column v-slot="props" field="firstName" label="Name" sortable>
        {{ props.row.firstName }} {{ props.row.lastName }}
      </b-table-column>
      <b-table-column v-slot="props" field="username" label="Username" sortable>
        {{ props.row.username }}
      </b-table-column>

      <b-table-column v-slot="props" field="location" label="Location" cell-class="permissions_column">
        <div v-if="props.row.type == 'jfe' && props.row.officeId" class="align-center">
          <img src="@/assets/images/JFE-logo-icon_mini.png" class="ml-1 mr-2" style="width: 18px;">
          <span class="perm active">
            {{ officesById[props.row.officeId].name }}
          </span>
        </div>
        <div v-if="props.row.type == 'licensee' && props.row.licenseeId" class="align-center">
          <span class="perm active"><b-icon icon="id-badge" type="is-primary" /></span>
          <span class="perm active">{{ licenseesById[props.row.licenseeId].name }}</span>
        </div>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="user_permissions"
        label="Permissions"
        cell-class="permissions_column">
        <div v-if="props.row.type == 'jfe'">
          <span class="perm" :class="{ active: props.row.hasJfeAdminPermission }">A</span>
          <span class="perm" :class="{ active: props.row.hasJfeRoyaltyTrackerPermission }">R</span>
          <span class="perm" :class="{ active: props.row.hasJfeGaugeTrackerPermission }">G</span>
          <span class="perm" :class="{ active: props.row.hasJfeInspectionSheetPermission }">I</span>
          <span class="perm" :class="{ active: props.row.hasJfeDataManagerPermission }">D</span>
          <!-- <span class="perm" :class="{ active: props.row.hasJfeBlankingDimensionsPermission }">B</span> -->
        </div>
        <div v-if="props.row.type == 'licensee'">
          <span class="perm" :class="{ active: props.row.hasLicenseeRoyaltyTrackerPermission }">R</span>
          <span class="perm" :class="{ active: props.row.hasLicenseeGaugeTrackerPermission }">G</span>
          <span class="perm" :class="{ active: props.row.hasLicenseeTechnicalDataPermission }">T</span>
          <span class="perm" :class="{ active: props.row.hasLicenseeInspectionSheetPermission }">I</span>
          <!-- <span class="perm" :class="{ active: props.row.hasLicenseeBlankingDimensionsPermission }">B</span> -->
        </div>
      </b-table-column>

      <b-table-column v-slot="props" field="dongle" label="Dongle">
        {{ props.row.dongle }}
      </b-table-column>
    </b-table>
    <div class="padding-y-4" />
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'

export default {
  name: 'Users',
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      searchText: null,
      searchCompany: null,
      searchOffice: null,
      showDisabled: false,
      loaded: false,
      showCompany: true,
      showOffice: true
    }
  },
  computed: {
    users () {
      let users = this.$store.state.users

      if (this.searchText) {
        const searchText = this.searchText.toLowerCase()

        users = users.filter(user => {
          const searchFull = `${user.firstName} ${user.lastName} ${user.username} ${user.dongle}`.toLowerCase()

          return searchFull.includes(searchText)
        })
      }
      if (this.searchOffice) {
        users = users.filter(user => {
          return user.officeId === this.searchOffice
        })
      }
      if (this.searchCompany) {
        users = users.filter(user => {
          return user.licenseeId === this.searchCompany
        })
      }
      if (!this.showDisabled) {
        users = users.filter(user => {
          return user.enabled === 1
        })
      }

      return users
    },
    offices () {
      return this.orderBy(this.$store.state.offices, 'name')
    },
    licensees () {
      return this.orderBy(this.$store.state.licensees, 'name')
    },
    licenseesByOffice () {
      return this.licensees.filter(licensee => {
        if (this.searchOffice) {
          return licensee.officeId === this.searchOffice
        } else {
          return true
        }
      })
    },
    officesById () {
      const mapped = {}
      this.offices.forEach(office => {
        mapped[office.id] = office
      })
      return mapped
    },
    licenseesById () {
      const mapped = {}
      this.licensees.forEach(licensee => {
        mapped[licensee.id] = licensee
      })
      return mapped
    }
  },
  watch: {
    'searchOffice' () {
      if (this.searchOffice) {
        this.showCompany = false
      } else {
        this.showCompany = true
      }
    },
    'searchCompany' () {
      if (this.searchCompany) {
        this.showOffice = false
      } else {
        this.showOffice = true
      }
    }
  },
  async mounted () {
    this.$store.commit('setTitle', 'Users')
    await this.$store.dispatch('refreshUsers')
    await this.$store.dispatch('refreshLicensees')
    await this.$store.dispatch('refreshOffices')
    this.loaded = true
  },
  methods: {
    goToUser (user) {
      this.$router.push({
        name: 'manage_user',
        params: { id: user.id }
      })
    }
  }
}
</script>
