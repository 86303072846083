<template>
  <div id="toolbar" class="padding-xy-2 padding-xy-3-tablet">
    <div class="columns is-mobile">
      <div class="column">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <AdminDropdown classes="is-marginless" label="Manage" />
            </li>

            <li
              :class="{
                'is-active': !['manage-single'].includes($route.meta.layout)
              }">
              <router-link :to="{ name: $route.meta.parent }">
                <b-icon :icon="$route.meta.icon" size="is-small" />
                <span> {{ $route.meta.title }} </span>
              </router-link>
            </li>

            <li v-if="['manage-single'].includes($route.meta.layout) && $route.meta.single !== false" class="is-active">
              <a>
                <span> {{ $store.state.title }} </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column is-narrow">
        <span v-if="!['manage-single'].includes($route.meta.layout)">
          <router-link :to="{path: $route.path + '/new'}" class="button is-primary">
            <span>{{ $route.meta.type }}</span>
            <b-icon icon="plus-circle" size="is-small" />
          </router-link>
        </span>
        <span v-else-if="$route.meta.single !== false">
          <b-dropdown v-if="id !== 'new' && !['manage_office'].includes($route.name)" :mobile-modal="false" position="is-bottom-left">
            <b-button
              slot="trigger"
              rounded
              type="is-primary"
              icon-right="ellipsis-h"
              class="icon-only" />
            <b-dropdown-item v-if="['manage_licensee'].includes($route.name) && !suspended" @click="suspendLicensee(this)">
              Suspend Licensee
            </b-dropdown-item>
            <b-dropdown-item v-if="['manage_licensee'].includes($route.name) && suspended" @click="unsuspendLicensee(this)">
              Unsuspend Licensee
            </b-dropdown-item>
            <!-- <b-dropdown-item v-if="['manage_licensee'].includes($route.name)" @click="deleteLicensee(this)">
              Delete Licensee
            </b-dropdown-item> -->
            <b-dropdown-item v-if="['manage_user'].includes($route.name)" @click="deleteUser(this)">
              Delete User
            </b-dropdown-item>
            <b-dropdown-item v-if="['manage_user'].includes($route.name)" @click="loginAsThisUser(this)">
              Login as this user
            </b-dropdown-item>
            <b-dropdown-item v-if="['manage_material'].includes($route.name)" @click="duplicateGrade(this)">
              Duplicate Grade
            </b-dropdown-item>
            <b-dropdown-item v-if="['manage_material'].includes($route.name)" @click="deleteGrade(this)">
              Delete Grade
            </b-dropdown-item>
            <b-dropdown-item v-if="['manage_connection'].includes($route.name)" @click="deleteConnection(this)">
              Delete Connection
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import AdminDropdown from '@/components/navigation/AdminDropdown'
import UsersApi from '@/apis/UsersApi'
import AdminApi from '@/apis/AdminApi'
import ProjectsApi from '@/apis/ProjectsApi'

export default {
  name: 'AdminNav',
  components: {
    AdminDropdown
  },
  data () {
    return {
      licensee: null,
      grade: null
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    id () {
      return this.$route.params.id
    },
    suspended () {
      if (this.licensee) {
        return this.licensee.suspended
      } else {
        return false
      }
    }
  },
  async mounted () {
    if (['manage_licensee'].includes(this.$route.name)) {
      if (this.$route.params.id !== 'new') {
        this.licensee = await AdminApi.getLicenseeById(this.$route.params.id)
      }
    }
  },
  methods: {
    deleteUser (user) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this user?',
        confirmText: 'Delete User',
        type: 'is-danger',
        onConfirm: async () => {
          await UsersApi.deleteUser(this.$route.params.id)

          this.$router.push({ name: 'manage_users' })

          this.$buefy.toast.open('User Deleted')
        }
      })
    },
    async loginAsThisUser () {
      await this.$store.dispatch('loginAsUser', {
        userId: this.$route.params.id
      })
      this.$emit('loginSuccess')
      this.$router.push('/')
    },
    deleteConnection (connection) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this connection?',
        confirmText: 'Delete',
        type: 'is-danger',
        onConfirm: async () => {
          await AdminApi.deleteConnection(this.$route.params.id)

          this.$router.push({ name: 'manage_connections' })

          this.$buefy.toast.open('Connection Deleted')
        }
      })
    },
    async duplicateGrade (grade) {
      this.grade = await AdminApi.getGradeById(this.$route.params.id)
      this.grade.id = null
      this.grade.name += ' Copy'

      this.grade = await AdminApi.createGrade(this.grade)
      this.$buefy.toast.open('Grade copied')

      try {
        this.$router.replace({
          name: 'manage_material',
          params: { id: this.grade.id }
        })
      } catch (e) {
        console.log(e)
      } finally {
        location.reload()
      }
    },
    async deleteGrade () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this grade?',
        confirmText: 'Delete',
        type: 'is-danger',
        onConfirm: async () => {
          this.grade = await AdminApi.getGradeById(this.$route.params.id)
          await AdminApi.deleteGrade(this.grade.id)
          this.$buefy.toast.open('Grade deleted')
          this.$router.push({ name: 'manage_materials' })
        }
      })
    },
    suspendLicensee (licensee) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to suspend this licensee?',
        confirmText: 'Suspend Licensee',
        type: 'is-danger',
        onConfirm: async () => {
          var licensee = await AdminApi.getLicenseeById(this.$route.params.id)
          if (licensee) {
            licensee.suspended = true
            await AdminApi.updateLicensee(this.$route.params.id, licensee)

            this.$router.push({ name: 'manage_licensees' })

            this.$buefy.toast.open('Licensee Suspended')
          }
        }
      })
    },
    unsuspendLicensee (licensee) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to unsuspend this licensee?',
        confirmText: 'Unsuspend Licensee',
        type: 'is-success',
        onConfirm: async () => {
          var licensee = await AdminApi.getLicenseeById(this.$route.params.id)
          if (licensee) {
            licensee.suspended = false
            await AdminApi.updateLicensee(this.$route.params.id, licensee)

            this.$router.push({ name: 'manage_licensees' })

            this.$buefy.toast.open('Licensee unsuspended')
          }
        }
      })
    },
    deleteLicensee (licensee) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this licensee?',
        confirmText: 'Delete Licensee',
        type: 'is-danger',
        onConfirm: async () => {
          var licensee = await AdminApi.getLicenseeById(this.$route.params.id)
          if (licensee) {
            licensee.deleted = true
            await AdminApi.updateLicensee(this.$route.params.id, licensee)
            await ProjectsApi.deleteProjectByLicenseeId(licensee.id)
            await UsersApi.deactivateUsersByLicenseeId(licensee.id)

            this.$router.push({ name: 'manage_licensees' })

            this.$buefy.toast.open('Licensee Deleted')
          }
        }
      })
    }
  }
}
</script>
