<template>
  <div v-if="loaded" class="modal-card" @keyup.enter="save">
    <header class="modal-card-head">
      <div class="modal-card-title">
        {{ component && component.name ? component.name : 'New Component' }}
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="close()" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="padding-xy-3 padding-xy-4-tablet">
        <b-field label="Component Name" class="required" :type="{'is-danger': errors.has('name')}"
                 :message="errors.first('name')">
          <b-input :ref="'name'" :name="'name'" v-model="component.name" v-validate="'required'" />
        </b-field>
        <b-field label="Serial number" class="required" :type="{'is-danger': errors.has('serial number')}"
                 :message="errors.first('serial number')">
          <b-input :ref="'serial_number'" :name="'serial number'" v-model="component.serial_number" v-validate="'required'" />
        </b-field>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left">
          <b-dropdown
            v-if="edit"
            :mobile-modal="false"
            position="is-top-right">
            <b-button
              slot="trigger"
              rounded
              type="is-dark"
              icon-right="ellipsis-h"
              class="icon-only" />
            <b-dropdown-item @click="deleteComponent()">
              Delete component
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="close">
            Cancel
          </button>
          <button class="button is-primary" @click="save">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  props: {
    item: Object
  },
  data () {
    return {
      loaded: false,
      edit: !!this.item,
      component: this.item ? this.item : {
        name: '',
        serial_number: ''
      }
    }
  },
  computed: {
  },
  async mounted () {
    this.loaded = true
  },
  methods: {
    async save () {
      if (await this.$validator.validateAll()) {
        if (!this.edit) {
          this.$emit('add-component', this.component)
        } else {
          this.$emit('update-component', this.component)
        }
        this.close()
      }
    },
    deleteComponent () {
      this.$emit('delete-component', this.component)
      this.close()
    },
    close () {
      this.$parent.close()
    }
  }
}

</script>
