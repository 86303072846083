<template>
  <div>
    <b-loading :is-full-page="false" v-if="!loaded" active :can-cancel="false">
      <div style="flex-direction: column; align-self: flex-start; text-align: center; z-index: 998; padding-top: 300px;">
        <div class="loading-icon" />
        <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
          Loading gauge data...
        </div>
      </div>
    </b-loading>
    <div v-if="loaded">
      <div id="toolbar" v-if="!newGauge" class="padding-xy-3 padding-xy-3-tablet">
        <div class="columns">
          <div class="column">
            <div class="columns is-mobile is-vcentered">
              <div class="column">
                <span class="margin-x-3 has-text-white">{{ gauge.name }}</span>
              </div>
              <div class="column is-narrow">
                <b-dropdown :mobile-modal="false" position="is-bottom-left">
                  <b-button
                    slot="trigger"
                    rounded
                    type="is-primary"
                    icon-right="ellipsis-h"
                    class="icon-only" />
                  <b-dropdown-item v-if="profile.type === 'jfe'" @click="confirmDeleteGauge()">
                    Delete Gauge
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row padding-xy-3 padding-xy-5-tablet" style="min-height: 100vh">
        <div class="columns">
          <div class="column">
            <div class="columns is-multiline">
              <div class="column is-12">
                <b-field class="required" label="Gauge/Kit Name" :type="{'is-danger': errors.has('name')}" :message="errors.first('name')">
                  <b-input v-model="gauge.name" v-validate="'required'" name="name" />
                </b-field>
                <b-field class="required" label="Connection" :type="{'is-danger': errors.has('connection')}" :message="errors.first('connection')">
                  <b-select v-model="gauge.connectionId" v-validate="'required'" expanded name="connection" placeholder="Select a connection" @input="resetClasses">
                    <option :value="null" disabled>Select connection...</option>
                    <option v-for="connection in connections" :key="connection.id" :value="connection.id">
                      {{ connection.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field class="required" label="Application" :type="{'is-danger': errors.has('application')}" :message="errors.first('application')">
                  <b-select v-model="gauge.application" v-validate="'required'" expanded name="application" placeholder="Select an application">
                    <option :value="null" disabled>Select application...</option>
                    <option value="pin">Pin</option>
                    <option value="box">Box</option>
                  </b-select>
                </b-field>
                <b-field class="required" label="Type" :type="{'is-danger': errors.has('type')}" :message="errors.first('type')">
                  <b-select v-model="gauge.typeId" v-validate="'required'" expanded name="type" placeholder="Select a type">
                    <option :value="null" disabled>Select type...</option>
                    <option v-for="type in gaugeParametersGrouped['type']" :key="type.id" :value="type.id">
                      {{ type.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field class="required" label="Owner" :type="{'is-danger': errors.has('owner')}" :message="errors.first('owner')">
                  <b-select v-model="gauge.ownerId" v-validate="'required'" expanded name="owner" placeholder="Select a owner">
                    <option :value="null" disabled>Select owner...</option>
                    <option v-for="office in offices" :key="office.id" :value="office.id">
                      {{ office.name }}
                    </option>
                  </b-select>
                </b-field>
                <b-field class="required" label="Manufacturer" :type="{'is-danger': errors.has('manufacturer')}" :message="errors.first('manufacturer')">
                  <b-select v-model="gauge.manufacturerId" v-validate="'required'" expanded name="manufacturer" placeholder="Select a manufacturer">
                    <option :value="null" disabled>Select manufacturer...</option>
                    <option v-for="manufacturer in gaugeParametersGrouped['manufacturer']" :key="manufacturer.id" :value="manufacturer.id">
                      {{ manufacturer.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field class="required" label="Value" :type="{'is-danger': errors.has('value')}" :message="errors.first('value')">
                  <b-input v-model="gauge.value" v-validate="'required'" name="value" />
                </b-field>
              </div>
              <div class="column is-6">
                <b-field class="required" label="Purchase Date" :type="{'is-danger': errors.has('purchaseDate')}" :message="errors.first('purchaseDate')">
                  <b-datepicker v-model="gauge.purchaseDate" v-validate="'required'" icon="calendar-alt" :mobile-modal="false" name="purchaseDate" data-vv-as="start date">
                    <div class="level is-mobile">
                      <button class="button has-text-grey is-transparent" @click="gauge.purchaseDate = new Date()">
                        <b-icon icon="calendar-alt" />
                        <span>Today</span>
                      </button>
                      <button class="button has-text-grey is-transparent" @click="gauge.purchaseDate = null">
                        <b-icon icon="times" />
                        <span>Reset</span>
                      </button>
                    </div>
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-12">
                <b-field label="Notes">
                  <b-input type="textarea" rows="4" v-model="gauge.notes" />
                </b-field>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="card has-shadow-30 padding-xy-3 mb-3">
              <div class="level" :class="{'mb-2' : gauge.classes.length}">
                <div class="has-text-weight-bold ml-1">Classes</div>
                  <b-button @click="openClassModal()" type="is-primary is-outlined" :icon-left="gauge.classes.length ? 'pencil' : 'plus'" :label="gauge.classes.length ? 'Manage' : 'Add Class'" :disabled="!gauge.connectionId" />
                </div>
                <template v-if="gauge.classes.length">
                  <div v-for="size, i in gauge.classes" :key="i" class="card gauge-class">
                    <div>{{sizeName(size.sizeId)}}</div>
                    <div v-if="size.weights.length" class="pl-2">
                    <b-tag type="is-white ml-1 is-rounded" v-for="weight in size.weights" :key="weight">{{ weightName(weight) }}</b-tag>
                    </div>
                  </div>
                </template>
            </div>
            <div class="card has-shadow-30 padding-xy-3">
              <div class="level" :class="{'mb-2' : gauge.components.length}">
                <b-switch v-model="gauge.kit" :left-label="true" class="has-text-weight-bold ml-1">Kit</b-switch>
                <b-button :class="{'is-invisible' : !gauge.kit}" @click="openComponentModal()" type="is-primary is-outlined" icon-left="plus" label="Add Component" />
              </div>
              <template v-if="gauge.kit">
                <div v-for="component, i in gauge.components" :key="i" class="card padding-xy-2 mt-2">
                  <div class="columns is-vcentered is-mobile">
                      <div class="column is-flex padding-xy-3">
                        <div class="has-text-weight-bold">
                          {{component.name}}
                        </div>
                        <div class="has-text-grey" style="margin: 4px 0 0 10px; font-size: 12px;">
                          {{component.serial_number}}
                        </div>
                      </div>
                      <div class="column is-narrow padding-xy-3">
                        <b-dropdown :mobile-modal="false" position="is-bottom-left">
                          <b-button
                            slot="trigger"
                            rounded
                            type="is-light is-small"
                            icon-right="ellipsis-h"
                            class="icon-only " />
                          <b-dropdown-item @click="openComponentModal(component)">
                            Edit Component
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteComponent(component)">
                            Delete Component
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <manage-buttons @cancel="cancel" @save="save" />
  </div>
</template>

<script>

import Vue2Filters from 'vue2-filters'
import AdminApi from '@/apis/AdminApi'
import GaugesApi from '@/apis/GaugesApi'
import ManageButtons from '@/components/navigation/ManageButtons'
import GaugeClassModal from '@/components/modals/GaugeClassModal'
import KitComponentModal from '@/components/modals/KitComponentModal'

export default {
  name: 'GaugeAdmin',
  components: {
    ManageButtons
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      loaded: false,
      newGauge: false,
      gauge: {
        name: null,
        connectionId: null,
        application: null,
        typeId: null,
        ownerId: null,
        manufacturerId: null,
        value: null,
        purchaseDate: null,
        notes: null,
        classes: [],
        kit: false,
        components: [],
        status: 'available'
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    offices () {
      return this.$store.state.offices
    },
    connections () {
      return this.$store.state.connectionsLight.filter(c => c.gaugeAvailability)
    },
    profile () {
      return this.$store.state.profile
    },
    gaugeParametersGrouped () {
      const gaugeParameters = this.$store.state.gaugeParameters
      return {
        manufacturer: gaugeParameters.filter((gp) => gp && gp.category === 'manufacturer'),
        type: gaugeParameters.filter((gp) => gp && gp.category === 'type'),
        calibrator: gaugeParameters.filter((gp) => gp && gp.category === 'calibrator')
      }
    }
  },
  async mounted () {
    this.$store.commit('setApp', 'gauge')
    this.$store.commit('setTitle', 'Gauge Admin')

    await this.$store.dispatch('refreshUsers')
    await this.$store.dispatch('refreshOffices')
    await this.$store.dispatch('refreshConnectionsLight', { hasImages: false, hasSizes: true, hasWeights: true })

    if (this.id === 'new') {
      this.$store.commit('setTitle', 'New Gauge')
      this.newGauge = true
    } else {
      const gaugeData = await GaugesApi.getGaugeById(this.id)
      this.gauge = this.transformGaugeData(gaugeData)

      this.newGauge = false
      this.showConnectionDetails = true
      this.oldGaugeStatus = this.gauge.status
      this.$store.commit('setTitle', this.gauge.name)
      document.title = 'JFE Tools • ' + this.gauge.name
    }

    await this.refreshGaugeConnection()

    await this.loadParameters()

    this.loaded = true
  },
  methods: {
    transformGaugeData (gaugeData) {
      return {
        ...gaugeData,
        connectionId: gaugeData.connectionId,
        manufacturerId: gaugeData.manufacturerId,
        typeId: gaugeData.typeId,
        ownerId: gaugeData.ownerId,
        kit: gaugeData.kit === 1,
        purchaseDate: gaugeData.purchaseDate ? new Date(gaugeData.purchaseDate) : null
      }
    },
    async loadParameters () {
      try {
        await this.$store.dispatch('refreshGaugeParameters')
      } catch (e) {
        console.log(e)
      }
    },
    cancel () {
      this.$router.push({ name: 'gauge_tracker' })
    },
    async save () {
      if (await this.$validator.validateAll()) {
        try {
          if (this.id === 'new') {
            await GaugesApi.createGauge(this.gauge)
            setTimeout(() => { this.cancel() }, 2000)
          } else {
            const gaugeData = await GaugesApi.updateGauge(this.id, this.gauge)
            this.gauge = this.transformGaugeData(gaugeData)
          }
          this.$buefy.toast.open('Gauge saved')
        } catch (error) {
          this.$buefy.dialog.alert({
            title: 'Ouch!',
            message: `<strong>Status</strong> ${error.response.status}<br><strong>Message:</strong> ${error.response.data.message}. <p>Please review this gauge and make sure it is consistent.</p>`,
            type: 'is-danger',
            hasIcon: true,
            icon: 'exclamation-circle'
          })
          return false
        }
      }
    },
    sizeName (id) {
      const allSizes = this.connections.map(c => c.sizes).flat()
      const size = allSizes.find(s => s.id === id)
      if (!size) {
        return ''
      }
      return size.size + '"'
    },
    weightName (id) {
      const allWeights = this.connections
        .map((c) => c.sizes)
        .flat()
        .map((s) => s.weights)
        .flat()
      const weight = allWeights.find((w) => w.id === id)
      return weight.weight
    },
    async refreshGaugeConnection () {
      if (this.gauge.connectionId) {
        this.gaugeConnection = await AdminApi.getConnectionById(this.gauge.connectionId)
      } else {
        this.gaugeConnection = null
      }
    },
    confirmDeleteGauge () {
      this.$buefy.dialog.confirm({
        message:
          'Are you sure you want to delete this gauge?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await GaugesApi.deleteGauge(this.$route.params.id)
          this.$buefy.toast.open('Gauge deleted')
          this.$router.push({ name: 'gauge_tracker' })
        }
      })
    },
    openClassModal () {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: GaugeClassModal,
        props: {
          gauge: this.gauge
        },
        events: {
          save: (classes) => {
            this.gauge.classes = classes
            modal.close()
          }
        }
      })
    },
    openComponentModal (component) {
      this.$buefy.modal.open({
        parent: this,
        component: KitComponentModal,
        customClass: 'skinny',
        props: {
          item: component
        },
        events: {
          'add-component': this.addComponent,
          'update-component': this.updateComponent,
          'delete-component': this.deleteComponent
        }
      })
    },
    addComponent (component) {
      this.gauge.components.push(component)
    },
    updateComponent (updatedComponent) {
      const index = this.gauge.components.indexOf(updatedComponent)
      if (index !== -1) {
        this.gauge.components.splice(index, 1, updatedComponent)
      }
    },
    deleteComponent (component) {
      const index = this.gauge.components.indexOf(component)
      if (index !== -1) {
        this.$buefy.dialog.confirm({
          message: 'Are you sure you want to delete this component?',
          confirmText: 'Delete Component',
          type: 'is-danger',
          onConfirm: () => {
            this.gauge.components.splice(index, 1)
          }
        })
      }
    },
    resetClasses () {
      this.gauge.classes = []
    }
  }
}

</script>
