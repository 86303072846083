import Axios from 'axios'

import { options, url } from './ApiConfig'

export default {
  async getGauges (params) {
    options().params = params
    const response = await Axios.get(url('/api/v1/gauges'), {
      params,
      auth: options().auth
    })

    return response.data
  },

  async getLastGaugeID () {
    const response = await Axios.get(url('/api/v1/gauges/lastId'), options())

    return response.data
  },

  async getGaugeById (id) {
    const response = await Axios.get(url(`/api/v1/gauges/${id}`), options())

    return response.data
  },

  async updateGauge (id, gauge) {
    const response = await Axios.put(url(`/api/v1/gauges/${id}`), gauge, options())

    return response.data
  },

  async createGauge (gauge) {
    const response = await Axios.post(url('/api/v1/gauges'), gauge, options())

    return response.data
  },

  async deleteGauge (id) {
    const response = await Axios.delete(url(`/api/v1/gauges/${id}`), options())

    return response.data
  },

  async createGaugeActivityRequest (data) {
    const response = await Axios.post(url('/api/v1/gauges/activity-requests'), data, options())
    return response.data
  },

  async updateGaugeActivityRequest (id, data) {
    const response = await Axios.put(url(`/api/v1/gauges/activity-requests/${id}`), data, options())
    return response.data
  },

  async getGaugeActivityHistory (id) {
    const response = await Axios.get(url(`/api/v1/gauges/activity-history/${id}`), options())
    return response.data
  },

  async addGaugeActivityComment (gaugeActivityRequestId, data) {
    const response = await Axios.post(url(`/api/v1/gauges/activity-requests/${gaugeActivityRequestId}/comments`), data, options())
    return response.data
  },

  async createActivityRequestShipping (data) {
    const response = await Axios.post(url('/api/v1/gauges/activity-request-shipping'), data, options())
    return response.data
  },

  async createActivityRejectGauge (data) {
    const response = await Axios.post(url('/api/v1/gauges/activity-reject-gauge'), data, options())
    return response.data
  },

  async createActivityReturnGauge (data) {
    const response = await Axios.post(url('/api/v1/gauges/activity-return-gauge'), data, options())
    return response.data
  },

  async getPdfUrl (fileName) {
    const response = await Axios.post(url('/api/v1/gauges/get-pdf-url'), { fileName }, options())

    return response.data
  }
}
