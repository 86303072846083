<template>
  <div v-if="loaded" class="modal-card" @keyup.enter="save">
    <header class="modal-card-head">
      <div class="modal-card-title">
        {{ parameter && parameter.name ? parameter.name : 'New Parameter' }}
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        :disabled="saving"
        @click="close" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="padding-xy-3 padding-xy-4-tablet">
        <b-field class="required" label="Name" :type="{'is-danger': errors.has('name')}"
         :message="errors.first('name')">
          <b-input :ref="'name'" :name="'name'" v-model="parameter.name" v-validate="'required'" />
        </b-field>
        <b-field label="Category">
          <b-select expanded v-model="parameter.category" name="category">
            <option :value="null" disabled>Choose Category...</option>
            <option value="manufacturer">Manufacturer</option>
            <option value="type">Type</option>
            <option value="calibrator">Calibrator</option>
          </b-select>
        </b-field>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left">
          <b-dropdown
            v-if="item.action !== 'create'"
            :mobile-modal="false" position="is-top-right">
            <b-button
              slot="trigger"
              rounded
              type="is-dark"
              icon-right="ellipsis-h"
              class="icon-only" />
            <b-dropdown-item @click="deleteParameter()">
              Delete Parameter
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="level-right">
          <button class="button is-cancel" type="button" :disabled="saving" @click="close">
            Cancel
          </button>
          <button class="button is-success" :disabled="saving" @click="save">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>

import AdminApi from '@/apis/AdminApi'

export default {
  props: {
    item: Object
  },
  data () {
    return {
      loaded: false,
      saving: false,
      parameter: this.item.action === 'create' ? {
        name: '',
        category: this.item.category
      } : this.item
    }
  },
  computed: {
  },
  async mounted () {
    this.loaded = true
  },
  methods: {
    async save () {
      try {
        if (await this.$validator.validateAll()) {
          this.saving = true
          this.parameter = await AdminApi.saveGaugeParameters(this.parameter)
          this.$buefy.toast.open('Parameter saved')
          this.close()
        }
      } catch (error) {
        this.saving = false
        this.$buefy.toast.open('Error saving parameter, try again later')
        console.log(error)
      }
    },
    deleteParameter () {
      try {
        this.$buefy.dialog.confirm({
          message: 'Are you sure you want to delete this parameter?',
          confirmText: 'Delete Parameter',
          type: 'is-danger',
          onConfirm: async () => {
            this.saving = true
            await AdminApi.deleteGaugeParameters(this.parameter.id)
            this.$buefy.toast.open('Parameter deleted')
            this.close()
          }
        })
      } catch (e) {
        this.saving = false
        this.$buefy.toast.open('Error deleting parameter, try again later')
        console.log(error)
      }
    },
    close () {
      this.$emit('reload-parameters')
      this.$parent.close()
    }
  }
}

</script>
