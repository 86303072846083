<template>
  <div class="modal-card" style="max-width: 500px; margin: 0 auto;">
    <header class="modal-card-head">
      <div class="modal-card-title">
        Datasheet Notes
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body content padding-xy-4-tablet">
      <b-field label="Notes">
        <b-input v-model="customNotes" type="textarea" />
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <div class="container level is-mobile">
        <div class="level-left" />
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="$parent.close">
            Cancel
          </button>
          <button class="button is-success" @click="save()">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  props: {
    notes: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      customNotes: this.notes
    }
  },
  methods: {
    save () {
      this.$emit('save', this.customNotes)
      this.$parent.close()
    }
  }
}

</script>
