<template>
  <div>
    <b-loading :is-full-page="false" v-if="!loaded" active :can-cancel="false">
      <div
        style="
          flex-direction: column;
          align-self: flex-start;
          text-align: center;
          z-index: 998;
          padding-top: 300px;
        "
      >
        <div class="loading-icon" />
        <div class="padding-xy-3 has-text-black" style="margin-top: 50px">
          Loading gauge data...
        </div>
      </div>
    </b-loading>
    <div v-if="loaded">
      <div class="row wide-row padding-xy-3 padding-xy-5-tablet">
        <div class="columns align-center mb-0">
          <div class="column align-center">
            <div class="is-size-4 has-text-weight-light">
              <b-button
                icon-left="arrow-left"
                type="is-primary"
                class="mr-2"
                @click="goBackToGaugeList"
              ></b-button>
              <template v-if="gauge.kit">Kit — </template> {{ gauge.name }}
            </div>
          </div>
          <div class="column is-narrow">
            <b-tag type="is-dark" size="is-large" class="is-capitalized">{{ formattedStatus }}</b-tag>
            <b-button
              v-if="profile.type == 'jfe'"
              @click="goToAdmin()"
              type="is-primary is-outlined ml-2"
              icon-left="pencil"
              >Edit Gauge</b-button
            >
          </div>
        </div>
        <hr class="mt-2 mb-0 has-background-grey-lighter" />
        <div class="columns mt-0 is-desktop">
          <div class="column">
            <div class="has-text-weight-bold is-size-5">
              {{ gauge.connection }} {{ gauge.application }}
            </div>
            <div>{{ gauge.type }}</div>
            <div class="mt-5">
              <table>
                <tbody>
                  <tr>
                    <td class="has-text-weight-bold pr-3">Owner:</td>
                    <td>{{ gauge.owner }}</td>
                  </tr>
                  <tr>
                    <td class="has-text-weight-bold pr-3">Manufacturer:</td>
                    <td>{{ gauge.manufacturer }}</td>
                  </tr>
                  <tr>
                    <td class="has-text-weight-bold pr-3">Value:</td>
                    <td>{{ gauge.value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-5">
              {{ gauge.notes }}
            </div>
            <div class="mt-5">
              <div class="level" v-if="gauge.classes" :class="{ 'mb-2': gauge.classes.length }">
                <div class="has-text-weight-bold">Classes</div>
              </div>
              <template v-if="gauge.classes && gauge.classes.length">
                <div
                  v-for="(size, i) in gauge.classes"
                  :key="i"
                  class="card gauge-class"
                >
                  <div>{{ sizeName(size.sizeId) }}</div>
                  <div v-if="size.weights.length" class="pl-2">
                    <b-tag
                      type="is-rounded"
                      v-for="weight in size.weights"
                      :key="weight"
                      >{{ weightName(weight) }}</b-tag
                    >
                  </div>
                </div>
              </template>
            </div>
            <div class="mt-5" v-if="gauge.kit && gauge.components.length">
              <div class="level">
                <div class="has-text-weight-bold">Components</div>
              </div>
              <div
                v-for="(component, i) in gauge.components"
                :key="i"
                class="card padding-xy-2 mt-2"
              >
                <div class="columns is-vcentered is-mobile">
                  <div class="is-flex padding-xy-3">
                    <div class="has-text-weight-bold">
                      {{ component.name }}
                    </div>
                    <div
                      class="has-text-grey"
                      style="margin: 4px 0 0 10px; font-size: 12px"
                    >
                      {{ component.serial_number }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-8-desktop has-border-left">
            <gauge-activity :gauge="gauge" @refresh-gauge="uploadGauge" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import GaugesApi from '@/apis/GaugesApi'
import GaugeActivity from '@/components/modals/GaugeActivity'

export default {
  name: 'GaugeDetail',
  components: {
    GaugeActivity
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      loaded: false,
      gauge: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    profile () {
      return this.$store.state.profile
    },
    connections () {
      return this.$store.state.connectionsLight
    },
    connection () {
      return this.connections.find((c) => c.id === this.gauge.connectionId)
    },
    formattedStatus () {
      return this.gauge.status.replace(/_/g, ' ')
    }
  },
  async mounted () {
    try {
      this.$store.commit('setApp', 'gauge')
      this.$store.commit('setTitle', 'Gauge Detail')

      await this.$store.dispatch('refreshUsers')
      await this.$store.dispatch('refreshConnectionsLight', {
        hasImages: false,
        hasSizes: true,
        hasWeights: true
      })

      this.gauge = await GaugesApi.getGaugeById(this.id)

      if (!this.gauge.id) {
        this.goBackToGaugeList()
      }

      this.$store.commit('setTitle', this.gauge.name)
      document.title = 'JFE Tools • Gauge Detail: ' + this.gauge.name
    } catch (e) {
      console.error(e)
      this.$buefy.toast.open(e.message || 'Error loading gauge data')
    }
    this.loaded = true
  },
  methods: {
    async uploadGauge () {
      try {
        this.loaded = false
        this.gauge = await GaugesApi.getGaugeById(this.id)
      } catch (e) {
        console.error(e)
        this.$buefy.toast.open(e.message || 'Error loading gauge data')
      }
      this.loaded = true
    },
    sizeName (id) {
      const allSizes = this.connections.map((c) => c.sizes).flat()
      const size = allSizes.find((s) => s.id === id)
      if (!size) {
        return ''
      }
      return size.size + '"'
    },
    weightName (id) {
      const allWeights = this.connections
        .map((c) => c.sizes)
        .flat()
        .map((s) => s.weights)
        .flat()
      const weight = allWeights.find((w) => w.id === id)
      return weight.weight
    },
    goToAdmin () {
      this.$router.push({
        name: 'gauge_admin',
        params: { id: this.id }
      })
    },
    goBackToGaugeList () {
      this.$router.push({ name: 'gauge_tracker' })
    }
  }
}
</script>

<style lang="scss" scoped>
.wide-row {
  max-width: 1200px;
  > .columns.is-desktop {
    .column {
      @media (min-width: 769px) {
        padding: 1.5rem;
        &:first-child {
          padding-left: 0.75rem;
        }
        &:last-child {
          padding-right: 0.75rem;
        }
      }
    }
  }
}
.has-border-left {
  border-top: solid 1px #dbdbdb;
  padding-top: 2rem;
  margin-top: 1rem;
  width: 100%;
  @media (min-width: 1024px) {
    width: 63%;
    border-top: none;
    margin-top: 0;
    border-left: solid 1px #dbdbdb;
  }
}
</style>
