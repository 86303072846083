<template>
  <div v-if="loaded" class="modal-card">
    <header class="modal-card-head">
      <div class="modal-card-title">
        <div class="align-center">
          <span v-if="requestStep === 'initiateRequest'">Gauge Request</span>
          <span v-if="requestStep === 'reviewRequest'">Review Request</span>
          <span v-if="requestStep === 'shipGauge'">Ship Gauge</span>
          <span v-if="requestStep === 'receiveGaugeLicensee' || requestStep === 'receiveGaugeJfe'">Receive Gauge</span>
          <span v-if="requestStep === 'returnGauge'">Return Gauge</span>
        </div>
      </div>
      <b-button
        icon-right="times"
        rounded
        class="icon-only"
        type="is-light"
        @click="$parent.close()" />
    </header>
    <section class="modal-card-body is-paddingless">
      <div class="padding-xy-3 padding-xy-4-tablet">
        <div class="columns is-multiline">
          <div class="column is-narrow">
            <div class="card">
              <div class="card-label">Gauge</div>
              <div class="has-text-weight-bold"><template v-if="gauge.kit">Kit — </template> {{ gauge.name }}</div>
              <div>{{ gauge.connection }} {{ gauge.application }}</div>
            </div>
          </div>
          <div class="column is-narrow" v-if="requestStep !== 'returnGauge'">
            <div class="card">
              <div class="card-label">
                <span v-if="requestStep === 'initiateRequest'">Location</span>
                <span v-if="requestStep === 'reviewRequest'">Requesting</span>
              </div>
              <div v-if="requestStep === 'reviewRequest' || requestStep === 'initiateRequest' || requestStep === 'shipGauge'">
                {{ licensee_address }}
              </div>
            </div>
          </div>
          <div class="column is-full" v-if="requestStep === 'shipGauge' || requestStep === 'returnGauge'">
            <div class="card">
              <b-field label="Shipment Method" v-if="requestStep === 'shipGauge'">
                <b-radio v-model="shipmentMethod" native-value="Ship">
                  Ship
                </b-radio>
                <b-radio v-model="shipmentMethod" native-value="Will call">
                  Will call
                </b-radio>
              </b-field>

              <b-field label="Carrier (*)">
                <b-input
                  v-model="carrier"
                  placeholder="Enter carrier name"
                  @input="clearErrorForm"
                />
              </b-field>

              <b-field label="Tracking Number (*)">
                <b-input
                  v-model="trackingNumber"
                  placeholder="Enter tracking number"
                  @input="clearErrorForm"
                />
              </b-field>

              <p class="mb-4"><small><strong>(*) Required fields</strong></small></p>

              <b-field
                v-if="requestStep === 'shipGauge' || requestStep === 'returnGauge'"
                class="file"
                style="border:none; background: none"
                :type="{'is-danger': errors.has('fileUp')}"
                :message="errors.first('fileUp')">
                <b-upload
                  refs="file"
                  drag-drop
                  name="fileUp"
                  data-vv-as="file"
                  @input="uploadFile(optionalFile, $event)">
                  <div v-if="optionalFile.data" class="is-inline-flex align-center">
                    <strong style="margin-right: 5px;">File:</strong> {{ file.name }} Rev {{ optionalFile.revision }} <b-icon icon="times-circle" type="is-danger" class="is-clickable is-small margin-x-2" @click.native="optionalFileInit()" />
                  </div>
                  <a v-if="!optionalFile.data && !wrongFile" class="button is-light is-inline-flex align-center">
                    <b-icon icon="upload" class="is-clickable is-small margin-x-2" />
                    <span>Add PDF, JPG or PNG (Optional)</span>
                  </a>
                  <div v-if="wrongFile" class="is-inline-flex align-center">
                    <strong style="margin-right: 5px;">Just PDF, JPG and PNG files are permitted</strong> <b-icon icon="times-circle" type="is-danger" class="is-clickable is-small margin-x-2" @click.native="optionalFileInit()" />
                  </div>
                </b-upload>
              </b-field>
              <object
                v-if="optionalFile.data"
                :data="originalFileURL"
                type="application/pdf"
                width="100%"
                height="500px" />
            </div>
          </div>
        </div>
        <div>
          <b-field label="Notes:">
            <b-input
              type="textarea"
              v-model="notes"
              placeholder="Enter notes"
            ></b-input>
          </b-field>
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div v-if="errorForm" class="container level is-mobile">
        <span class="error-message">{{ errorForm }}</span>
      </div>
      <div class="container level is-mobile">
        <div class="level-left">
        </div>
        <div class="level-right">
          <button class="button is-cancel" type="button" @click="close">
            Cancel
          </button>
          <button class="button is-primary" @click="save" v-if="requestStep === 'initiateRequest'">
            Request
          </button>
          <button class="button is-primary" @click="save('approved')" v-if="requestStep === 'reviewRequest'">
            Approve
          </button>
          <button class="button is-primary" @click="save('denied')" v-if="requestStep === 'reviewRequest'">
            Deny
          </button>
          <button class="button is-primary" @click="save('rejected')" v-if="requestStep === 'returnGauge'">
            Reject
          </button>
          <button class="button is-primary" @click="save"
                  v-if="requestStep === 'shipGauge' || requestStep === 'receiveGaugeLicensee' || requestStep === 'receiveGaugeJfe'">
            Save
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import AdminApi from '@/apis/AdminApi'
import GaugesApi from '@/apis/GaugesApi'

export default {
  props: {
    gauge: Object,
    requestStep: String,
    requestItem: Object,
    requestType: String
  },
  data () {
    return {
      loaded: false,
      selectedAction: null,
      licensee: {},
      licensee_address: '',
      notes: '',
      shipmentMethod: 'Ship',
      carrier: '',
      trackingNumber: '',
      optionalFile: null,
      errorForm: null,
      wrongFile: false,
      originalFileURL: null,
      file: { id: 'new', name: 'New File' }
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    connections () {
      return this.$store.state.connectionsLight
    },
    connection () {
      return this.connections.find((c) => c.id === this.gauge.connectionId)
    }
  },
  async mounted () {
    this.loaded = true
    this.selectedAction = this.requestStep

    if (this.requestStep === 'initiateRequest') {
      this.licensee = await AdminApi.getLicenseeById(this.profile.licenseeId)
      this.licensee_address = this.licensee.address || ''
    } else {
      this.licensee = {
        id: this.requestItem.licenseeId,
        name: this.requestItem.licenseeName
      }
      this.licensee_address = this.requestItem.shippingAddress
    }
    this.optionalFileInit()
  },
  methods: {
    optionalFileInit () {
      this.wrongFile = false
      this.optionalFile = {
        fileId: this.file.id,
        type: null,
        data: null
      }
    },
    async uploadFile (optionalFile, file) {
      try {
        const reader = new FileReader()

        reader.onload = async () => {
          optionalFile.type = file.type
          const typeSplit = file.type.split('/')

          if (typeSplit[1] === 'pdf' || typeSplit[1] === 'jpg' || typeSplit[1] === 'jpeg' || typeSplit[1] === 'png') {
            optionalFile.data = reader.result
            optionalFile.size = file.size
            optionalFile.fileId = this.file.id
            optionalFile.name = file.name

            // Preview
            this.originalFileURL = URL.createObjectURL(file)
          } else {
            this.wrongFile = true
          }
        }

        reader.readAsDataURL(file)
      } catch (e) {
        this.errors.items.push('optionalFile')
        console.error(e)
      }
    },
    async save (response = '') {
      this.errorForm = null
      try {
        switch (this.requestStep) {
          case 'initiateRequest':
            await GaugesApi.createGaugeActivityRequest({
              gaugeId: this.gauge.id,
              userId: this.profile.id,
              shippingAddress: this.licensee_address,
              type: 'allocation',
              notes: this.notes,
              licenseeId: this.profile.licenseeId
            })
            this.$buefy.toast.open('Allocation started')
            break
          case 'reviewRequest':
            const data = {
              id: this.requestItem.requestId,
              status: response,
              type: this.requestType,
              gaugeId: this.gauge.id,
              notes: this.notes
            }
            await GaugesApi.updateGaugeActivityRequest(this.requestItem.requestId, data)
            this.$buefy.toast.open({
              message: `Request ${response === 'approved' ? 'approved' : 'denied'}`,
              type: 'is-success'
            })
            break
          case 'shipGauge':
            try {
              if (!this.carrier || !this.trackingNumber) {
                this.errorForm = 'Please fill in carrier and tracking number fields.'
                return
              }
              await GaugesApi.createActivityRequestShipping({
                requestId: this.requestItem.requestId,
                shipmentMethod: this.shipmentMethod,
                carrier: this.carrier,
                trackingNumber: this.trackingNumber,
                file: this.optionalFile,
                notes: this.notes
              })
              this.$buefy.toast.open({
                message: 'Shipping details saved successfully!',
                type: 'is-success'
              })
            } catch (error) {
              console.error(error)
              this.$buefy.toast.open({
                message: 'Failed to save shipping details.',
                type: 'is-danger'
              })
            }
            break
          case 'receiveGaugeLicensee':
            await GaugesApi.updateGaugeActivityRequest(this.requestItem.requestId, {
              id: this.requestItem.requestId,
              type: this.requestType,
              gaugeId: this.gauge.id,
              status: 'received',
              notes: this.notes
            })
            this.$buefy.toast.open({
              message: 'Request marked as received',
              type: 'is-success'
            })
            break
          case 'returnGauge':
            if (!this.carrier || !this.trackingNumber) {
              this.errorForm = 'Please fill in carrier and tracking number fields.'
              return
            }
            await GaugesApi.createActivityRejectGauge({
              requestId: this.requestItem.requestId,
              carrier: this.carrier,
              trackingNumber: this.trackingNumber,
              notes: this.notes,
              file: this.optionalFile
            })
            this.$buefy.toast.open({
              message: 'Rejecting details saved successfully!',
              type: 'is-success'
            })
            break
          case 'receiveGaugeJfe':
            await GaugesApi.updateGaugeActivityRequest(this.requestItem.requestId, {
              id: this.requestItem.requestId,
              type: this.requestType,
              gaugeId: this.gauge.id,
              status: 'returnedReceived',
              notes: this.notes
            })
            this.$buefy.toast.open({
              message: 'Request marked as received',
              type: 'is-success'
            })
            break
        }

        this.$emit('saved')
        this.close()
      } catch (error) {
        this.$buefy.toast.open('Failed to start allocation')
        console.error(error)
      }
    },
    close () {
      this.$parent.close()
    },
    clearErrorForm () {
      this.errorForm = null
    }
  }
}

</script>

<style lang="scss" scoped>
.column.is-narrow {
  max-width: 100%;
}
.card {
  background: transparent;
  border: none;
  display: block;
  font-size: 14px;
  .has-text-weight-bold {
    font-size: 15px;
  }
  .card-label {
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-size: 12px;
    color: #000;
    margin-bottom: 8px;
    border-bottom: solid 1px;
    display: inline-block;
  }
}
.error-message {
  color: #ff0000;
  font-size: 14px;
}
</style>
