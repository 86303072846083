<template>
  <div>
    <nav id="navbar" class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link
          :to="{ name: 'home' }"
          class="navbar-item is-paddingless is-clickable">
          <img src="@/assets/images/JFE-logo-icon.png">
        </router-link>
        <b-dropdown :mobile-modal="false">
          <div slot="trigger" class="navbar-item is-clickable">
            <span class="padding-x-1">
              <span v-if="currentApp == 'royalty'">Royalty Tracker</span>
              <span v-if="currentApp == 'gauge'">Gauge Tracker</span>
              <span v-if="currentApp == 'datasheet'">Datasheet Generator</span>
              <span v-if="currentApp == 'technical'">Technical Documents</span>
              <span v-if="currentApp == 'inspection'">Inspection Sheets</span>
              <span v-if="currentApp == 'blanking'">Blanking Dimensions</span>
              <span v-if="currentApp == 'manage'">Administration</span>
            </span>
            <b-icon icon="caret-down" class="rotate" />
          </div>
          <b-dropdown-item
            v-if="profile && (profile.hasJfeRoyaltyTrackerPermission || profile.hasLicenseeRoyaltyTrackerPermission)"
            has-link
            :class="currentApp == 'royalty' ? 'active' : ''">
            <a @click="royaltyApp">
              <span>Royalty Tracker</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="profile && (profile.hasJfeGaugeTrackerPermission || profile.hasLicenseeGaugeTrackerPermission)"
            has-link
            :class="currentApp == 'gauge' ? 'active' : ''">
            <a @click="gaugeApp">
              <span>Gauge Tracker</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item has-link :class="currentApp == 'datasheet' ? 'active' : ''">
            <a @click="datasheetApp">
              <span>Datasheet Generator</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item has-link :class="currentApp == 'technical' ? 'active' : ''">
            <a @click="technicalApp">
              <span>Technical Documents</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item v-if="(profile.hasJfeInspectionSheetPermission || profile.hasLicenseeInspectionSheetPermission)" has-link :class="currentApp == 'inspection' ? 'active' : ''">
            <a @click="inspectionSheetsApp">
              <span>Inspection Sheets</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item has-link :class="currentApp == 'blanking' ? 'active' : ''">
            <a @click="blankingApp">
              <span>Blanking Dimensions</span>
            </a>
          </b-dropdown-item>
        </b-dropdown>
        <div id="hamburger_button">
          <a
            role="button"
            class="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="mainNav"
            @click="mobileNav = !mobileNav">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
      </div>
      <div id="mainNav" class="navbar-menu" :class="{ 'is-active': mobileNav }">
        <div class="navbar-start" />

        <div class="navbar-end">
          <div v-if="currentApp == 'royalty'">
            <div class="nav-item-wrapper">
              <router-link :to="{ name: 'royalty_tracker_projects' }" class="navbar-item">
                <span>Projects</span>
              </router-link>
            </div>
            <div class="nav-item-wrapper">
              <router-link :to="{ name: 'royalty_tracker_invoices' }" class="navbar-item">
                <span>Invoices</span>
              </router-link>
            </div>
          </div>
          <div v-if="currentApp == 'gauge'">
            <div class="nav-item-wrapper">
              <router-link :to="{ name: 'gauge_tracker' }" class="navbar-item">
                <span>Gauges</span>
              </router-link>
            </div>
          </div>
          <div v-if="currentApp == 'datasheet'">
            <div class="nav-item-wrapper">
              <router-link :to="{ name: 'datasheet_generator' }" class="navbar-item">
                <span>Generator</span>
              </router-link>
            </div>
          </div>
          <div v-if="currentApp == 'inspection'">
            <div class="nav-item-wrapper">
              <router-link :to="{ name: 'inspection_sheets' }" class="navbar-item">
                <span>Generator</span>
              </router-link>
            </div>
          </div>
          <div v-if="currentApp == 'technical'">
            <div class="nav-item-wrapper">
              <router-link :to="{ name: 'technical_documents' }" class="navbar-item">
                <span>Documents</span>
              </router-link>
            </div>
          </div>
          <div v-if="currentApp == 'blanking'">
            <div class="nav-item-wrapper">
              <router-link :to="{ name: 'blanking_dimensions' }" class="navbar-item">
                <span>Generator</span>
              </router-link>
            </div>
          </div>

          <div v-if="currentApp !== 'manage'" class="nav-item-wrapper divider" />

          <!-- IF ADMIN -->
          <AdminDropdown classes="nav-item-wrapper is-marginless" position="is-bottom-left" />
          <!-- END IF ADMIN -->

          <b-dropdown
            v-if="profile != 'guest'"
            :mobile-modal="false"
            position="is-bottom-left"
            class="nav-item-wrapper is-marginless">
            <div slot="trigger" class="navbar-item">
              <b-icon icon="user" />
            </div>
            <div class="has-text-center">
              <div class="padding-xy-2">
                <b>{{ $store.state.profile.fullName }}</b>
                <div><a @click="editUserInfo">Edit profile</a></div>
              </div>
              <router-link
                :to="{name: 'logout'}"
                class="button is-primary is-fullwidth padding-y-2"
                style="border-radius: 0 0 5px 5px; margin: 0.5rem 0 -0.5rem; height: auto">
                Logout
              </router-link>
            </div>
          </b-dropdown>
          <div v-else class="nav-item-wrapper">
            <div class="navbar-item" style="opacity: 1">
              <router-link :to="{name: 'login'}" class="button is-primary">
                LOGIN
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import AdminDropdown from '@/components/navigation/AdminDropdown'
import UserInfo from '@/components/modals/UserInfo'
import AdminApi from '@/apis/AdminApi'

export default {
  name: 'AppNavigation',
  components: {
    AdminDropdown
  },
  data () {
    return {
      mobileNav: false
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    currentApp () {
      return this.$store.state.app
    }
  },
  methods: {
    royaltyApp () {
      this.$store.commit('setApp', 'royalty')
      this.$router.push({ name: 'royalty_tracker_projects' })
    },
    gaugeApp () {
      this.$store.commit('setApp', 'gauge')
      this.$router.push({ name: 'gauge_tracker' })
    },
    datasheetApp () {
      this.$store.commit('setApp', 'datasheet')
      this.$router.push({ name: 'datasheet_generator' })
    },
    technicalApp () {
      this.$store.commit('setApp', 'technical')
      this.$router.push({ name: 'technical_documents' })
    },
    inspectionSheetsApp () {
      this.$store.commit('setApp', 'inspection')
      this.$router.push({ name: 'inspection_sheets' })
    },
    blankingApp () {
      this.$store.commit('setApp', 'blanking')
      this.$router.push({ name: 'blanking_dimensions' })
    },
    editUserInfo () {
      this.$buefy.modal.open({
        parent: this,
        component: UserInfo,
        customClass: 'skinny'
      })
    },
    async makeDumpFiles () {
      const loadingComponent = this.$buefy.loading.open({ container: null })
      await AdminApi.makeDumpFiles('makeDumpFiles')
      loadingComponent.close()
    }
  }
}
</script>
